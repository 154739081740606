import React from 'react';
import Text from '@web-bricks/Shared/Input/textField';
import Form from '@web-bricks/Shared/form/components/form';
import firebase from '@firebaseConnect';
import Entities from '@toolkit/entities';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SmsIcon from '@material-ui/icons/Sms';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import interiorDesigner from "@toolkit/lib/interiorDesigner";

const classes = {
  "@global": {
    body: {},
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  submit: {},
};

class SignIn extends React.Component {

  constructor(props) {
    super(props);
      this.state = {
        inValidPhoneNum: false,
        errorMessage:null
      }
    }

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };

  isValidNumber = (phoneNum) =>{
    var pattern = /^(0|([1-9]\d*))$/;
    if(pattern.test(phoneNum) && phoneNum.length === 10){
      return true
    }else{
      this.setState({
        inValidPhoneNum : true,
        errorMessage:'Invalid phone number!'
      })
    }
  }

  onSubmit = (values) => {
    this.setState({
      phoneNumberIncorrect: false,
      incorrectReason: null
    })
    const phone = _.toString(values.phone);
    let validity = this.isValidNumber(phone)
    if(validity){
      this.props.signInHelper.onUserLookup(phone)
      this.props.signInHelper.storePhoneNumber(phone)
      this.goToView(`/account/password`)
    }else{
      values.onSuccess()
    }
  }

  renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
    return (
      <MainLayout name="main-layout">
        <View landing>
          <Layout name="login">
            <Layout>
              <Block name="signin-close">
                <IconButton onClick={() => this.props.history.push('/cart')}>
                  <Bits.Icon name="close-icon" fontSize='medium'>Close</Bits.Icon>
                </IconButton>
              </Block>
              <Block name="login-header">
                <Bits.Text type="h1" name="header">
                  Sign-in to your store using mobile number
                </Bits.Text>
                <Block name="signin-bg" />
              </Block>
            </Layout>
            <Layout>
              <Block name="mobile-entry">
                <Form
                  submitButton={{
                    submitButtonId: "submit-button",
                    label: (
                      <ArrowForwardIcon
                        name="submit-icon"
                        style={{
                          color: "white",
                          backgroundColor:
                            interiorDesigner.colorPallete.primary,
                          borderRadius: 22,
                          padding: 11,
                        }}
                      />
                    ),
                  }}
                  className={classes.form}
                  noValidate
                  onSubmit={this.onSubmit}
                  buttonPosition="row"
                >
                  <Block name="signin-container">
                    <Block name="signin">
                      <Text
                        //variant="outlined"
                        margin="normal"
                        // required
                        fullWidth
                        id="phone"
                        label="Enter your mobile number"
                        name="phone"
                        autoFocus
                        error={this.state.inValidPhoneNum}
                        helperText={this.state.errorMessage}
                        InputProps={{
                        }}
                      />
                    </Block>
                  </Block>
                </Form>
              </Block>
            </Layout>
            <Layout>
              <Block name="login-message">
                <SmsIcon
                  style={{ color: interiorDesigner.colorPallete.primary }}
                />
                <Bits.Text type="p" name="login-message-text">
                  <b>One Time Password</b> will be send to your phone number to
                  verification
                </Bits.Text>
              </Block>
            </Layout>
          </Layout>
        </View>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallet) => {
    return {
      "login-header": {
        master: {
          display: "flex",
          fontSize: 18,
        },
      },
      "signin-close": {
        master: {
          position: "absolute",
          right: 20,
          top: 20,
          zIndex: 2,
        },
        mobile: {
          right: 10,
          top: 10,
        },
      },
      "close-icon": {
        master: {
          color: colorPallet.teritary,
        },
      },
      "signin-bg": {
        master: {
          backgroundImage: `url(${
            require("@core/assests/images/cardamom-spices.png").default
          }`,
          width: 300,
          height: 500,
          // height:100,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          position: "absolute",
          top: 0,
          right: 200,
        },
        mobile: {
          right: "-65px",
          opacity: 0.9,
        },
      },
      "main-layout": {
        master: {
          backgroundColor: colorPallet.secondary,
        },
      },
      "signin-container": {
        master: {
          width: "100%",
          //margin:'50px 20px'
        },
      },
      "mobile-entry": {
        master: {
          marginTop: 50,
          width: "50%",
        },
        mobile: {
          width: "100%",
        },
      },
      header: {
        master: {
          color: colorPallet.primary,
          marginRight: 56,
          zIndex: 1,
        },
      },
      submit: {
        master: {
          margin: "auto",
        },
      },
      "login-message": {
        master: {
          display: "flex",
          marginTop: 20,
        },
      },
      "login-message-text": {
        master: {
          margin: 0,
          fontSize: 10,
          marginLeft: 10,
          color: colorPallet.primary,
        },
      },
      login: {
        master: {
          padding: "70px 40px",
          height: "100vh",
          maxWidth: "60%",
          margin: "0 auto",
        },
        mobile: {
          padding: "50px 20px",
          maxWidth: "100%",
          margin: "0px auto",
        },
        transition: "slideUp",
      },
    };
  };
}

export { SignIn };
export default Entities.createPlace(SignIn, {
  name: "SignIn",
});
