import React from 'react';
import List from '@material-ui/core/List';
import tokenManager from '@utils/lib/tokenManager';
import _ from 'lodash';
import Helper from '@core/helpers'
import Entities from '@toolkit/entities';
import Button from '@web-bricks/Shared/Input/button';
import Container from '@core/modules/shared/views/Things/container.js';
import ShowAddress from '@core/modules/User/views/Places/address/showAddress';
import AddCircleIcon from '@material-ui/icons/AddCircle';

class ManageAddresses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileId: tokenManager.user,
        };
        this.helper = new Helper.UserSigninHelper()
        this.userHelper = new Helper.UserHelper()
    }

    async getData() {
        const user = await this.helper.getUserById(tokenManager.user)
        const locationList = await this.userHelper.getLocationFormate(user)
        let currentLocation = await Helper.CartHelper.getUserLocation(tokenManager.user)
        this.setState({
            user: user,
            locationList: locationList,
            currentLocation: currentLocation
        })
    }

    onAddressSubmit = async (values) => {
        let location = _.find(this.state.locationList, { value: values.target.value })
        Helper.CartHelper.selectAddress(location, this.state.user)
    }


    getLabelValues = (formatter, _, transformers) => {
        return ({
            locationList: this.state.locationList
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {

        return (
            <Container
                page={{ title: 'Address Book', secondaryText: 'Select any one of the address below for quick access to delivery across the city' }}
                previousPage={[{ title: 'Profile', onClick: () => this.props.history.push('/profile') }]}
                activePage={'Manage'}
                rightButton={{ buttonLabel: 'new', onClick: () => this.props.history.push('/addAddress?nextRoute=/manageAddresses'), startIcon: <AddCircleIcon /> }}
            >
                <Layout name='main-layout'>
                    <Block >
                        <Bits.Text name='title'>Saved Address</Bits.Text>
                        <List>
                            <ShowAddress />
                        </List>
                    </Block>
                </Layout>
            </Container>
        );
    }
    getStylesheet = (colorPallete) => {
        return ({
            'background': {
                master: {
                    backgroundImage: `url(${require('@core/assests/images/Icons/address-book.png').default}`,
                    width: 75,
                    backgroundRepeat: 'no-repeat'
                }
            },
            'main-layout': {
                master: {
                    //width: '50%',
                    //margin: '40px',
                    marginLeft: 30
                },
                mobile: {
                    width: '95%',
                    margin: '50px auto'
                }
            },
            'address-book-intro': {
                master: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '20px 0px'
                }
            },
            'address-header': {
                master: {
                    fontSize: 22,
                    margin: 0,
                    fontWeight: 'bold'
                }
            },
            'title': {
                master: {
                    fontSize: 18,
                    color: 'grey',
                    margin: 0,
                    fontWeight: 600
                }
            },
            'add-new-address': {
                master: {
                    margin: '20px 0px'
                }
            },
        })
    }

}

export default Entities.createPlace(ManageAddresses, {
    name: 'ManageAddresses'
});