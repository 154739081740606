import React from 'react';
import _ from 'lodash';

export default Component => class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || props.defaultValue,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(nextProps.value, this.props.value)) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    getValue = () => {
        return this.state.value;
    }

    setValue = (value) => {
        this.setState({
            value,
        });
    }

    render() {
        return (
            <Component
                {...this.props}
                ref={this.props.innerRef}
                getValue={this.getValue}
                setValue={this.setValue}
                value={this.state.value}
            />
        )
    }
};