import React from 'react';
import Form from '@web-bricks/Shared/form/components/form';
import Entities from '@toolkit/entities';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SmsIcon from '@material-ui/icons/Sms';
import TextOtpInput from '@web-bricks/Shared/Input/passwordInput'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import persistentStorage from '@utils/lib/localStorage';
import interiorDesigner from "@toolkit/lib/interiorDesigner";

const classes = ({
  '@global': {
    body: {
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
  },
});

class Password extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      keyValue:0,
      errorFlag: false
    }
  }
  onSubmit = (values) => {
    let user = persistentStorage.get('userDetails')
    this.props.signInHelper.onOtpCheck(values.password, (result) => {
      switch (result) {
        case 'NEW-USER': 
          this.onComplete({
            nextRoute: `/createUser/details/${user._id}`,
          })
          break;
        case 'STAFF':
          this.onComplete({
           nextRoute: '/vendor/dashboard'
          })
          break;
        case 'ADMIN':
          this.onComplete({
           nextRoute: '/vendor/dashboard'
          })
          break;
        case 'CUSTOMER':
          this.onComplete({
            nextRoute: '/',
          })
          break;
        case 'MISSMATCHED':
          this.setState({
            errorFlag: true,
            errorMessage : 'Invalid OTP!'
          })
          break;
        case 'TIMEOUT':
          this.setState({
            errorFlag: true,
            errorMessage : 'TIMEOUT'
          })
          break;
        default:
            console.log('new request raised')
            break;
      }
     }
    )
    values.onSuccess();
  }

  getLabelValues = (formatter, _, transformers) => {
    return ({
      phoneNumber: this.props.signInHelper.getPhoneNumber()
    })
  }

  onResendOTP = () =>{
    this.props.signInHelper.sendOtp(this.props.signInHelper.getPhoneNumber())
    this.setState({
      keyValue : this.state.keyValue + 1
    })
  }

  handleChange = otp => this.setState({ otp });

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    Bits,
    View,
  }) {
    return (
      <MainLayout name='main-layout'>
        <View landing>
          <Layout name='password'>
            <Layout>
              <Block>
                <ArrowBackIosIcon style={{ cursor: 'pointer' }} onClick={() => this.goToView('/account/signin')} />
              </Block>
            </Layout>
            <Layout>
              <Block name='password-header'>
                <Bits.Text type='h1' name='header'>
                  Verify OTP
                </Bits.Text>
              </Block>
            </Layout>
            <Layout>
              <Block name='login-message'>
                <SmsIcon style={{ color: interiorDesigner.colorPallete.primary }} />
                <Bits.Text type='p' name='login-message-text'>{`Enter the OTP send to +91 ${values.phoneNumber}`}</Bits.Text>
              </Block>
            </Layout>
            <Layout>
              <Block name="password-block">
                <Form key={this.state.keyValue} submitButton={{
                  label: <ArrowForwardIcon name='submit-icon' style={{
                    color: 'white',
                    backgroundColor: interiorDesigner.colorPallete.primary,
                    borderRadius: 22,
                    padding: 11
                  }} />
                }} className={classes.form} noValidate onSubmit={this.onSubmit} buttonPosition='row'>
                  <Block name='signin-container'>
                    <Block name="signin">
                      <TextOtpInput
                        hasErrored={this.state.incorrectOtp}
                        numInputs={6}
                        name='password'
                        data-cy	='otp'
                      />
                    </Block>
                  </Block>
                </Form>
                {
                  this.state.errorFlag &&
                  <Block name='resend-otp-block'>
                    <Bits.Text name='error-message'>{this.state.errorMessage === 'TIMEOUT' ? 'OTP expired':'incorrect OTP!' }</Bits.Text>
                    {this.state.errorMessage === 'TIMEOUT' && <Bits.Text name='error-message-expire' onClick={this.onResendOTP}>Resend OTP?</Bits.Text>}
                  </Block>
                }
              </Block>
            </Layout>
          </Layout>
        </View>
      </MainLayout>
    );
  }


  getStylesheet = (colorPallet) => {
    return ({
      'resend-otp-block':{
        master:{
          display:'flex',
          justifyContent:'space-between'
        }
      },
      'main-layout': {
        master: {
        }
      },
      'signin-container': {
        master: {
          display: 'flex',
          //width:'100%'
          //margin:'50px 20px'
        }
      },
      'error-message': {
        master: {
          fontSize: 12,
          color: 'red',
          margin: 0
        }
      },
      'error-message-expire':{
        master: {
          fontSize: 12,
          color: 'red',
          margin: 0,
          textDecoration: 'underline',
          cursor:'pointer'
        }
      },
      'signin': {
        master: {
          display: 'flex',
          flexDirection: 'row',
          //margin: 'auto'
        }
      },
      'password-block': {
        master: {
          width: '50%'
        }

      },
      'password-header': {
        master: {
          fontSize: 18
        }
      },
      'header': {
        master: {
          color: colorPallet.primary
        }
      },
      'submit': {
        master: {
          margin: 'auto'
        }
      },
      'login-message': {
        master: {
          display: 'flex',
          marginTop: 20
        }
      },
      'login-message-text': {
        master: {
          margin: 0,
          fontSize: 15,
          marginLeft: 5,
          color: colorPallet.primary
        }
      },
      'password': {
        master: {
          padding: '70px 35px',
          height: '100vh',
          width: '60%',
          margin: '0 auto',
        },
        mobile: {
          padding: '50px 0px',
          width: '90%',
        },
        transition: 'children-slideRight'
      },
      'resend-otp': {
        master: {
          marginTop: 20
        }
      },
      'message-to-resend': {
        master: {
          fontSize: 12,
          margin: 0
        }
      },
      'resend-otp-button': {
        master: {
          color: '#D03C3C',
          fontSize: 15,
          marginTop: 5
        }
      },
      'signin': {
        master: {
          marginTop: 25,
          marginLeft: -8
        }
      },
      'top-bar': {
        master: {
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 30
        }
      },
    })
  }
}

export default Entities.createPlace(Password, {
  name: 'Password'
});
