import React from 'react';
import _ from 'lodash';
import cartHelper from '@core/helpers/cartHelper.js';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import { ShoppingBasket } from '@material-ui/icons';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import tokenManager from '@utils/lib/tokenManager';
import toolkit from '@toolkit'

class CartBasketIcon extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            count: cartHelper.getCount() || 0,
            cart: cartHelper.getCart(),
        };
        cartHelper.addListener((cart) => {
            this.setState({
                count: cartHelper.getCount(),
                cart,
            })
        }, 'item-list')
    }

    getLabelValues = () => ({
        isStaff: tokenManager.isStaff(),
    })

    renderSurface(values, {
        Bits
    }) {
        return !values.isStaff && (
            <Bits.IconButton onClick={() => { this.props.pushToCart() }} name='fab-cart-icon'>
                <Badge color="secondary" badgeContent={this.state.count}>
                <Bits.Icon
                    name="cart-basket-icon-link"
                >ShoppingBasket</Bits.Icon>
                </Badge>
            </Bits.IconButton>
        )

    }

    getStylesheet = (colorPallete) => ({
        'cart-basket-icon-link': {
            master: {
                color: this.props.color || colorPallete.primary
            }
        }
    })
}

export default toolkit.Entities.createThing(CartBasketIcon, {
    name: 'CartBasketIcon',
});