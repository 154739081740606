import React from "react";
import _ from "lodash";
import Helper from "@core/helpers";
import Entities from "@toolkit/entities";
import { InfiniteLoader, Grid, AutoSizer } from "react-virtualized";
import InventoryCard from "./inventoryCard";
import SimpleInventory from "./simpleInventory";
import Skeleton from "@material-ui/lab/Skeleton";
import { calendarFormat } from "moment";
import { CallReceived } from "@material-ui/icons";
import withCart from "@core/modules/Cart/views/lib/withCart";
import interiorDesigner from "@toolkit/lib/interiorDesigner";

class InventoryLineItem extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    const { group, Block, Bits, mainconfig, isMobile, key, style } = props;
    return (
      <div>
        <div
          onClick={this.expandView}
          index={key}
          style={_.merge({}, style, {
            display: "flex",
            justifyContent: isMobile ? "space-between" : "unset",
            borderBottom: isMobile ? "0.2px solid #b5b5b5" : "unset",
            alignItems: isMobile ? "center" : "unset",
            flexDirection: isMobile ? "unset" : "column",
            margin: isMobile ? "unset" : "10px 0px",
            cursor: "pointer",
          })}
        >
          {group.discountPercentage > 0 && (
            <div
              className="discount-badge"
              style={props.getAutoStyles("discount-badge")}
            >
              <p
                className="discount-percentage"
                style={props.getAutoStyles("discount-percentage")}
              >
                {group.discountPercentage}%
              </p>
              <p
                className="secondary-text"
                style={props.getAutoStyles("secondary-text")}
              >
                OFF
              </p>
            </div>
          )}
          <div
            className="inventory-card-image"
            style={props.getAutoStyles("inventory-card-image")}
          >
            <Bits.Image
              noLazy
              src={
                _.isNil(group.image) ? mainconfig.shop.compactLogo : group.image
              }
              name={
                _.isNil(group.image) ? "image-place" : "inventory-image-block"
              }
            />
          </div>
          <div>
            <Block name="inventory-details">
              <p
                className="card-title"
                style={props.getAutoStyles("card-title")}
              >
                {group.label}
              </p>
              {_.size(group.inventories) > 1 && (
                <Bits.Text
                  style={{
                    margin: 0,
                    paddingRight: 8,
                    fontSize: 11,
                    width: "100%",
                    color: "#5da356",
                  }}
                >
                  Starting from
                </Bits.Text>
              )}
              <Block name="price-block">
                {group.rates.MRP === group.rates.saleRate ? (
                  <Bits.Currency
                    value={group.rates.saleRate}
                    name="card-content"
                  />
                ) : (
                  <Block name="rates">
                    <Bits.Currency
                      value={group.rates.saleRate}
                      name="card-content"
                    />
                    {_.size(group.inventories) <= 1 && (
                      <Bits.Currency
                        value={group.rates.MRP}
                        name="card-content-strike"
                      />
                    )}
                  </Block>
                )}
                {this.renderQuickAdd()}
              </Block>
            </Block>
          </div>
        </div>
        {this.renderExpandView()}
      </div>
    );
  }
}

InventoryLineItem = withCart(InventoryLineItem);
class InventoryListByCategory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      renderedBlocks: 9,
    };

    this.fetchCompleted = false;
    this.inventoryHelper = new Helper.InventoryHelper();

    this.gridRef = React.createRef();
    this.pool = _.get(props.sections, "pool", []);
    this.inventoryBook = [];
    this.groupedInventories = [];
    this.requestMap = {};
    this.cellRenderer = this.cellRenderer.bind(this);
    this.renderGrid = this.renderGrid.bind(this);
    this.getColumnCount = this.getColumnCount.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onSectionRendered = this.onSectionRendered.bind(this);
    this.getRowCount = this.getRowCount.bind(this);
    this.isRowLoaded = this.isRowLoaded.bind(this);
  }

  getData() {
    if (!_.isEmpty(this.pool)) {
      this.fetchInventories({
        sectionName: _.head(this.pool),
        load: 15,
        index: 0,
      });
      this.startFetchingNextSectionInventories(this.props.sections);
    }
  }

  componentDidUpdate() {
    // if(_.isEmpty(this.pool)) {
    //     this.pool = this.props.sections.pool;
    //     this.fetchInventories({
    //         sectionName: _.get(this.props.sections, `pool.0`),
    //         load: 15,
    //         index: 0
    //     })
    //     this.startFetchingNextSectionInventories(this.props.sections)
    // }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  getColumnCount() {
    return this.deps.interiorDesigner.isMobile() ? 1 : 4;
  }

  fetchInventories = async ({ sectionName, index, delay, load }) => {
    if (this.unmounted) return;

    const inventories = await this.inventoryHelper.getSectionInventories(
      sectionName,
      this.props.categoryId,
      this.state.list
    );

    if (this.unmounted) return;

    const inventoryBook = this.inventoryBook;

    const groupedInventories = [];
    _.forIn(_.groupBy(inventories, "label"), (inventories, label) => {
      const rates = _.head(inventories).rates || {};
      const tags = _.head(inventories).tags;
      const saleRate = rates.MRP === rates.saleRate ? 0 : rates.saleRate;
      const discountPercentage = _.ceil(
        ((rates.MRP - rates.saleRate) / rates.MRP) * 100
      );

      groupedInventories.push({
        label: _.capitalize(label),
        inventories,
        discountPercentage,
        rates,
        tags,
        image: _.get(_.head(inventories), `media.0.url`),
        section: sectionName,
        sectionIndex: index,
      });
    });

    _.set(inventoryBook, index, groupedInventories);
    this.inventoryBook = inventoryBook;

    const seqFills = _.takeWhile(
      this.props.sections.pool,
      (sectionName, index) => !_.isUndefined(this.inventoryBook[index])
    );

    let newList = [];
    _.each(seqFills, (sectionName, index) => {
      newList = _.uniqBy(_.concat(newList, this.inventoryBook[index]), "label");
    });

    const anyResolver =
      _.get(this.requestMap, this.groupedInventories.length) || _.noop;
    const lastBlockNo = this.groupedInventories.length;

    this.groupedInventories = newList;

    if (seqFills.length === this.props.sections.pool.length) {
      this.fetchCompleted = true;
    }
    if (newList.length > lastBlockNo) {
      anyResolver();
    }

    this.setState({
      renderedBlocks: _.size(this.groupedInventories),
    });
    this.requestMap[lastBlockNo] = null;
  };

  componentDidUpdate() {
    if (_.isEmpty(this.pool) && this.props.sections) {
      this.pool = this.props.sections.pool;
      this.fetchInventories({
        sectionName: _.get(this.props.sections, `pool.0`),
        load: 15,
        index: 0,
      });
      this.startFetchingNextSectionInventories(this.props.sections);
    }
  }

  startFetchingNextSectionInventories = (sections) => {
    _.each(_.tail(sections.pool), (section, index) => {
      setTimeout(() => {
        this.fetchInventories({
          sectionName: section,
          load: 15,
          index: index + 1,
        });
      }, (index + 1) * 300);
    });
  };

  isFetchingCompleted() {
    return this.fetchCompleted;
  }

  getRowCount() {
    return (
      _.ceil(this.state.renderedBlocks / this.getColumnCount()) +
      (!this.isFetchingCompleted() && 1)
    );
  }

  getLabelValues() {
    return {
      sections: _.get(this.props.sections, "pool"),
    };
  }

  isRowLoaded({ index }) {
    const lastBlock = index * this.getColumnCount();
    return !_.isEmpty(this.groupedInventories[lastBlock]);
  }

  loadMore({ startIndex, stopIndex }) {
    // this.requestMap =
    const firstBlockNo = startIndex
      ? (startIndex + 1) * this.getColumnCount() - 1
      : 0;
    return new Promise((resolve, reject) => {
      if (this.groupedInventories[firstBlockNo + 1]) {
        resolve();
      } else {
        this.requestMap[`${this.groupedInventories.length || 0}`] = () => {
          resolve();
        };
      }
    });
  }

  overscanIndicesGetter = ({
    direction, // One of "horizontal" or "vertical"
    cellCount, // Number of rows or columns in the current axis
    scrollDirection, // 1 (forwards) or -1 (backwards)
    overscanCellsCount, // Maximum number of cells to over-render in either direction
    startIndex, // Begin of range of visible cells
    stopIndex, // End of range of visible cells
  }) => {
    return {
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(
        cellCount - 1,
        stopIndex + overscanCellsCount
      ),
    };
  };

  renderGrid({ onRowsRendered, registerChild }) {
    // const list = _.pick()

    this._onRowsRendered = onRowsRendered;
    const { Block, Layout } = this.deps;
    const self = this;
    const isMobile = this.deps.interiorDesigner.isMobile();
    const columnWidth = isMobile ? window.screen.width - 11 : 220;
    const height = window.screen.height - (isMobile ? 75 : 100);
    return (
      <Layout
        style={{
          display: "flex",
          width: isMobile ? "100vw" : "100%",
          height: "100%",
        }}
        name='layout'
      >
        <AutoSizer disableHeight>
          {function ({ width }) {
            return (
              <Block style={{ flex: "1 1 auto", height: "100%" }}>
                <Grid
                  ref={(ref) => {
                    registerChild = ref;
                    self.gridRef = ref;
                  }}
                  rowCount={self.getRowCount()}
                  rowHeight={isMobile ? 180 : 300}
                  columnCount={self.getColumnCount()}
                  width={width}
                  height={height}
                  columnWidth={columnWidth}
                  overscanIndicesGetter={self.overscanIndicesGetter}
                  cellRenderer={self.cellRenderer}
                  onSectionRendered={self.onSectionRendered}
                  scrollToAlignment="start"
                  name='grid'
                />
              </Block>
            );
          }}
        </AutoSizer>
      </Layout>
    );
  }

  renderSkeleton(style, key) {
    return (
      <div key={key} index={key} style={{ ...style }}>
        <Skeleton
          style={{
            width: style.width,
            height: style.height - 40,
            marginBottom: 10,
            boxSizing: "border-box",
          }}
          type="rectangle"
        />
        <Skeleton
          style={{
            width: style.width,
            height: 30,
            marginRight: 10,
            boxSizing: "border-box",
          }}
          type="rectangle"
        />
      </div>
    );
  }

  onSectionRendered({
    columnStartIndex,
    columnStopIndex,
    rowStartIndex,
    rowStopIndex,
  }) {
    const startIndex = rowStartIndex;
    const stopIndex = rowStopIndex;

    this._onRowsRendered({
      startIndex,
      stopIndex,
      columnStartIndex,
      columnStopIndex,
    });
  }

  getAutoStyles = (key) => {
    const isMobile = this.deps.interiorDesigner.isMobile();
    return _.merge(
      {},
      this.styles[key].master,
      isMobile && { ...this.styles[key].mobile }
    );
  };

  // cellRenderer({columnIndex, key, rowIndex, style}) {

  //     const group = _.get(this.groupedInventories, `${(((this.getColumnCount()) * rowIndex) + columnIndex)}`);
  // }

  styles = {
    "discount-badge": {
      master: {
        background: interiorDesigner.colorPallete.teritary,
        position: "absolute",
        right: 40,
        width: 30,
        height: 30,
        padding: 5,
        top: 0,
        textAlign: "center",
        zIndex: 1,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
      },
      mobile: {
        left: 15,
        width: 28,
        height: 28,
        padding: 5,
        right: "unset",
      },
    },
    "discount-percentage": {
      master: {
        color: "white",
        fontWeight: "bolder",
        fontSize: 13,
        margin: "auto",
        //marginTop: 2
      },
      mobile: {
        fontSize: 14,
      },
    },
    "secondary-text": {
      master: {
        margin: "1px 0px",
        color: "white",
        fontSize: 8,
        fontWeight: "bold",
        //paddingTop: 1
      },
      mobile: {
        fontSize: 8,
      },
    },
    "inventory-card-image": {
      master: {
        width: "100%",
        maxWidth: 180,
        height: 180,
        position: "relative",
      },
      mobile: {
        maxWidth: 170,
        height: "95%",
        marginLeft: 5,
      },
    },
    "card-title": {
      master: {
        maxWidth: 180,
        fontSize: 16,
        color: "#4e4e4e",
        margin: "13px 0px 3px 0px",
        lineHeight: 1.4,
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "auto",
      },
      mobile: {
        fontSize: 18,
        minWidth: 180,
        margin: "10px 0px",
        lineHeight: 1.2,
        height: "auto",
        margin: 0,
        fontWeight: 600,
        color: "#4e4e4e",
      },
    },
  };

  cellRenderer({ columnIndex, key, rowIndex, style }) {
    const group = _.get(
      this.groupedInventories,
      `${this.getColumnCount() * rowIndex + columnIndex}`
    );

    const { Block, Bits, mainconfig } = this.deps;
    const isMobile = this.deps.interiorDesigner.isMobile();
    // <InventoryCard
    //             index={key}
    //             group={group}
    //             onClick={() => this.imageClick(group)}
    //             onCountChange={this.cartUpdate}
    //         />
    // <SimpleInventory group={group} style={style} />
    // <SimpleInventory group={group} style={style} />
    //
    //         <Block name="inventory-card-image">
    //
    //     </Block>
    // <Block key={key} index={key}  style={style}>
    //     {
    //         group.discountPercentage > 0 &&
    //           <Block name='discount-badge'>
    //             <Bits.Text name='discount-percentage'>{group.discountPercentage}%</Bits.Text>
    //             <Bits.Text name='secondary-text'>OFF</Bits.Text>
    //           </Block>
    //       }
    //         <Bits.Text name="card-title">

    //             {group.label}
    //         </Bits.Text>
    //         {
    //             <Block>
    //                 {
    //                     _.size(group.inventories) > 1 && <Bits.Text>From</Bits.Text>
    //                 }
    //                 {
    //                     group.rates.MRP === group.rates.saleRate ?
    //                         <Bits.Currency value={group.rates.saleRate} name="card-content" /> :
    //                         <Block name="rates">
    //                             <Bits.Currency value={group.rates.saleRate} name="card-content" />
    //                             <Bits.Currency value={group.rates.MRP} name="card-content-strike" />
    //                         </Block>
    //                 }

    //             </Block>
    //           }

    //     </Block>

    return group ? (
      <InventoryLineItem
        key={key}
        index={key}
        inventoryBook={this.inventoryBook}
        onSelect={this.props.onSelect}
        group={group}
        Block={Block}
        Bits={Bits}
        mainconfig={mainconfig}
        getAutoStyles={this.getAutoStyles}
        isMobile={isMobile}
        style={style}
      />
    ) : this.isFetchingCompleted() ? null : (
      this.renderSkeleton(style, key)
    );
  }

  jumpToSection = (section, index) => () => {
    const lastBlockNo = _.reduce(
      _.slice(this.props.sections.pool, 0, index),
      (sum, section, index) => {
        sum = sum + _.size(this.inventoryBook[index]);
        return sum;
      },
      0
    );

    this.gridRef.scrollToCell({
      columnIndex: 1,
      rowIndex: _.floor(lastBlockNo / this.getColumnCount()),
    });
  };

  renderSurface(values, { Bits, MainLayout, Layout, Block }) {
    return (
      <MainLayout name="inventory-wheeler-main-layout">
        <Layout style={{ minWidth: 300 }}>
          <Block
            name="section-tabs"
            className="thin-scroll"
            skeleton={{ type: "rectangle", cols: 8, width: "300" }}
          >
            {_.map(values.sections, (section, index) => (
              <Block
                name="section-container"
                onClick={this.jumpToSection(section, index)}
              >
                <Bits.Text name="section-name">
                  {_.capitalize(section)}
                </Bits.Text>
              </Block>
            ))}
          </Block>
        </Layout>
        <Layout name="section-inventories">
          <Block style={{ width: "100%", height: "100%" }}>
            <InfiniteLoader
              isRowLoaded={this.isRowLoaded}
              loadMoreRows={this.loadMore}
              rowCount={this.getRowCount()}
              minimumBatchSize={9}
              treshold={1}
            >
              {this.renderGrid}
            </InfiniteLoader>
          </Block>
        </Layout>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => ({
    "inventory-wheeler-main-layout": {
      master: {
        display: "flex",
        width: "100%",
      },
      mobile: {
        flexDirection: "column",
      },
    },
    "card-content-strike": {
      master: {
        fontSize: 12,
        color: colorPallete.darkGrey,
        textDecoration: "line-through",
      },
    },
    "section-container": {
      master: {
        textAlign: "center",
        cursor: "pointer",
      },
      mobile: {
        margin: "0px 5px",
      },
    },
    "image-place": {
      master: {
        objectFit: "none",
        width: "100%",
        opacity: 0.9,
      },
    },
    "section-name": {
      master: {
        margin: "4px 0px",
        fontSize: 15,
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "6px 16px",
        borderRadius: 20,
        color: "#5da356",
        background: "#e7e7e7",
      },
      mobile: {
        margin: 0,
        padding: "6px 20px",
      },
    },
    // SECTION STYLES WHILE ACTIVE
    // "section-name-active": {
    //   master: {
    //     fontWeight: 600,
    //     color: "#fff",
    //     background: "#5da356",
    //   },
    // },
    "inventory-details": {
      master: {
        width: 180,
      },
      mobile: {
        width: "100%",
      },
    },
    rates: {
      master: {
        display: "flex",
        alignItems: "center",
      },
    },
    "price-block": {
      master: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#5da356",
        height: 30,
      },
      mobile: {
        marginLeft: 0,
      },
    },
    "card-content": {
      master: {
        fontSize: 18,
        fontWeight: 600,
        marginRight: 10,
      },
    },
    "inventory-image-block": {
      master: {
        objectFit: "cover",
        borderRadius: 4,
        width: "100%",
        height: "100%",
      },
    },
    "section-tabs": {
      master: {
        display: "flex",
        flexDirection: "column",
        marginTop: 200,
        height: "calc(100vh - 240px)",
        overflow: "scroll",
      },
      mobile: {
        alignItems: "center",
        flexDirection: "unset",
        height: 64,
        margin: 0,
        paddingBottom: 10,
        background: "unset",
        position: "sticky",
      },
    },
    "section-inventories": {
      master: {
        width: "calc(100% - 300px)",
        height: "100%",
        marginTop: 90,
        marginLeft: 50,
      },
      mobile: {
        width: "100%",
        height: "auto",
        marginTop: 0,
        marginLeft: 0,
      },
    },
  });
}

export default Entities.createThing(InventoryListByCategory, {
  name: "InventoryWindow",
  hasSkeleton: true,
});
