import _ from 'lodash';
import request from "./request";


class BaseModel {

    constructor(collectionName) {
        this.collectionName = collectionName;
    }
    create = async (info) => {
        return request.post(`${this.collectionName}/create`, info);
    }

    createMany = async (data) =>{
        return request.post(`${this.collectionName}/createMany`, data)
    }

    getById = async (_id, options) => {
        const object = await request.post(`${this.collectionName}/getById`, {_id, options});
        return object
    }

    update = async (_id, data, options) =>{
        request.post(`${this.collectionName}/update`, {_id, data, options});
    }

    find = async ({query, options}) =>{
        let document = request.post(`${this.collectionName}/find`, {query, options});
        return document
    }

    deleteMany = async (ids) =>{
        request.post(`${this.collectionName}/deleteMany`, {ids});
    }

}

export default BaseModel;
