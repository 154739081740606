import React,{ Component } from 'react';
import Avatar from './avatar/index.js';

class CustomAvatar extends Component {
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div>
                <Avatar user={this.props.user} size={this.props.size}/>
            </div>
        )
    }
} 

export default CustomAvatar;