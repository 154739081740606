import React, { Component } from 'react';
import api from '@api';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import endpoints from '@api';
import Form from "@web-bricks/Shared/form/components/form";
import FormMaker from '@web-bricks/Shared/form/components/formMaker';


class TemplateList extends Component {
    constructor(props) {
      super(props)
      this.state = {
        users : [],
        selectedUsers: {}
      }
    }

    async getData() { 
        const users = await endpoints.User.find({query:{}})
        this.setState({
            users: users,
        })
    };

    getHeaders = () => {
        const columns = _.concat(
            [
                {
                    field: 'name',
                    headerName: 'Name',
                    type: "string",
                    width: 200
                },
                {
                    field: 'phone',
                    headerName: 'Phone',
                    type: "number",
                    width: 200
                },
                {
                    field: 'email',
                    headerName: 'Email',
                    type: "string",
                    width: 300
                },
            ],
        );
        return columns
    };

    getRows = (users) => {
        const getUsersList = _.map((users), (user) => ({
            id: user._id,
            ...user,
        }))
        return getUsersList
    }

    onSelectionModelChange = (selectedUsers) => {
        this.setState({
            selectedUsers: selectedUsers
        })
    }
    
  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    View,
    Bits
  }) {
    return (
        <MainLayout>
            <View>
                <Layout>
                    <Block>
                        <Form>
                            <FormMaker
                                fields={[
                                    {
                                        type: "DataTable",
                                        props: {
                                            name: 'userList',
                                            columns: this.getHeaders(),
                                            handleSelectionModelChange: this.onSelectionModelChange,
                                            rows: this.getRows(this.state.users)
                                        }
                                    }
                                ]}
                            />
                        </Form>
                    </Block>
                </Layout>
            </View>
        </MainLayout>
    );
  }
}

export default Entities.createPlace(TemplateList, {
    name: 'TemplateList'
});
