import React,{ Component } from 'react';
import _ from 'lodash';
import toolkit from '@toolkit';
import {RatingStars} from '@web-bricks/Shared/Input/rating';
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar';

class ReviewsList extends Component {
        constructor(props) {
            super(props);
            this.state = {
    
            }
        }
    
        async getData() {
            this.setState({

            })
        }
        getLabelValues = (formatter, _, transformers) => {
            return ({
                
            })
        }
    
        renderSurface(values, {
            MainLayout,
            Layout,
            Block,
            Bits,
            View,
            Switch,
            interiorDesigner
        }) {  
        return (
            <MainLayout name='reviews-list'>
                <View>
                    <Layout>
                        <Block>
                            <Bits.Text name='heading-text'>Reviews & Ratings</Bits.Text>
                            {
                                this.props.avgRating &&
                                <Block name='avg-rating-block'>
                                    <RatingStars defaultValue={this.props.avgRating} readOnly size="large"/>
                                    <Bits.Text name='avg-rating-text'>{` ${this.props.avgRating}`}</Bits.Text>
                                </Block>
                            }
                            {
                                _.map(this.props.reviewsList, item =>{
                                    return(
                                        <Block name='review-block'>
                                            <Block name='reviewer-block'>
                                                <Avatar src="/broken-image.jpg" style={{ width: 24, height: 24 }}/>
                                                <Bits.Text name='reviewer-name'>{item.reviewer.name}</Bits.Text>
                                            </Block>
                                            <Block name='star-rating-block'>
                                                <RatingStars defaultValue={item.rating} readOnly size="small"/>
                                            </Block>
                                            <Block>
                                                <Bits.Text name='review-text'>{item.review}</Bits.Text>
                                            </Block>
                                            <Block>
                                                <Bits.Text name='reviewed-date'>{`${moment(item.createdAt).fromNow()}`}</Bits.Text>
                                            </Block>
                                        </Block>
                                    )
                                })
                            }
                        </Block>
                    </Layout>
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({ 
            'reviewer-block':{
                master:{
                    display:'flex',
                    marginBottom:5
                }
            },
            'reviewer-name':{
                master:{
                    margin:'0px 0px 0px 5px'
                }
            },
            'reviewed-date':{
                master:{
                    margin:'5px 0px',
                    fontSize:12,
                    color: colorPallete.subtext
                }
            },
            'avg-rating-text':{
                master:{
                    margin:'3px 10px',
                    fontSize:20,
                    fontWeight:"600"
                }
            },
            'avg-rating-block':{
                master:{
                    display:'flex',
                    margin:'5px 0px'
                }
            },
            'review-block':{
                master:{
                    margin:'25px 0px 20px 10px'
                }
            }, 
            'reviews-list':{
                master:{
                    marginLeft:50
                }
            },       
            'star-rating-block':{
                master:{
                    margin:'5px 0px'
                }
            },
            'review-text':{
                master:{
                    margin:0
                }
            },
            'heading-text':{
                master:{
                    margin:0,
                    fontSize: 20,
                    fontWeight:'bold'
                }
            }
        })
    }
    
}

export default  toolkit.Entities.createPiece(ReviewsList, {
    name: 'ReviewsList'
});