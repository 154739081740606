import GenerateNumber from '@utils/lib/generateNumber';
import api from '@api';
import tokenManager from '@utils/lib/tokenManager';
import OrdersHelper from './orderHelper';
import config from '@config/index';
import interiorDesigner from '@toolkit/lib/interiorDesigner'
import _ from 'lodash'
class TransactionHelper {
    
    constructor() {
        this.cart = {};
        this.orderHelper = new OrdersHelper()

        this.shop = config.getConfig('shop');
        this.razorpayCreds = config.getConfig('razorpay')
    }

    createOrderForTransaction = async (cart,email, successCb, errorCb) =>{
        const generateNumber = new GenerateNumber()
        const number = generateNumber.generate()

        this.cart = cart;

        const options = {
            amount : _.ceil(this.cart.billableAmount * 100),
            currency : 'INR',
            receipt : number
        }
        const transaction = await api.Transactions.createRazorpayOrder(options)
        const paymentSuccessfull = await this.createRazorpayTransaction(transaction,email, successCb, errorCb)
    }

    createRazorpayTransaction = async (order, email, successCb, errorCb) =>{
        let orderDetails ={}
        let chechOutResponse = {}
        var checkOutDetails = {
            "amount": this.cart.billableAmount,
            "currency": "INR",
            "name": this.shop.name,
            "description": this.shop.description,
            "image": this.shop.logo,
            "order_id": order.id,
            "prefill": {
                "name": tokenManager.name,
                "email": email,
                "contact": tokenManager.phone
            },
            "theme": {
                "color": interiorDesigner.colorPallete.primary
            },
            // "transfers": [
            //     {
            //       "account": this.shop.bankaccount.razorpayAccountId,
            //       "amount": order.billableAmount,
            //       "currency": "INR",
            //       "notes": {
            //         "branch": this.shop.bankaccount.branch,
            //         "name": this.shop.bankaccount.name
            //       },
            //       "linked_account_notes": [
            //         "branch"
            //       ],
            //       "on_hold": 0,
            //       //"on_hold_until": 1671222870
            //     }
            //   ]
            }
        await this.createTransaction(checkOutDetails)
        var options = {
            "key_id": this.razorpayCreds.key_id,
            "key_secret": this.razorpayCreds.key_secret,
            ...checkOutDetails,
            "handler": async (response) =>{
                chechOutResponse ={
                    paymentId : response.razorpay_payment_id,
                    orderId : response.razorpay_order_id,
                    razorpaySignature : response.razorpay_signature
                }
                let query = {
                    orderId : chechOutResponse.orderId
                }
                const transaction = await this.findTransaction(query)
                orderDetails = await this.orderHelper.placeOrder(this.cart, transaction._id)
                console.log(orderDetails)
                this.succeedRazorpayTransaction(chechOutResponse, transaction._id, successCb)
            },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', (response) => {
            chechOutResponse ={
                code : response.error.code,
                description : response.error.description,
                source : response.error.source,
                step : response.error.step,
                reason : response.error.reason,
                orderId : response.error.metadata.order_id,
                paymentId : response.error.metadata.payment_id,
            }
            this.failedRazorpayTransaction(chechOutResponse, errorCb)
        });
        rzp1.open();
        return orderDetails
    }

    succeedRazorpayTransaction = async (chechOutResponse, _id, cb) => {
        const data = {
            ...chechOutResponse,
            status : "success"
        }
        // let authentic_source = await api.Transactions.chechAuth(_id, chechOutResponse.paymentId, this.razorpayCreds.key_secret, chechOutResponse.razorpaySignature)
        // if(authentic_source){
            await api.Transactions.update(_id, data);
            cb()
        // }else{
        //     console.log(cb)
        // }
    }

    failedRazorpayTransaction = async (chechOutResponse, cb) =>{
        const data ={
            ...chechOutResponse,
            status : "failed"
        }
        // await api.Transactions.update(chechOutResponse.orderId, data)
        await api.Transactions.updateFailedTransaction(chechOutResponse.orderId, data)
        cb()
    }

    findTransaction = async (query) =>{
        const transaction = await api.Transactions.find({query})
        return transaction[0]
    }

    createTransaction = async (checkOutDetails) =>{
        const createTrasaction ={
            amount : checkOutDetails.amount,
            orderId:checkOutDetails.order_id,
            initiatedBy:tokenManager.user,
            status : "created"
        }
        return await api.Transactions.create(createTrasaction)
    }

    getTransactionById = async (id) =>{
        const transactionDetails = await api.Transactions.getById(id)
        return transactionDetails
    }

    // aptForRefund = async (order) =>{
    //     await api.Transactions.refund(order, this.razorpayCreds.key_id, this.razorpayCreds.key_secret)
    // }
}

export default TransactionHelper