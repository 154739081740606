import React from 'react';
import _ from 'lodash';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import { Skeleton, View } from '../bits';
import { concatNameChain } from '../lib/utils';


const getSkeleton = (skeleton) => {

    const Rectangle = props => <Skeleton variant="rectangle" {...props} />
    
    const skeletonTypes = {
        rectangle: Rectangle,
        card: props => <div style={{ width: '100%'}}>
            {
                _.times(skeleton.cols, (index) => {
                    return <View key={index} style={{ width: props.width, marginRight: 20, display: 'inline-block' }}>
                        <Rectangle {...props} style={{ ...props.style, boxSizing: 'border-box'}} height={_.isString(props.height) ? props.height : (props.height - 30)} width={props.width} />
                        <Skeleton {...props} style={{ ..._.omit(props.style, ['height']),  boxSizing: 'border-box'}} variant="text" height={30} width={props.width} />
                    </View>
                })
            }
        </div>
    }

    return skeletonTypes[skeleton.type];
}

class Children extends React.PureComponent {
    constructor(props) {
        super(props)
    }
    render() {
        const self = this;
        return (React.Children.map(this.props.children, function(Child, index) {
            return Child ? React.cloneElement(Child, {
                stylesheet: _.merge({}, self.props.stylesheet, Child.props.stylesheet || {}),
                namechain: concatNameChain(self.props.namechain, Child.props.name || Child.name || `blocks.${index + 1}`),
                _blockStyles: self.props._blockStyles
             }) : null
        }) || null)
    }
}

class Block extends React.PureComponent {

    constructor(props) {
        super(props)
        this.skeletonStyles = {};

    }

    getName = () => {
        return this.props.name || ('blocks.' + this.props.childIndex)
    }

    displayName = this.getName()

    render() {
        const self = this;
        const blockStyles = interiorDesigner
            .parentStylesheet(this.props.stylesheet)
            .getStyles(this.getName(), this.props.style, this.props.namechain);

        const ChildrenStylesheet = interiorDesigner
            .parentStylesheet(this.props.stylesheet)
            .getChildrenStylesheet(this.getName());

        
        const hasWaiting = _.hasIn(this.props.skeleton, 'waitFor');
        const waitFor = _.get(this.props.skeleton, 'waitFor');
        const showSkeleton = ((this.props._isParentLoading || (hasWaiting && _.isNil(waitFor))) && this.props.skeleton);

        if (showSkeleton && _.get(this.props.skeleton, 'cover')) {
            this.skeletonStyles = interiorDesigner
                .parentStylesheet(this.props.stylesheet)
                .getStyles(_.get(this.props.skeleton, 'cover'), this.props.style);
        }


        return <View {...this.props} style={blockStyles}>
            {
                (showSkeleton) ? (
                    (function() {
                        const Skeleton = getSkeleton(self.props.skeleton)
                        return <Skeleton
                            style={_.omit(self.skeletonStyles, ['cursor', 'background'])}
                            width={_.get(self.props.skeleton, 'width', _.get(self.skeletonStyles, 'width', '100%'))}
                            height={_.get(self.skeletonStyles, 'height', _.get(blockStyles, 'minHeight', '100%'))}
                        />;
                    })()
                ) : (
                    <Children
                        namechain={this.props.namechain}
                        children={this.props.children}
                        _blockStyles={blockStyles}
                        stylesheet={ChildrenStylesheet}
                    />
                )
            }
        </View>
    }
}

export default Block;