import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import InventoryList from '@core/modules/Inventory/views/Things/inventoriesList';
import FilterListIcon from '@material-ui/icons/FilterList';
import Dailog from '@web-bricks/Shared/view/components/dailog';
import Fab from '@material-ui/core/Fab';
import DialogBox from '@core/modules/shared/views/Things/dialogBox';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import transitionRegistry from '@toolkit/lib/transitionRegistry';
import toolkit from '@toolkit';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Modal from '@web-bricks/Shared/view/components/modal';
import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';
const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2.5}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,

  };
}

const CustomTabs = withStyles({
  root: {
    textTransform: 'capitalize',
    minWidth: 300,
    maxWidth: 300,
    overflow: 'auto',
    height: 'calc(100vh - 182px)',
    alignItems: 'center',
    "@media (max-width: 600px)": {
      height: 'auto',
      alignItems: 'flex-start',
      marginLeft: 15,
      marginTop: 5,
      maxWidth: 'unset',
    }
  }
})(Tabs);

const CustomTab = withStyles({
  root: {
    textTransform: "none",
    fontSize: 23,
    fontWeight: 500,
    lineHeight: '23px',
    "@media (max-width: 600px)": {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '18px',
    }
  },

})(Tab);

class SwipeableTabs extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab || 0,
    }

  }

  getLabelValues = () => ({})

  handleChange = (event, activeTab) => {

    this.setState({
      activeTab
    });
    this.props.onTabChange(activeTab)
  };


  onSectionJump = (section, index) => {
    this.setState({
      showSectionsList: false
    })
    this.handleChange({}, index)
  }


  onChangeIndex = (index) => {
    this.handleChange({}, index)
  }

  renderSurface(values, {
    Block,
    Layout,
    interiorDesigner,
    Bits
  }) {
    // const classes = useStyles();
    return (
      <Layout name="main-container">
        <Layout name="section-container">
          <Block name="section-tabs">
            <Layout name="tab-header-layout">
              <CustomTabs
                orientation={interiorDesigner.isMobile() ? 'horizontal' : "vertical"}
                value={this.state.activeTab}
                onChange={this.handleChange}
                indicatorColor="white"
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
              >
                {
                  _.map(this.props.tabHeaders, (item, index) => {
                    return (
                      <CustomTab
                        key={index}
                        value={index}
                        label={<transitionRegistry.slideUp
                          index={index}
                          key={index}
                          render={transitStyles => {
                            return (
                              <span key={index} style={transitStyles}>{_.capitalize(item)}</span>
                            )
                          }}
                        />}
                        {...a11yProps(index)}
                      />
                    )
                  })
                }

                {/* <Layout name="modal-items-display">
                    <Block name="icons-alignment">
                      <DehazeIcon onClick={()=> this.setState({ showSectionsList: true })} 
                      />
                      
                    </Block>
                  </Layout>   */}
              </CustomTabs>
              {
                this.props.actions
              }
            </Layout>
          </Block>
          <Block name="list">
           <VirtualizeSwipeableViews
              overscanSlideAfter={0}
              overscanSlideBefore={0}
              axis={'x'}
              index={this.state.activeTab}
              onChangeIndex={this.onChangeIndex}
              slideCount={_.size(this.props.tabHeaders)}
              slideRenderer={this.props.component}
              animateTransitions={false}
           >
            </VirtualizeSwipeableViews>
          </Block>
          <Modal escape open={this.state.showSectionsList}>
            {
              _.map(this.props.list, (item, index) => {
                return (
                  <Block key={index} name="items-font-align" onClick={() => this.onSectionJump(item, index)}>
                    <Bits.Text name="items-text-align">{_.capitalize(item.label)}</Bits.Text>
                  </Block>
                )
              })
            }
          </Modal>
        </Layout>
      </Layout>
    );
  }

  getStylesheet = (colorPallete) => {
    return ({
      'tab-header-layout': {
        master: {
          display: 'flex',
          width: '100%',
          flexDirection: 'column-reverse'
        },
        mobile: {
          flexDirection: 'column'
        }
      },
      'modal-items-display': {
        master: {
          position: 'absolute',
          bottom: 0,
          right: 0
        }
      },
      'main-container': {
        master: {
          position: 'relative',
          width: '100%'
        }
      },
      'list': {
        master: {
          position: 'relative',
          left: 300,
          width: 'calc(100% - 260px)',
          //top: 125,
        },
        mobile: {
          marginTop: 0,
          left: 0,
          top: 0,
          width: '100%'
        }
      },
      'section-container': {
        master: {
          display: 'flex',
        },
        mobile: {
          display: 'unset',
          margin: 0
        }
      },
      'section-tabs': {
        master: {
          position: 'fixed',
          top: 250,
        },
        mobile: {
          position: 'unset',
          top: 'unset'
        }
      },
      'items-text-align': {
        master: {
          fontSize: 24,
          textAlign: 'center',
          color: colorPallete.primary
        }
      },
    })
  }
}

SwipeableTabs.defaultProps = {
  onTabChange: _.noop
};

export default toolkit.Entities.createPlace(SwipeableTabs, {
  name: 'SwipeableTabs'
})