import persistentStorage from './localStorage';


class Cache {

    constructor() {
        this.store = {};
    }

    wakeup = async () => {
        let savedCache = await persistentStorage.get('cacheStore');
        if (savedCache) {
            this.store = JSON.parse(savedCache);
        }
    }

    add = async (key, data) => {
        this.store[key] = data;
        await persistentStorage.set({ cacheStore: JSON.stringify(this.store) });
    }

    get = (key) => {
        return key ? this.store[key] : null;
    }

    cleanup = async () => {
        this.store = {};
        await persistentStorage.remove('cacheStore');
    }

    remove = async (key) => await persistentStorage.remove(key);

}

export default new Cache();
