import React from "react";
import _ from 'lodash';
import Entities from '@toolkit/entities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class RefundPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    async getData() {
        this.setState({

        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
            
        })
    }


    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        return (
            <MainLayout name="mainlayout">
                <View landing>
                    <Layout>
                        <Block name='top-bar'>
                            <ArrowBackIosIcon onClick={() => this.props.history.goBack()}/>
                            <Bits.Text name='top-bar-title'>Cancellation & Refund Policy</Bits.Text>
                        </Block> 
                    </Layout>
                    <Layout>
                        <Block>
                            <Bits.Text name='content'>We do not accept returns unless the product is damaged. The company shall accept for the return request only if it feels the request to be genuine and there is a genuine problem faced by a customer</Bits.Text>
                            <Bits.Text name='content'>In rare cases, where you wish to return or exchange full or partial order because of any damage in the product, you may place a request for return on the website. </Bits.Text>
                            <Block>
                                <Bits.Text name='block-header'>Refund Mode</Bits.Text>
                                <Bits.Text name='block-content'>All the refunds will be processed & credited to the original mode of payment via our payment gateway. The refund mode cannot be changed. </Bits.Text>
                            </Block>
                            <Block>
                                <Bits.Text name='block-header'>Refund Processing Duration</Bits.Text>
                                <Bits.Text name='block-content'>The refunds are initiated within 24 hours of receiving a cancellation or a genuine complaint which complies with the refund policy mentioned above. The refund amount is deposited in the customer's original payment mode within 5-7 working days.</Bits.Text>
                            </Block>
                            <Block>
                                <Bits.Text name='block-header'>Cancellations</Bits.Text>
                                <Bits.Text name='block-content'>Orders can only be cancelled before they are shipped.</Bits.Text>
                            </Block>
                        </Block>
                    </Layout>
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'content':{
                master:{
                    fontSize: 15,
                    textAlign:'justify'  
                }
            },
            'block-content':{
                master:{
                    margin:0,
                    fontSize: 15,
                    textAlign:'justify'

                }
            },
            'block-header':{
                master:{
                    color:colorPallete.primary,
                    fontSize:20,
                    margin:'20px 0px 10px',
                }
            },
            'top-bar':{
                master:{
                    display:'flex',
                    marginBottom:30
                }
            },
            'mainlayout':{
                master:{
                    maxWidth:900,
                    margin:'auto',
                    margin:'20px auto',
                    padding: '0px 20px',
                    // margin:'30px 30px'
                }
            },
            'top-bar-title':{
                master:{
                    margin:'0px 5px',
                    fontSize:21,
                }
            },
        })
    }
}

export default Entities.createPlace(RefundPolicy, {
    name: 'RefundPolicy'
});

