import React from "react";
import _ from "lodash";
import withSpinner from "@web-bricks/modules/components/withSpinner";
import Layout from "../surfaces/Layout";
import Block from "../surfaces/Block";
import * as Bits from "../bits";
import { concatNameChain } from "./utils";
import { Route, Switch, withRouter } from "react-router-dom";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import config from "@config";

export default function (Component, options) {
  const hasGetData = _.isFunction(Component.prototype.getData);
  const mainconfig = config.getConfig();
  class Entity extends Component {
    name = options.name;
    namechain = concatNameChain(this.props.namechain, options.name);

    constructor(props) {
      super(props);

      if (!this.state) this.state = {};

      this.state.gotData = !hasGetData;

      if (this.getStylesheet)
        this.getStylesheet = this.getStylesheet.bind(this);

      this.setNextRoute();
    }

    goToView = (viewName) => {
      this.props.history.push(`${viewName}${this.props.location.search}`);
    };

    getSS = () => {
      return _.merge(
        {},
        this.getStylesheet ? this.getStylesheet(interiorDesigner.colorPallete, config.getConfig()) : {},
        this.props.stylesheet || {}
      );
    };

    getBits = () => {
      const self = this;
      return _.mapValues(Bits, function (Bit, index) {
        return function (props) {
          return (
            <Bit
              key={index}
              {...props}
              stylesheet={self.getSS()}
              namechain={`${self.namechain}`}
            />
          );
        };
      });
    };

    getLocationSearchParams() {
      const urlSearchTerm = this.props.location && this.props.location.search;
      if (urlSearchTerm) {
        const a = JSON.parse(
          '{"' +
            decodeURI(urlSearchTerm.substring(1))
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        return a;
      } else {
        return {}
      }
    }

    setNextRoute = () => {
      
      const a = this.getLocationSearchParams()
      this.nextRoute = a.nextRoute;
    };

    onComplete = ({ nextRoute }) => {
      const nextRouteFromFlow = this.nextRoute;
      const route = nextRouteFromFlow || nextRoute;

      if (route) this.props.history.push(route);
    };

    onLoad = () => {
      this.setState({
        gotData: true,
      });
    };

    deps = {
      MainLayout: (props) => {
        return (
          <Layout
            key={this.namechain + props.name}
            namechain={`${this.namechain}`}
            {...props}
            stylesheet={this.getSS()}
          />
        );
      },

      Layout: (props) => {
        return (
          <Layout
            key={this.namechain + props.name}
            namechain={`${this.namechain}`}
            {...props}
            stylesheet={this.getSS()}
          />
        );
      },

      View: Route,
      Switch: (props) => {
        const self = this;
        return (
          <Switch>
            {React.Children.map(props.children, function (Child, index) {
              return React.cloneElement(Child, {
                key: index,
                path: `/${_.chain(self.props.match.path)
                  .split("/")
                  .compact()
                  .join("/")
                  .value()}/${Child.props.landing ? "" : Child.props.name}`,
              });
            })}
          </Switch>
        );
      },

      Block: (props) => {
        return (
          <Block
            key={this.namechain + props.name}
            namechain={`${this.namechain}`}
            {...props}
            stylesheet={this.getSS()}
            _isParentLoading={!this.state._dataFetched}
          />
        );
      },

      Bits: this.getBits(),
      interiorDesigner,
      mainconfig,
    };

    render = () => {
      const conf = config.getConfig();
      let values = {};

      if (this.getLabelValues) values = this.getLabelValues(_, conf);

      return this.renderSurface(values, this.deps, conf);
    };

    componentGetData = this.getData;
    _render = this.render;
  }

  const wrapper = hasGetData ? withSpinner : null;
  const wrappedComp = wrapper ? wrapper(Entity, !options.hasSkeleton) : Entity;
  wrappedComp.displayName = options.name;
  return options.noRouter ? wrappedComp : withRouter(wrappedComp);
}
