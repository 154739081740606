import React, { Component } from 'react';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import Form from "@web-bricks/Shared/form/components/form";
import FormMaker from '@web-bricks/Shared/form/components/formMaker';
import Button from '@web-bricks/Shared/Input/button';


class AddTemplate extends Component {
    constructor(props) {
      super(props)
      this.state = {
         isEmail: false,
         isSMS: false
      }
    }


    toggleModel = () => {
        this.setState({
            open: !this.state.open
        })
    }
    
    toggleScheduleModel = () => {
        this.setState({
            scheduleOpen: !this.state.scheduleOpen
        })
    }

    onSubmit (values) {

    }
    
  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    View,
    Bits
  }) {
    return (
        <MainLayout>
            <View>
                <Layout>
                    <Block>
                    <Form submitButton={{ label: "Send", variant: "container" }}  onSubmit={this.onSubmit} disabled={_.isEmpty(this.users) || (this.selectedUsers) } >
                                <Block name="add-template">
                                    <Button onClick={this.toggleModel} name="template-button" style={{ padding: '30px 0px', margin: '0px auto', display: 'flex', justifyContent: 'flex-end' }}>Add Template</Button>
                                    <Button onClick={this.toggleScheduleModel} name="template-button" style={{ padding: '30px 0px', margin: '0px auto', display: 'flex', justifyContent: 'flex-end' }}>Schedule Templates</Button>
                                </Block> 
                                <FormMaker fields={[
                                    {
                                        type: "HTMLInput",
                                        title: "HTML Message",
                                        props: {
                                            name: 'custom message',
                                            label: 'Custom Message',   
                                        }
                                    },
                                    {
                                        type: "textArea",
                                        title: "Send Notification",
                                        props: {
                                            name: 'message',
                                            label: 'Message',
                                            rows: 4,
                                            placeHolder: "Write Your Message within 160 Characters",
                                            style:{ width:600 }
                                    }
                                },
                            ]} />       
                            </Form> 
                    </Block>
                </Layout>
            </View>
        </MainLayout>
    )
  }
}

export default Entities.createPlace(AddTemplate, {
    name: 'AddTemplate'
});
