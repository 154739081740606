import React from "react";
import _ from "lodash";
import InputLabel from "@material-ui/core/InputLabel";
import { withFormsy } from "formsy-react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import classNames from "classnames";
import customFormHoc from "./customFormHoc";

class SelectInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
      value: props.value || props.defaultValue,
    };
    if (!_.isNil(props.defaultValue)) {
      this.setValue(props.defaultValue);
    }
  }

  focus = () => {
    this.toggleOpen(true);
  };

  toggleOpen = (open) => {
    this.setState({
      open,
    });
  };

  onChange = (event) => {
    this.setValue(event.target.value);
    const selectedOption = _.find(this.props.list, {
      value: event.target.value,
    });
    this.props.onChange(event.target.value, selectedOption);
  };

  setValue = (value) => {
    this.setState({
      value,
    });
    this.props.setValue(value);
    this.props.onInteractionComplete(value);
  };

  render() {
    const id = `${this.props.name}-${_.random()}-radio`;
    return (
      <div
        className={classNames("select__container", this.props.wrapperClass, {
          "has-value": this.state.value,
        })}
      >
        <InputLabel id={`${id}-label`}>{this.props.label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          value={this.state.value}
          onChange={this.onChange}
          className="select__box"
          open={this.state.open}
          inputProps={{
            name: this.props.name,
            id,
          }}
          onOpen={() => this.toggleOpen(true)}
          onClose={() => this.toggleOpen(false)}
          style={this.props.style}
        >
          {_.map(this.props.list, (item, index) => {
            return (
              <MenuItem
                classes={{ selected: "select__option_label_selected" }}
                value={item.value}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  }
}

SelectInput.defaultProps = {
  onChange: _.noop,
  onInteractionComplete: _.noop,
};

const nonFormsyHOC = customFormHoc(SelectInput);

export { nonFormsyHOC as SelectNonFormInput };
export default withFormsy(SelectInput);
