import { withFormsy } from 'formsy-react';
import React from 'react';



class HiddenInput extends React.Component {


    constructor(props) {
        super(props);
    }

    render() {
        return null
    }
}


export default withFormsy(HiddenInput)