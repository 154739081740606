import React from 'react';
import SignIn from '../login/signIn';
import Password from '../login/password';
import withSpinner from '@web-bricks/modules/components/withSpinner';
import { Switch, Route } from 'react-router';
import { withRouter } from 'react-router-dom';
// import tokenManager from '@utils/lib/tokenManager';
import Helper from '@core/helpers';
import AccountCreated from '../login/accountCreated'


class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.signinHelper = new Helper.UserSigninHelper()
        this.shopHelper = new Helper.ShopHelper();
    }


    _render() {
        return (
            <Switch>
                <Route
                    path="/account/signin"
                    render={props => <SignIn {...props} {...this.state} signInHelper={this.signinHelper} />}
                />
                <Route
                    path="/account/created"
                    render={props => <AccountCreated {...props} {...this.state} signInHelper={this.signinHelper} />}
                />
                <Route
                    strict
                    exact
                    path="/account/password"
                    render={props => <Password onComplete={this.onComplete} {...props} {...this.state}  signInHelper={this.signinHelper} shopHelper={this.shopHelper}/>}
                />
            </Switch>
        );
    }
}

export default withRouter(withSpinner(LoginPage));
