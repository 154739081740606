import React,{ Component } from 'react';
import Prediction from './placesPredictions';
import Text from './textField';
// import { getCountries } from "../../lib/enums";
import _ from 'lodash';
import Select from '../../Shared/Input/select';
import GoogleMapPicker from './mapsPicker';
import HiddenInput from '../../Shared/Input/hiddenInput'

class Address extends Component{
    constructor(props){
        super(props);
        const address = this.props.address;
        // this.countriesList = getCountries();
        this.state = {
            address_line_1 : address ? `${address.addressLineOne} `: '',
            address_line_2 : address ? `${address.addressLineTwo}` : '',
            //country : address ?address.country : 'IN',
            state : address ?address.state : '',
            city: address ?address.city : '',
            zip : address ?address.zipCode : '',
            address : address,
            lat :address && address.lat ,
            lng : address && address.lng
        }
    }

    componentWillReceiveProps(nextProps){
        if(!_.isEqual(this.props.address, nextProps.address)) {
            this.setState(nextProps.address);
        }
    }

    onSelect = (address) => {
        this.setState(address);
    }

    render(){
        
        return (
            <div>
                <GoogleMapPicker.input onSelect={this.onSelect} name='Lng' value={{ lat: this.state.lat, lng: this.state.lng }} defaultValue={this.state.address ?{ lat: this.state.lat, lng: this.state.lng } : {}}/>
                <HiddenInput name="lat" value={this.state.lat} />
                <HiddenInput name="lng" value={this.state.lng} />
                <Prediction.formInput required placeholder="Area/street name" label= 'Apartment/Bulding/Street name' name="addressLineTwo" onSelect={this.onSelect} value={this.state.address_line_2} defaultValue ={this.state.address_line_2} onSelect={this.onSelect} />
                {
                    // this.props.useCurrentLocation &&
                    //     <CurrentAddress />
                }
                <Text label="Flat no/House no/Apartment name"  name="addressLineOne"  value={this.state.address_line_1}required />
                <Text className="city1" label='City' name="city" value={this.state.city} required/>
                <Text label='State' name="state" value={this.state.state} required/>
                <Text label='Zipcode' name="zipCode" value={this.state.zip} required/>
                
                {/* <Select
                    name="country"
                    placeholder="Country"
                    value={this.state.country}
                    list={this.countriesList}
                    defaultValue={this.state.country}
                    required
                /> */}
               
            </div>
        )
    }
}

Address.defaultProps = {
    useCurrentLocation: true,
};

export default Address;