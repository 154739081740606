import api from '@api';
import _ from 'lodash';
import firebase from '@firebaseConnect';
import ShopHelper from './ebrShopHelper';
var dot = require('dot-object');

class VendorHelper {

    constructor() {
        this.shopHelper = new ShopHelper()
    }

    addInventories = async (inventories) => {
        const promises = _.map(inventories, inventory => {

            let inventoryItem = dot.object(inventory);
            return api.Inventories.createInventory(inventoryItem);
        });

        try {
            return await Promise.all(promises.map(p => p.catch(e => e)));
        } catch (e) {
            return null;
        }
    } 

    getProperties = async () =>{
        const query = {}
        const properties = await api.Properties.find({ query })
        return properties;
    }

    getUpdatedList = (sections) =>{
        let list = []
        _.map(sections, (item) =>{
            const data ={
                isIncluded : item.isIncluded,
                _id : item._id,
                label : item.label,
                image : item.image
            }
            list.push(data)
        })
        let isIncludedList = _.filter(list, {isIncluded : true})
        let notIncludedList = _.filter(list, {isIncluded : false})
        isIncludedList = _.map(isIncludedList, (item, index) =>{
            item.sortRank = index + 1
            return item
        })
        let updatedList = _.concat(isIncludedList, notIncludedList)
        return updatedList
    }

    updateShopDetailsProperties = async (list) =>{
        _.forEach(list, item =>{
            api.Properties.update(item._id, item)
        })
    }

    updateCategories = async (list) =>{
         _.forEach(list, item =>{
            api.Categories.update(item._id, item)
        })
    }

}

export default VendorHelper