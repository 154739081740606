import React from 'react';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  TimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withFormsy } from 'formsy-react';
import moment from 'moment';
import dateLib from '../../lib/date';


class DateField extends React.Component {

    constructor(props) {
        super(props);

        const defaultValue = props.defaultValue || (props.defaultToToday && new Date());

        this.state = {
          date: defaultValue,
        }

        if (defaultValue) {
          this.onChange(defaultValue);
        }
        
        this.format = this.props.dateTimePicker ? 'dd MMM, hh:mm a' : this.props.timePicker ? 'hh:mm a' : 'dd/MM/yyyy';
        this.momentFormat = this.props.dateTimePicker ? 'dd MMM, hh:mm a' : this.props.timePicker ? 'hh:mm a' : 'DD/MM/YYYY';
    }

    maskDate = (date) => {
      return date ? moment(date, this.momentFormat).toDate() : null;
    }

    getMomentObj = (date) => moment(date, this.momentFormat);
  
    onChange = (date) => {
      if (!date) return;
      const momentObj = this.getMomentObj(date);
        if (this.props.calculateAge && momentObj._isValid) {
            var { age, ageString } = dateLib.getAge(momentObj);
            this.setState({
                ageString,
                age
            })
            this.props.onAgeChange(age.years);
            this.props.setValue(this.maskDate(date));
        } else {
          this.props.setValue(this.maskDate(date))
        }
        this.setState({
          date,
        })
      }
      
      render() {
        const Picker = this.props.dateTimePicker ? DateTimePicker : this.props.timePicker ? TimePicker : KeyboardDatePicker;
        const value = this.props.value;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Picker
                    name={this.props.name}
                    format={this.format}
                    placeholder="DD/MM/YYYY"
                    margin="normal"
                    value={value || null}
                    id="dob"
                    label={(this.props.label || '') + (this.props.dateTimePicker ? '' : ' (dd/mm/yyyy)')}
                    onChange={this.onChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                />
                {
                  this.state.ageString && 
                      <div>
                          <b>Age: </b>
                          {this.state.ageString}
                          <br />
                      </div>
                }
            </MuiPickersUtilsProvider>
        );
    }
}





export default withFormsy(DateField);


