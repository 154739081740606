import React from "react";
import _ from "lodash";
import Entities from "@toolkit/entities";
import Helper from "@core/helpers";
import api from "@api";
import config from "@config/index";
import DisplayBoard from "@core/modules/shared/views/Things/displayBoard";
import Container from "@core/modules/shared/views/Things/container.js";
import BottomNavigation from "@web-bricks/Shared/view/components/bottomNav";
import tokenManager from "@utils/lib/tokenManager";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ArrowRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Visa from "react-pay-icons/lib/Visa";
import Mastercard from "react-pay-icons/lib/Mastercard";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PinterestIcon from "@material-ui/icons/Pinterest";
import MailIcon from "@material-ui/icons/Mail";
import secure from "@core/assests/images/secure.png";
import Badge from "@material-ui/core/Badge";

const Icons = {
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  youtube: YouTubeIcon,
  twitter: TwitterIcon,
};

// const secures = [
//     {
//         image: require('../../../../../../images/secure.png')
//     }
// ]

class FrontWall extends React.Component {
  constructor(props) {
    super(props);
    this.shopHelper = new Helper.ShopHelper();

    const shopDetails = config.getConfig("shop");
    this.socialmedialinks = shopDetails.socialmedialinks;
    this.studiolink = shopDetails.studiolink;
    this.userHelper = new Helper.UserSigninHelper();
    this.inventoryHelper = new Helper.InventoryHelper();
    this.state = {
      shop: {},
      inventories: {},
      shopDetails,
    };
  }

  getData() {
    this.fetchUserLocations();
    //await api.User.migration()
  }

  fetchUserLocations = async () => {
    let userCurrentLocation = {};
    if (tokenManager.user && tokenManager.type !== "staff") {
      const user = await this.userHelper.getUserById(tokenManager.user);
      _.forEach(user.locations, (location) => {
        if (_.isEqual(location.id, user.currentLocation))
          userCurrentLocation = location;
      });
      const zipCode = await this.shopHelper.checkZoneAvailability(
        userCurrentLocation.zipCode
      );
      if (zipCode !== null) {
        this.shopHelper.setZone(zipCode);
      }
    }
  };

  getLabelValues = (_) => {
    return {
      shopContactNo: _.get(this.state.shopDetails, "contact.phone"),
    };
  };

  onGoToShop = () => {
    this.goToView("categories");
  };

  renderSurface(
    values,
    { MainLayout, Layout, Block, Bits, View, Switch, interiorDesigner }
  ) {
    return (
      <Layout>
        <Container>
          <MainLayout name="FrontWall-mainlayout">
            <Layout name="main-container">
              <Layout>
                <Block name="board-layout">
                  <DisplayBoard />
                </Block>
              </Layout>
              {/* <Layout name="shop-door" >
                            <Block>
                                <Bits.Text type="p" name="shop-door-text" onClick={this.onGoToShop}>
                                    SHOP NOW
                                </Bits.Text>
                            </Block>
                            <Block name="shop-door-icon">
                                <ArrowForwardIcon fontSize="large" onClick={this.onGoToShop} />
                            </Block>
                        </Layout> */}
            </Layout>
          </MainLayout>
        </Container>
        <Layout name="footer">
          <Block name="footer-container">
            <Block name="payment-media-align">
              <Block name="payment-icons">
                <Bits.Text name="footer-title">Pay Using</Bits.Text>
                <Block name="payment-align">
                  <Visa style={{ width: 48, height: 48 }} />
                  <Mastercard
                    style={{ width: 48, height: 48, margin: "0px 5px" }}
                  />
                </Block>
              </Block>
              <Block name="payment-icons">
                <Bits.Text name="footer-title">100% Secure Payment</Bits.Text>
                <Block name="secure-image">
                  <img style={{ width: 48, height: 48 }} src={secure} />
                </Block>
              </Block>
              <Layout name="payment-icons">
                <Block>
                  <Bits.Text name="footer-title">Follow us</Bits.Text>
                </Block>
                <Layout name="social-media-link-bar">
                  {Object.keys(this.socialmedialinks).map((key) => {
                    const Icon = Icons[key];
                    return (
                      <Block name="social-media-align">
                        <a
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                          href={this.socialmedialinks[key]}
                          target="_blank"
                        >
                          <Icon style={{ color: "white", fontSize: 22 }} />
                        </a>
                      </Block>
                    );
                  })}
                </Layout>
              </Layout>
              <Block name="whatsapp-button">
                <Bits.IconButton>
                  <Badge color="primary">
                    <a href={`https://wa.me/91${values.shopContactNo}`}>
                      <Bits.Icon name="whats-app">WhatsApp</Bits.Icon>
                    </a>
                  </Badge>
                </Bits.IconButton>
              </Block>
            </Block>
            <Block name="info-links">
              <Block name="link-container">
                <Bits.Text name="footer-links-title">Information</Bits.Text>
                <Bits.Text
                  name="footer-link"
                  variant="body2"
                  onClick={() => this.props.history.push("/aboutUs")}
                >
                  About us
                  <Bits.Text
                    name="footer-link"
                    variant="body2"
                    onClick={() => this.props.history.push("/contactUs")}
                  >
                    Contact us
                  </Bits.Text>
                </Bits.Text>
                <Bits.Text
                  name="footer-link"
                  variant="body2"
                  onClick={() => this.props.history.push("/termsOfService")}
                >
                  Terms of Service
                </Bits.Text>
              </Block>
              <Block name="link-container">
                <Bits.Text name="footer-links-title">Policies</Bits.Text>
                <Bits.Text
                  name="footer-link"
                  variant="body2"
                  onClick={() =>
                    this.props.history.push("/refundCancellationPolicy")
                  }
                >
                  Refund/Cancellation Policy
                </Bits.Text>

                <Bits.Text
                  name="footer-link"
                  variant="body2"
                  onClick={() => this.props.history.push("/privacyPolicy")}
                >
                  Privacy Policy
                </Bits.Text>
                <Bits.Text
                  name="footer-link"
                  variant="body2"
                  onClick={() => this.props.history.push("/shippingPolicy")}
                >
                  Shipping Policy
                </Bits.Text>
              </Block>
            </Block>
          </Block>
          <Layout name="studio-link">
            <Block name="studio-link-align">
              <Bits.Text name="powered-by">Powered by</Bits.Text>
              <Bits.Text name="studio-link-margin">
                <a
                  style={{
                    textDecoration: "none",
                    color: interiorDesigner.colorPallete.teritary,
                    fontSize: 14,
                  }}
                  href="https://ebrdesignstudio.in/"
                  target="_blank"
                >
                  Eyebrow raise design studio
                </a>
              </Bits.Text>
            </Block>
          </Layout>
        </Layout>
      </Layout>
    );
  }

  getStylesheet = (colorPallete) => {
    return {
      "FrontWall-mainlayout": {
        master: {
          backgroundColor: colorPallete.bg,
          height: "100%",
          marginTop: "30px",
        },
      },
      as: {
        master: {
          color: "red",
        },
      },
      "FrontWall.shop-banner": {
        master: {
          //margin:'0px 20px'
        },
      },
      as: {
        master: {
          //color: 'blue'
        },
      },
      "main-container": {
        master: {
          minHeight: "100vh",
          width: "100%",
          margin: "0 auto",
        },
        mobile: {
          width: "100%",
        },
      },
      "shop-banner": {
        master: {},
        mobile: {
          marginLeft: 0,
        },
        transition: "slideUp",
      },
      "coupon-showcase": {
        transition: "slideDown",
      },
      // 'shop-banner.logo': {
      //     master: {
      //         backgroundColor: 'black'
      //     }
      // },

      "social-media-link-bar": {
        master: {
          display: "flex",
          alignItems: "center",
          height: 48,
        },
      },
      "shop-door": {
        master: {
          width: "100%",
          backgroundColor: "#404040",
          position: "fixed",
          bottom: 55,
          height: 60,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        },
        mobile: {
          height: 60,
        },
      },
      "shop-door-nav": {
        master: {
          position: "fixed",
          bottom: 0,
          width: "100%",
        },
      },
      "shop-door-icon": {
        master: {
          color: "white",
          opacity: 0.8,
          margin: "0px 10px",
          cursor: "pointer",
        },
        mobile: {
          paddingTop: 5,
        },
      },
      "shop-door-text": {
        master: {
          color: "white",
          fontWeight: "bold",
          textAlign: "right",
          fontSize: 20,
          cursor: "pointer",
        },
        mobile: {
          fontSize: 18,
        },
      },
      "fab.element": {
        master: {},
        mobile: {
          color: "white",
          right: 15,
        },
      },
      "logo-image": {
        master: {},
        mobile: {
          width: 65,
          height: 65,
          margin: "6px 0px 6px -10px",
        },
      },
      title: {
        master: {
          color: "#404040",
          fontWeight: "bold",
          marginTop: 3,
          marginBottom: 3,
        },
        mobile: {
          color: "#404040",
        },
      },
      description: {
        master: {
          color: "#404040",
          margin: 0,
        },
        mobile: {
          color: "#404040",
        },
      },
      "shop-banner-title": {
        master: {
          margin: "0px 5px",
        },
        // master:{
        //     fontSize:18,
        //     color:'#404040'
        // },
        // mobile:{
        //     fontSize:18,
        //     color:'#404040'
        // }
      },
      "FrontWall.displayboard.info.productname": {},
      "FrontWall.board-layout": {
        master: {
          // overflow: 'auto'
        },
        mobile: {},
      },
      "info-links": {
        master: {
          display: "flex",
        },
        mobile: {
          justifyContent: "center",
        },
      },
      "footer-left": {
        master: {
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
        },
      },
      "footer-right": {
        master: {
          paddingLeft: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
        },
      },
      "footer-link": {
        master: {
          fontSize: 12,
          color: colorPallete.bg,
          cursor: "pointer",
          margin: 0,
          lineHeight: 2,
        },
      },
      "link-container": {
        master: {
          marginRight: 50,
        },
        mobile: {
          margin: 40,
        },
      },
      "footer-text": {
        master: {
          color: colorPallete.subtext,
          margin: 0,
          fontSize: 12,
        },
      },
      "footer-content": {
        master: {
          display: "flex",
          padding: "20px 0px",
          justifyContent: "space-around",
          flexDirection: "column",
        },
        mobile: {
          alignItems: "center",
        },
      },
      footer: {
        master: {
          marginTop: 80,
          backgroundColor: colorPallete.primary,
          paddingTop: 40,
          // paddingBottom: 80,
          width: "100%",
        },
        mobile: {
          marginTop: 20,
          padding: "20px 20px 120px",
          boxSizing: "border-box",
          flexDirection: "row",
        },
      },
      "footer-container": {
        master: {
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          margin: "0 auto",
          maxWidth: 1250,
        },
        mobile: {
          width: "100%",
          flexDirection: "column",
        },
      },
      "payment-icons": {
        master: {
          padding: "0px 20px",
        },
      },
      "payment-align": {
        master: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
      "payment-text": {
        master: {
          fontSize: 14,
          textAlign: "center",
          color: colorPallete.white,
          // marginTop:10
        },
      },
      "footer-title": {
        master: {
          fontSize: 11,
          color: colorPallete.bg,
          textTransform: "uppercase",
          fontWeight: 600,
          letterSpacing: 1,
        },
        mobile: {
          marginTop: 15,
          textAlign: "center",
        },
      },
      "footer-links-title": {
        master: {
          fontSize: 11,
          color: colorPallete.bg,
          textTransform: "uppercase",
          fontWeight: 600,
          letterSpacing: 1,
        },
        mobile: {
          marginTop: 15,
        },
      },
      "social-media-icons": {
        master: {
          display: "flex",
        },
      },
      "social-media-align": {
        master: {
          opacity: 0.9,
        },
        mobile: {
          textAlign: "center",
        },
      },
      // 'social-media-text':{
      //     master:{
      //         color: colorPallete.subtext
      //     }
      // },
      "payment-media-align": {
        master: {
          marginRight: 90,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        },
        mobile: {
          marginRight: 0,
          flexDirection: "column",
          alignItems: "center",
        },
      },
      // 'return-policy':{
      //     master:{
      //         color:colorPallete.white,
      //         margin:0,
      //         marginLeft:20
      //     }
      // },
      "follow-us": {
        master: {
          textAlign: "center",
          color: colorPallete.white,
          // marginBottom:25
        },
      },
      ".MuiSvgIcon-root": {
        master: {
          marginTop: "6px",
        },
      },
      "secure-payment": {
        master: {
          padding: 10,
        },
      },
      "secure-image": {
        master: {
          width: 55,
          height: 50,
          margin: "0 auto",
        },
      },
      "whatsapp-button": {
        master: {
          backgroundColor: colorPallete.primary,
          position: "fixed",
          bottom: 35,
          right: 35,
          transition: "0.4s",
          borderRadius: "50%",
          width: 48,
          height: 48,
        },
        mobile: {
          bottom: 35,
          right: 20,
        },
      },
      "whats-app": {
        master: {
          color: colorPallete.white,
        },
      },
      "studio-link": {
        master: {
          backgroundColor: colorPallete.bg,
          // maxWidth: 1200,
          width: "100%",
          margin: "auto",
          marginTop: 40,
          padding: "0px 10px",
        },
      },
      "studio-link-align": {
        master: {
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        },
      },
      "powered-by": {
        master: {
          fontSize: 14,
          marginTop: 0,
          marginBottom: 0,
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
      "studio-link-margin": {
        master: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    };
  };
}

export default Entities.createPlace(FrontWall, {
  name: "FrontWall",
});
