import React from "react";
import _ from "lodash";
import CartItem from "@core/modules/Cart/views/Things/cartItem";
import Form from "@web-bricks/Shared/form/components/form";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import Button from "@web-bricks/Shared/Input/button";
import Helper from "@core/helpers";
import tokenManager from "@utils/lib/tokenManager";
import DialogBox from "@core/modules/shared/views/Things/dialogBox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { RadioGroupForm } from "@web-bricks/Shared/Input/radio";
import Entities from "@toolkit/entities";
import {
  RemoveCircleOutlineOutlined,
  ShoppingBasketRounded,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import ChooseZones from "@core/modules/Delivery/views/Places/chooseZones";
import persistentStorage from "@utils/lib/localStorage";
import { CheckboxItem } from "@web-bricks/Shared/Input/checkbox";
import Container from "@core/modules/shared/views/Things/container.js";

class Cart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            cart: {},
            showPaymentMethods: false,
            showDailogFlag: false,
            user: {},
            showZones: false,
        }

        this.couponHelper = new Helper.CouponHelper()
        this.orderHelper = new Helper.OrderHelper()
        this.shopHelper = new Helper.ShopHelper()
        this.userSigninHelper = new Helper.UserSigninHelper()
        this.transactionHelper = new Helper.TransactionHelper()
        this.userHelper = new Helper.UserHelper()
    }

    async getData() {
        const cart = await Helper.CartHelper.getCart()
        let locationList = []
        let user = {}
        let location = {}
        if (tokenManager.user) {
            user = await this.userHelper.getUserById(tokenManager.user)
            locationList = await this.userHelper.getLocationFormate(user)
            Helper.CartHelper.addListener((cart) => {
            }, 'cart');
            location = await Helper.CartHelper.getUserLocation(tokenManager.user)
            await this.userHelper.userCurrentZipcode(location)
            Helper.CartHelper.updateDeliveryLocation(location)
        }
        let deliveryCharge = await this.shopHelper.getDeliveryCharges(location.zipCode)
        this.setState({
            cart: cart,
            user: user,
            location: location,
            locationList: locationList,
            deliveryCharge: deliveryCharge,
            cashOnDelivery: cart.paymentMode === 'COD' ? true : false
        })
    }
  

  onChange = async (count, cartItem) => {
    await Helper.CartHelper.updateCart(count, cartItem);
    const cart = await Helper.CartHelper.getCart();
    this.setState({
      cart: cart,
    });
  };

  onZoneSubmit = async (values) => {
    this.shopHelper.setZone(values);
    //let location = await Helper.CartHelper.getUserLocation(tokenManager.user)
    let deliveryCharge = await this.shopHelper.getDeliveryCharges(
      this.state.location
    );
    this.setState({
      deliveryCharge: deliveryCharge,
    });
  };

  closeZoneDailog = () => {
    this.setState({
      showZones: false,
    });
  };

  onClose = () => {
    this.setState({
      showPaymentMethods: false,
    });
  };

  onClick = () =>
    this.setState({
      showPaymentMethods: true,
    });

  onCreateOrder = async () => {
    const cart = await Helper.CartHelper.getCart();
    if (cart.orderType !== "takeaway") {
      cart.sumAmount = cart.sumAmount;
    }
    // const bill = this.getBillAmounts();
    // const cartWithBill = { ...cart, ...bill };
    // if (this.state.cashOnDelivery) {
    //   cartWithBill.paymentMode = "COD";
    //   await this.orderHelper.placeOrder(cartWithBill, null);
    //   Helper.CartHelper.clearCart();
    //   this.goToView(`AllOrders`);
    // } else {
    //   cartWithBill.paymentMode = "RP";
    //   await this.transactionHelper.createOrderForTransaction(
    //     cartWithBill,
    //     this.state.user.email,
    //     () => {
    //       Helper.CartHelper.clearCart();
    //       this.goToView(`AllOrders`);
    //     },
    //     () => {
    //       this.props.history.push(`/cart`);
    //     }
        const bill = this.getBillAmounts()
        const cartWithBill = { ...cart, ...bill };
        if (this.state.cashOnDelivery) {
            //cartWithBill.paymentMode = 'COD'
            let order = await this.orderHelper.placeOrder(cartWithBill, null)
            Helper.CartHelper.clearCart()
            this.goToView(`orderSummary/${order._id}`)
        } else {
            //cartWithBill.paymentMode = 'RP'
            await this.transactionHelper.createOrderForTransaction(cartWithBill, this.state.user.email,
                (order) => {
                    Helper.CartHelper.clearCart()
                    this.goToView(`orderSummary.${order._id}`)
                },
                () => {
                    this.props.history.push(`/cart`)
                })
        }
    }

    getAddedAmount = (amount) => {
        return amount + parseInt(this.state.deliveryCharge)
    }

    onDailogOpen = () => {
        this.setState({
            showDailogFlag: true,
        })
    }

    onDailogClose = () => {
        this.setState({
            showDailogFlag: false
        })
    }

  getAddedAmount = (amount) => {
    return amount + parseInt(this.state.deliveryCharge);
  };

  onDailogOpen = () => {
    this.setState({
      showDailogFlag: true,
    });
  };

  onDailogClose = () => {
    this.setState({
      showDailogFlag: false,
    });
  };

  updateDeliveryCharge = async (location) => {
    const deliveryCharge = await this.shopHelper.getDeliveryCharges(location);
    this.setState({ deliveryCharge });
  };

  onAddressSubmit = async (values) => {
    const location = _.find(this.state.user.locations, {
      id: values.target.value,
    });
    this.userHelper.userCurrentZipcode(location);
    this.setState({
      showDailogFlag: false,
      location: location,
      // cart : cart
    });
    Helper.CartHelper.updateDeliveryLocation(location);
    Helper.CartHelper.updateOrderType("delivery");
    this.updateDeliveryCharge(location.zipCode);
  };

  onAddAddress = () => {
    this.goToView("/addAddress?nextRoute=/cart");
  };

  // setTakeAway = () => {
  //   Helper.CartHelper.updateOrderType("takeaway");
  //   this.setState({
  //     showDailogFlag: false,
  //     deliveryCharge: 0,
  //   });
  // };

  // onApplyCoupon = async (values) => {
  //   this.setState({
  //     couponApplyFailed: false,
  //     couponFailedReason: null,
  //   });

  //   const cart = await Helper.CartHelper.getCart();
  //   const bill = {
  //     rawTotalAmount: this.state.cart.sumAmount,
  //     deliveryCharge: this.state.deliveryCharge,
  //     orderType: this.state.cart.orderType,
  //     items: _.map(cart.cartItems, (item) => {
  //       return {
  //         quantity: item.count,
  //         inventoryId: item.cartItem._id,
  //         totalAmount: item.totalPrice,
  //         categories: _.map(item.cartItem.categories, "categoryId"),
  //       };
  //     }),
  //   };

  //   const response = await this.couponHelper.redeem(values.code, bill);
  //   if (response.applied) {
  //     this.setState({
  //       appliedCoupon: {
  //         code: values.code,
  //         ...response,
  //       },
  //     });
  //   } else {
  //     this.setState({
  //       couponApplyFailed: true,
  //       couponFailedReason: response.reason,
  //     });
  //   }
  //   values.onSuccess();
  // };

  // removeCoupon = () => {
  //   this.setState({
  //     appliedCoupon: null,
  //   });
  // };

  // onCOD = (e) => {
  //   this.setState({
  //     cashOnDelivery: e,
  //   });
  // };

  // getBillAmounts = () => {
  //   const totalAmount =
  //     this.state.cart.orderType !== "takeaway"
  //       ? this.getAddedAmount(this.state.cart.sumAmount)
  //       : this.state.cart.sumAmount;
  //   let billableAmount = totalAmount;
  //   if (this.state.appliedCoupon) {
  //     const discountedPrice = totalAmount - this.state.appliedCoupon.discount;
  //     billableAmount = discountedPrice || billableAmount;
  //   }

    setTakeAway = () => {
        Helper.CartHelper.updateOrderType('takeaway')
        this.setState({
            showDailogFlag : false,
            deliveryCharge : 0
        })
    }

    onApplyCoupon = async values => {

        this.setState({
            couponApplyFailed: false,
            couponFailedReason: null
        })

        const cart = await Helper.CartHelper.getCart();
        const bill = {
            rawTotalAmount: this.state.cart.sumAmount,
            deliveryCharge: this.state.deliveryCharge,
            orderType: this.state.cart.orderType,
            items: _.map(cart.cartItems, item => {
                return ({
                    quantity: item.count,
                    inventoryId: item.cartItem._id,
                    totalAmount: item.totalPrice,
                    categories: _.map(item.cartItem.categories, 'categoryId')
                })
            })
        }

        const response = await this.couponHelper.redeem(values.code, bill);
        if (response.applied) {
            this.setState({
                appliedCoupon: {
                    code: values.code,
                    ...response,
                }
            })
        } else {
            this.setState({
                couponApplyFailed: true,
                couponFailedReason: response.reason
            })
        }
        values.onSuccess();
    }

    removeCoupon = () => {
        this.setState({
            appliedCoupon: null
        })
    }

    onCOD = (e) => {
        Helper.CartHelper.updatePaymentMode(e)
        this.setState({
            cashOnDelivery: e ? e : false
        })
    }

    getBillAmounts = () => {
        const totalAmount = this.state.cart.orderType !== 'takeaway' ? this.getAddedAmount(this.state.cart.sumAmount) : this.state.cart.sumAmount;
        let billableAmount = totalAmount;
        if (this.state.appliedCoupon) {
            const discountedPrice = totalAmount - this.state.appliedCoupon.discount;
            billableAmount = discountedPrice || billableAmount;
        }

        return ({
            totalAmount,
            billableAmount,
            deliveryCharge: this.state.deliveryCharge,
            discount: _.get(this.state.appliedCoupon, 'discount', 0),
            couponedDiscount: _.get(this.state.appliedCoupon, 'discount', 0),
            couponId: _.get(this.state.appliedCoupon, 'couponId', null),
            discountableItems: _.get(this.state.appliedCoupon, 'discountableItems', []),
        })
    }

    getLabelValues = (_, transformers) => {
        const billAmounts = this.getBillAmounts();
        return ({
            appliedCoupon: this.state.appliedCoupon,
            totalAmount: billAmounts.totalAmount,
            billableAmount: billAmounts.billableAmount,
            isCouponFailed: this.state.couponApplyFailed,
            couponFailedReason: this.state.couponFailedReason,
        })
    }

    signIn = () => {
        this.onComplete({
            nextRoute: '/account/signin?nextRoute=/cart',
        })
    }


    renderSelectAddress = () => {
        return (
            <List>
                <ListItem button onClick={this.setTakeAway}>
                    <ListItemText primary="Take away" />
                </ListItem>
                <Divider />
                <ListItem button onClick={this.onAddAddress}>
                    <ListItemText primary="Add Address" />
                </ListItem>
                <Divider />
                <ListItem>
                    <RadioGroupForm
                        name="deliveryAddress"
                        list={this.state.locationList}
                        onChange={this.onAddressSubmit}
                        required
                    />
                </ListItem>
            </List>
        )
    }

  renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
    return (
      <MainLayout name="cart-mainlayout">
        <View>
          <Layout name="cart-list-view">
            {_.size(this.state.cart.cartItems) > 0 ? (
              <Layout>
                <Container page={{ title: "My Basket" }} actions>
                  <Layout>
                    <Block name="arrow-overlay">
                      <Bits.Icon
                        name="close-icon"
                        onClick={() => this.props.history.goBack()}
                      >
                        Close
                      </Bits.Icon>
                    </Block>
                    <Block name="cart-layout">
                      <Layout name="item-grid">
                        {_.map(this.state.cart.cartItems, (cartItem, index) => {
                          return (
                            <Block>
                              <CartItem
                                key={index}
                                item={cartItem}
                                onChange={(count) =>
                                  this.onChange(count, cartItem.cartItem)
                                }
                                cartHelper={Helper.CartHelper}
                              />
                            </Block>
                          );
                        })}
                      </Layout>
                      <Layout>
                        <Layout name="Billing-block">
                          <Layout>
                            <Block>
                              {values.appliedCoupon ? (
                                <Bits.Text name="coupon-header-green">
                                  Coupon Applied!
                                </Bits.Text>
                              ) : (
                                <Bits.Text name="cart-headers">
                                  Apply Coupon
                                </Bits.Text>
                              )}
                            </Block>
                            {values.appliedCoupon ? (
                              <Layout name="applied-coupon">
                                <Layout name="applied-coupon-row">
                                  <Block>
                                    <Bits.Text name="applied-coupon-code">
                                      {values.appliedCoupon.code}
                                    </Bits.Text>
                                  </Block>
                                  <Block>
                                    <IconButton
                                      onClick={this.removeCoupon}
                                      size="small"
                                    >
                                      <RemoveCircleOutlineOutlined
                                        fontSize={"12"}
                                        name=""
                                      />
                                    </IconButton>
                                  </Block>
                                </Layout>
                                <Block>
                                  <Bits.Currency
                                    negative
                                    name="applied-coupon-discount"
                                    value={values.appliedCoupon.discount}
                                  ></Bits.Currency>
                                </Block>
                              </Layout>
                            ) : (
                              <Block>
                                <Form
                                  buttonPosition="row"
                                  onSubmit={this.onApplyCoupon}
                                  buttonWrapperClassName="coupon-btn"
                                  submitButton={{
                                    size: "small",
                                    label: "Apply",
                                    variant: "contained",
                                    color: "primary",
                                    style: {
                                      marginTop: 7,
                                    },
                                  }}
                                  formMaker={{
                                    list: [
                                      {
                                        type: "textField",
                                        style: {},
                                        props: {
                                          name: "code",
                                          label: "Coupon Code",
                                          wrapperClass: "coupon-field",
                                          error: values.isCouponFailed,
                                          helperText: values.couponFailedReason,
                                        },
                                      },
                                    ],
                                  }}
                                />
                              </Block>
                            )}
                          </Layout>
                          <Layout name="payment-mode">
                            <Block>
                              <Bits.Text name="cart-headers">
                                Payment Mode
                              </Bits.Text>
                            </Block>
                            <CheckboxItem
                              onChange={this.onCOD}
                              color="primary"
                              label="Cash on delivery"
                              defaultValue = {this.state.cashOnDelivery}
                            />
                          </Layout>
                          <Layout>
                            <Block>
                              <Bits.Text name="cart-headers">Charges</Bits.Text>
                            </Block>
                            <Block name="flex-row-space-btw">
                              <Bits.Text name="delivery-charge">
                                Delivery Charge
                              </Bits.Text>
                              <Bits.Currency
                                name="charge-price"
                                value={
                                  this.state.cart.orderType !== "takeaway"
                                    ? this.state.deliveryCharge
                                    : 0
                                }
                              />
                            </Block>
                          </Layout>
                          <Bits.Divider name="divider" />
                          <Layout>
                            <Block name="billable-price-row">
                              <Bits.Text name="billable-charge">
                                Total
                              </Bits.Text>
                              <Bits.Currency
                                name="billable-price"
                                value={values.billableAmount}
                              />
                            </Block>
                          </Layout>
                        </Layout>
                      </Layout>
                    </Block>
                  </Layout>
                </Container>
                <Layout name="bottom-bar">
                  <Block name="bottom-tab">
                    <Block name="button">
                      {tokenManager.user &&
                        this.state.cart.orderType !== "takeaway" &&
                        _.isNull(
                          persistentStorage.get("selectedDeliveryZone")
                        ) && (
                          <Block>
                            <Bits.Text name="payment-mode-text">
                              Services unavailable for opted delivery Zone.Try
                              using takeaway or other address to continue
                              shopping
                            </Bits.Text>
                          </Block>
                        )}
                      <Block name="flex-row-space-btw">
                        <Block>
                          {tokenManager.user ? (
                            <Block>
                              <Bits.Text name="location-title">
                                Delivery Address
                              </Bits.Text>
                              <Bits.Text
                                name="location-details"
                                onClick={this.onDailogOpen}
                              >
                                {this.state.cart.orderType !== "takeaway"
                                  ? !_.isEmpty(this.state.location)
                                    ? `Location - ${this.state.location.label}`
                                    : "Location"
                                  : "Take Away"}
                              </Bits.Text>
                            </Block>
                          ) : (
                            <Bits.Text
                              name="location-details"
                              onClick={this.signIn}
                            >
                              Sign in to continue
                            </Bits.Text>
                          )}
                        </Block>
                        <Button
                          fullWidth
                          color="secondary"
                          variant="contained"
                          style={{
                            width: "170px",
                            height: "100%",
                            borderRadius: 4,
                            color: "#33333",
                            fontSize: 18,
                            fontWeight: 500,
                            textTransform: "capitalize",
                          }}
                          onClick={() => this.onCreateOrder(this.state.cart)}
                          disabled={
                            tokenManager.user &&
                            (this.state.cart.orderType === "takeaway" ||
                              !_.isNull(
                                persistentStorage.get("selectedDeliveryZone")
                              ))
                              ? false
                              : true
                          }
                        >
                          <p
                            style={{
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            Order Now
                          </p>
                        </Button>
                      </Block>
                    </Block>
                  </Block>
                </Layout>
                {this.state.showDailogFlag && (
                  <DialogBox
                    handleClickOpen={this.onDailogOpen}
                    handleClose={this.onDailogClose}
                    open={this.state.showDailogFlag}
                    dialogTitle="Select Address"
                    renderItem={this.renderSelectAddress}
                  />
                )}
              </Layout>
            ) : (
              <Layout name="empty-cart-layout">
                <Block name="empty-cart-icon" />
                <Block>
                  <Bits.Text name="empty-cart-text">
                    Empty basket rattles loudly
                  </Bits.Text>
                  <Bits.Text name="empty-cart-caption">
                    Looks like you haven't added anything into your basket
                  </Bits.Text>
                </Block>
                <Block name="btn-block">
                  <Bits.Text
                    name="products-btn"
                    onClick={() => this.goToView("categories")}
                  >
                    Back to Menu
                  </Bits.Text>
                </Block>
              </Layout>
            )}
            {this.state.showZones && (
              <Layout>
                <Block>
                  <Bits.Text name="empty-cart-text">
                    Empty basket rattles loudly
                  </Bits.Text>
                  <Bits.Text name="empty-cart-caption">
                    Looks like you haven't added anything into your basket
                  </Bits.Text>
                </Block>
                <Block name="btn-block">
                  <Bits.Text
                    name="products-btn"
                    onClick={() => this.goToView("/categories")}
                  >
                    Back to Menu
                  </Bits.Text>
                </Block>
              </Layout>
            )}
          </Layout>
        </View>
      </MainLayout>
    );
  }
  getStylesheet = (colorPallete) => {
    return {
      "bottom-bar": {
        transition: "children-slideDown",
      },
      divider: {
        master: {
          marginTop: 5,
        },
      },
      "applied-coupon": {
        master: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
      "arrow-overlay": {
        master: {
          position: "absolute",
          right: 50,
          top: 40,
          zIndex: 2,
        },
        mobile: {
          right: 10,
          top: 10,
        },
      },
      "applied-coupon-code": {
        master: {
          color: colorPallete.successGreen,
          marginRight: 5,
        },
      },
      "item-grid": {
        transition: "children-slideDown",
        master: {
          margin: "0px 50px",
        },
        mobile: {
          margin: 0,
        },
      },
      "applied-coupon-discount": {
        master: {
          color: colorPallete.successGreen,
        },
      },
      "applied-coupon-row": {
        master: {
          display: "flex",
          alignItems: "center",
        },
      },
      "payment-mode": {
        master: {
          margin: "20px 0px",
        },
        mobile: {
          margin: "10px 0px",
        },
      },
      "payment-mode-text": {
        master: {
          marginTop: 0,
        },
      },
      "cart-mainlayout": {
        master: {
          // height: '100vh',
          margin: "50px 0px",
        },
        mobile: {
          margin: "13px 0px",
        },
      },
      "cart-header-layout": {
        master: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "40px 30px",
        },
        mobile: {
          zIndex: 1,
          padding: "15px 15px 20px 15px",
        },
      },
      "cart-header-left": {
        transition: "children-slideDown",
      },
      "cart-list-view": {
        master: {
          //maxWidth: '80%',
          margin: "0 auto",
        },
        mobile: {
          maxWidth: "100%",
          margin: "0px auto",
          //padding: 20
        },
      },
      "empty-cart-layout": {
        master: {
          width: "100%",
          position: "absolute",
          top: "40%",
          left: 0,
          transform: "translateY(-50%)",
          textAlign: "center",
          zIndex: -1,
        },
      },
      "empty-cart-icon": {
        master: {
          backgroundImage: `url(${
            require("@core/assests/images/emptyCart.png").default
          }`,
          color: colorPallete.primary,
          width: 350,
          height: 350,
          transform: "translate(-50%, -50%)",
          left: "50%",
          top: "50%",
          position: "absolute",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        },
      },
      "empty-cart-text": {
        master: {
          margin: 0,
          marginTop: 490,
          fontSize: 22,
          lineHeight: "24px",
          fontWeight: 600,
          letterSpacing: "0.87px",
          color: colorPallete.primary,
          opacity: 0.9,
        },
      },
      "empty-cart-caption": {
        master: {
          margin: 5,
          fontSize: 15,
          color: colorPallete.subtext,
        },
      },
      "btn-block": {
        master: {
          margin: "30px 0px",
        },
      },
      "products-btn": {
        master: {
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          letterSpacing: 0.6,
          background: colorPallete.primary,
          borderRadius: 4,
          fontSize: 16,
          width: 250,
          fontWeight: 600,
          border: `1px solid ${colorPallete.primary}`,
          color: colorPallete.bg,
          margin: "0 auto",
          padding: "10px 40px",
        },
      },
      "Billing-block": {
        master: {
          minWidth: 300,
          margin: "0px 50px",
        },
        mobile: {
          minWidth: "unset",
          margin: 0,
        },
        transition: "children-slideRight",
      },
      "cart-close": {
        master: {
          textAlign: "right",
          color: colorPallete.primary,
        },
      },
      button: {
        master: {
          padding: "15px",
        },
      },
      "location-title": {
        master: {
          margin: 0,
          fontSize: 15,
          color: colorPallete.yellow,
        },
      },
      "location-details": {
        master: {
          textDecoration: "underline",
          fontWeight: "600",
          margin: "5px 0px",
          letterSpacing: 0.6,
          fontSize: 24,
          cursor: "pointer",
        },
        mobile: {
          fontSize: 18,
        },
      },
      "cart-header-text": {
        master: {
          margin: "0px 0px 0px 10px",
          fontSize: 24,
          fontWeight: 400,
        },
        mobile: {
          margin: "0px 0px 0px 10px",
          fontSize: 20,
        },
      },
      "delivery-charge": {
        master: {
          color: colorPallete.black,
          fontSize: 20,
        },
      },
      "billable-charge": {
        master: {
          color: colorPallete.primary,
          fontSize: 24,
        },
        mobile: {
          fontSize: 18,
        },
      },
      "billable-price-row": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        },
      },
      "billable-price": {
        master: {
          margin: "5px 0px",
          color: colorPallete.primary,
          fontSize: 24,
          fontWeight: "bold",
        },
        mobile: {
          fontSize: 20,
          fontWeight: 600,
        },
      },
      "charge-price": {
        master: {
          margin: "5px 0px",
          color: colorPallete.primary,
          fontSize: 20,
        },
      },
      "cart-headers": {
        master: {
          fontSize: 11,
          color: colorPallete.darkGrey,
          margin: 0,
          fontWeight: 500,
          letterSpacing: 0.57,
          paddingTop: 15,
          textTransform: "uppercase",
        },
        mobile: {
          fontSize: 10,
        },
      },
      "coupon-header-green": {
        master: {
          fontSize: 18,
          color: colorPallete.successGreen,
          margin: 0,
          fontWeight: 600,
          letterSpacing: 0.87,
          paddingTop: 15,
        },
        mobile: {},
      },
      "charges-header": {
        master: {
          fontSize: 17,
          color: colorPallete.black,
          margin: 0,
          fontWeight: 600,
          letterSpacing: 0.87,
          paddingBottom: 10,
        },
        mobile: {
          margin: "20px 0px 0px 0px",
          paddingBottom: 0,
        },
      },
      "cart-layout": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 30px 100px 30px",
        },
        mobile: {
          flexDirection: "column",
          padding: "0px 20px 200px 20px",
        },
      },
      "bottom-tab": {
        master: {
          position: "fixed",
          bottom: 0,
          backgroundColor: interiorDesigner.colorPallete.primary,
          width: "100%",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          color: colorPallete.yellow,
          height: "auto",
        },
      },
      button: {
        master: {
          maxWidth: "80%",
          margin: "25px auto",
        },
        mobile: {
          maxWidth: "100%",
          margin: "10px auto",
          padding: "0px 20px",
        },
      },
      "flex-row-space-btw": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
      "cart-header": {
        master: {
          display: "flex",
          alignItems: "center",
          color: colorPallete.primary,
        },
      },
      "top-bar": {
        master: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
      "logo-image": {
        master: {
          width: 65,
          height: 65,
          margin: "6px 0px 6px 10px",
        },
      },
      "profile-piece": {
        master: {
          margin: 20,
        },
      },
    };
  };
}

export default Entities.createPlace(Cart, {
  name: "Cart",
});
