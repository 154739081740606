import _ from 'lodash';

const placeMapping = {
    address_line_1: {
      addressComponent: ['flat_no'],
      addressFormat: 'long_name'
    },
    address_line_2: {
        addressComponent: ['street_number', 'route', 'neighborhood','sublocality'],
        addressFormat: 'long_name',
    },
    city: {
      addressComponent: ['locality'],
      addressFormat: 'long_name'
    },
    state: {
      addressComponent: ['administrative_area_level_1'],
      addressFormat: 'short_name'
    },
    zip: {
      addressComponent: ['postal_code'],
      addressFormat: 'long_name'
    },
    country: {
      addressComponent: ['country'],
      addressFormat: 'short_name'
    }
};

let maps, infoWindow;

// Retrives places with the places input
export function places(inputValue, cb){
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({ input: inputValue }, cb);
}

export function getPlaces(placeId, cb){
    const request = {
        placeId : placeId,
    }
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails(request, (response) => {
        //response = getLatlng(response)
        const formattedAddress = formatAddress(response);
        cb(formattedAddress);
    });
}

// function getLatlng(address) {
//     var geocoder = new window.google.maps.Geocoder();
//     //var address = document.getElementById('textboxid').value;
  
//     geocoder.geocode({
//       'address': address
//     }, function(results, status) {
  
//       if (status === window.google.maps.GeocoderStatus.OK) {
//         var latitude = results[0].geometry.location.lat();
//         var longitude = results[0].geometry.location.lng();
//       }
//     });
//   }

function formatAddress(response){
    const result = {};
    _.each(placeMapping, (component, name) => {
        response.address_components.forEach((address) => {
            if (!_.isEmpty(_.intersection(address.types, component.addressComponent))) {
                if (result[name]) {
                    result[name] = `${(result[name])}, ${address[component.addressFormat]}`;
                } else {
                    result[name] = address[component.addressFormat];
                }
            }
        })
    })
    return result;
}



export function getAddressByCurrentLocation(onSuccess,onError){
    const geocoder = new window.google.maps.Geocoder;
    const geolocation = navigator.geolocation;
    geolocation.getCurrentPosition((position) => {
        const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
        getAddress(geocoder,latlng,onSuccess,onError);
    }, onError);
}

export function getAddressByLatLng(lat, lng, onSuccess){
    const geocoder = new window.google.maps.Geocoder;
        geocoder.geocode({'location': {lat: lat, lng : lng}}, function(results, status) {
            if(status === 'OK'){
                if(results[0]){
                    const formatedAddress = formatAddress(results[0]);
                    formatedAddress.lat = lat
                    formatedAddress.lng = lng
                    onSuccess(formatedAddress);
                }
            }
        })  
    }

function getAddress(geocoder, latlng, onSuccess, onError){
        geocoder.geocode({'location': latlng}, function(results, status) {
            if(status === 'OK'){
                if(results[0]){
                    let formatedAddress = formatAddress(results[0]);
                    formatedAddress.lat = latlng.lat
                    formatedAddress.lng = latlng.lng
                    onSuccess(formatedAddress, latlng, results[0].formatted_address);
                }
            }
    }, onError);
}


// This function is called when the user clicks the UI button requesting
// a geocode of a place ID.
function geocodePlaceId(geocoder, map, infowindow) {
    var placeId = document.getElementById('place-id').value;
    geocoder.geocode({'placeId': placeId}, function(results, status) {
        if (status === 'OK') {
            if (results[0]) {
                map.setZoom(11);
                map.setCenter(results[0].geometry.location);
                var marker = new window.google.maps.Marker({
                map: map,
                position: results[0].geometry.location
                });
                infowindow.setContent(results[0].formatted_address);
                infowindow.open(map, marker);
            } else {
                window.alert('No results found');
            }
        } else {
            window.alert('Geocoder failed due to: ' + status);
        }
    });


    function initMap() {
    maps = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 6,
    });
    infoWindow = new window.google.maps.InfoWindow();
    const locationButton = document.createElement("button");
    locationButton.textContent = "Pan to Current Location";
    locationButton.classList.add("custom-map-control-button");
    maps.controls[window.google.maps.ControlPosition.TOP_CENTER].push(locationButton);
    locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
            const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
            infoWindow.setPosition(pos);
            infoWindow.setContent("Location found.");
            infoWindow.open(maps);
            maps.setCenter(pos);
            },
            () => {
            handleLocationError(true, infoWindow, maps.getCenter());
            }
        );
        } else {
        // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, maps.getCenter());
        }
    });
    }

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
        browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(map);
    }
}


export default { getAddressByCurrentLocation, places, getPlaces, geocodePlaceId };
