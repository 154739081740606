'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import customFormHoc from './customFormHoc';
import { withFormsy } from 'formsy-react';

class SketchExample extends React.Component {

    constructor(props){
        super(props);
        this.setValue(this.props.defaultValue);
    }

  state = {
    displayColorPicker: false,
    color: this.props.defaultValue
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setValue(color.hex)
    // this.props.onChange(color)
    this.setState({ color: color.hex })
  };

    setValue = value => {
        this.props.setValue(value)
    }  

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '45px',
          height: '27px',
          borderRadius: '2px',
          background: this.state.color,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
      <p style={{color:'#5a5a5a', fontSize:13}}>{this.props.required ? `${this.props.label} *` :this.props.label}</p>
        <div style={ styles.swatch } onClick={ this.handleClick }>
          <div style={ styles.color } />
        </div>
        { this.state.displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
        </div> : null }

      </div>
    )
  }
}

const nonFormsyHOC = customFormHoc(SketchExample);

export { nonFormsyHOC as SketchExample };
export default withFormsy(SketchExample);