import createEntity from '../lib/createEntity';
import createBit from '../lib/createBit';


const createPlace = createEntity;
const createThing = createEntity;
const createPiece = createEntity;

export default {
    createPlace,
    createThing,
    createPiece,
    createBit
}