import OtpInput from 'react-otp-input';
import _ from 'lodash';
import React, {Component} from 'react';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';
require('./styles.scss');

class TextOtpInput extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: props.defaultValue,
        }
        this.changeValue = this.changeValue.bind(this);
        this.props.setValue(this.props.defaultValue);
    }

    changeValue(otp){
        // const value = e.target.value;
        this.setState({ 
            value: otp,
        })
        this.props.setValue(otp);
    }

    // componentWillReceiveProps(nextProps){
    //     if(this.props.values !== nextProps.values){
    //         this.setState({ 
    //             value: nextProps.values,
    //         })
    //         this.props.setValue(nextProps.values);
    //     }
    // }

    // onKeyDown = (event) => {
    //     if (event.keyCode === 13) {
    //         this.props.onEnter(this.state.value, event);
    //         this.props.onInteractionComplete(this.state.value, event);
    //     }
    //     this.props.onKeyDown();
    // }

    render(){
        return(
            <div ref="textField__container" className={classNames('textField__container', this.props.wrapperClass)}>
                <OtpInput
                    {...this.props}
                    value={this.state.value}
                    onChange={this.changeValue}
                    numInputs={this.props.numInputs}
                    //separator={<span>-</span>}
                    shouldAutoFocus
                    isInputNum='true'
                    data-cy	= 'otp'
                    inputStyle={{  
                        width: '2rem',  
                        height: '2rem',  
                        margin: 4,  
                        fontSize: '1rem',  
                        borderRadius: 4,  
                        border: '#D3D3D3', 
                        background:'#D3D3D3',
                        color:'black'                 
                    }}
                    {...this.props}
                />
            </div>
        ) 
    }
}

Text.defaultProps = {
    //onKeyDown: _.noop,
    onEnter: _.noop,
    onInteractionComplete: _.noop,
    onChange: _.noop,
};

export { TextOtpInput };
export default withFormsy(TextOtpInput);
