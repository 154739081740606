import React from 'react';
import tokenManager from '@utils/lib/tokenManager';
import _ from 'lodash';
import Helper from '@core/helpers'
import Entities from '@toolkit/entities';
import Button from '@web-bricks/Shared/Input/button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import Divider from '@material-ui/core/Divider';
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import LocationOnIcon from '@material-ui/icons/LocationOn';



class ShowAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileId: tokenManager.user,
        };
        this.helper = new Helper.UserSigninHelper()
        this.userHelper = new Helper.UserHelper()
    }

    async getData() {
        const user = await this.helper.getUserById(tokenManager.user)
        const locationList = await this.userHelper.getLocationFormate(user)
        let currentLocation =await Helper.CartHelper.getUserLocation(tokenManager.user)
        this.setState({
            user: user,
            locationList: locationList,
            currentLocation : currentLocation
        })
    }

    getIcon = (label) => 
    {
        if (label==='Home') {
         return(
            <HomeIcon style={{fontSize:'30px', margin:' auto',color:interiorDesigner.colorPallete.primary,padding:'20px'}}></HomeIcon>
            )
        }
        else if(label==='Work'){
            return(
                <WorkIcon style={{fontSize:'30px', margin:'  auto',color:interiorDesigner.colorPallete.primary,padding:'20px'}}></WorkIcon>
            )
        }
        else {
        return(
            <LocationOnIcon style={{fontSize:'30px', margin:'auto',color:interiorDesigner.colorPallete.primary,padding:'20px'}}></LocationOnIcon>
            )
        }
        
    }

    onEditAddress = (id) =>{
        this.goToView(`/addAddress/${id}`)
    }

    onDeleteAddress = async (id) =>{
        await this.userHelper.deleteAddress(id)
        const user = await this.helper.getUserById(tokenManager.user)
        const locationList = await this.userHelper.getLocationFormate(user)
        this.setState({
             locationList: locationList
        })

    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
           
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
       
        return (
            <MainLayout name='main-layout'>
                <View>
                    <Layout>
                        <Block name="address-list-block">
                            {
                            _.map(this.state.locationList, item =>
                                {
                                return(
                                    <Block>
                                    <Block name="address-icons">
                                        <Block>{this.getIcon(item.label)}</Block>
                                    <Block>
                                       <Bits.Text name="address-label">{item.label}</Bits.Text> 
                                       <Bits.Text name="address-details">{item.secondaryText}</Bits.Text> 
                                       <Block name="manage-address-block">
                                            <Button onClick={() => this.onEditAddress(item.value)} style={{ width: '80px', height: '100%', fontSize:'10px', color:interiorDesigner.colorPallete.primary}} startIcon={<EditIcon  style={{fontSize:'15px',}}/>}>Edit</Button>
                                            <Button onClick={() => this.onDeleteAddress(item.value)} style={{ width: '80px', height: '100%', fontSize:'10px',  color:interiorDesigner.colorPallete.primary}} startIcon={<DeleteIcon style={{fontSize:'15px',}}/>}>Delete</Button>
                                       </Block>
                                      
                                    </Block>
                                    </Block>
                                    <Divider />
                                    </Block>
                                    
                                    )
                                }
                            )
                            }
                        </Block>
                        
                    </Layout>
                </View>
            </MainLayout>
        );
    }
    getStylesheet = (colorPallete) => {
        return ({
            'main-layout': {
                master: {
                    //width: '50%',
                    margin: 0
                },
                mobile: {
                    width: '95%',
                    margin: '50px auto'
                }
            },
            'address-list-block':{
                master:{
                    width:350,
                    
                },
               
            },
            'address-icons':{
                master:{
                    display:'flex'
                }
            },
            'manage-address-block':
            {
                 master:{
                    display:'flex',
                    marginLeft: '0px',
                    padding: '3px',
                    color: colorPallete.primary,
                    marginBottom: '10px'
                    }
            },
           'address-label':{
               master:{
                   fontWeight: 'bold',
                   fontSize: '15px',
                   margin: 0,
                   marginTop:10
               }
           },
           'address-details':{
               master:{
                   color:interiorDesigner.colorPallete.subtext,
                   fontSize:'12px',
                   margin:0
               }
           }

            
        })
    }

}

export default Entities.createPlace(ShowAddress, {
    name: 'ShowAddress'
});