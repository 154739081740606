
const errorHandler = (options) => (error = {}) => {

    switch(error.status) {
        case 401:
            console.log(401);
            break;
        case undefined:
    }

    throw error;
};

export default errorHandler;
