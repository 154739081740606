import React from "react";
import _ from "lodash";
import Entities from "@toolkit/entities";
import Helper from "@core/helpers";
import FilterList from "@core/modules/shared/views/Things/filter";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import ChooseZones from "@core/modules/Delivery/views/Places/chooseZones";
import tokenManager from "@utils/lib/tokenManager";
import Container from "@core/modules/shared/views/Things/container.js";
import InventoryList from "@core/modules/Inventory/views/Things/inventoriesList";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dailog from "@web-bricks/Shared/view/components/dailog";
import SwipeableTabs from "../../../shared/views/Things/swipeableTabs";
import persistentStorage from "@utils/lib/localStorage";
import { Button } from "@material-ui/core";
import InventoryListByCategory from "@core/modules/Inventory/views/Things/inventoryListByCategory";
import ItemModal from "../../../Inventory/views/Things/itemModal";

class ShopSections extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inventories: {},
      categoryId: this.props.match.params._id,
      showFilterDailog: false,
      showTabsDailog: false,
      list: [],
      selectedTabIndex: 0,
      inventoryBook: {},
    };

    this.inventoryBook = {};
    this.shopHelper = new Helper.ShopHelper();
    this.categoryHelper = new Helper.CategoryHelper();
    this.inventoryHelper = new Helper.InventoryHelper();
    this.itemHelper = new Helper.ItemHelper();
    this.filterHelper = new Helper.FilterHelper();

    this.getRenderItems = this.getRenderItems.bind(this);
    this.getListComponent = this.getListComponent.bind(this);
  }

  async getData() {
    const [categoryDetails, primarySections] = await Promise.all([
      this.categoryHelper.getDetails(this.state.categoryId),
      this.inventoryHelper.getPrimarySectionNames(this.state.categoryId),
    ]);
    this.setState({
      CategoryName: _.upperFirst(_.get(categoryDetails, "category.name")),
      description: _.get(categoryDetails, "category.description"),
      sections: primarySections,
      propertyIndex: 0,
      inventoryCount: _.get(categoryDetails, "inventoryCount"),
    });

    // this.fetchInventories({
    //     sectionName: primarySections.pool[0],
    //     load: 15
    // })

    // this.startFetchingNextSectionInventories(primarySections)
    this.askDeliveryZoneIfNeeded();
    this.getFilters(primarySections);
  }

  // startFetchingNextSectionInventories = (sections) => {
  //     _.each(_.tail(sections.pool), (section, index) => {
  //         this.fetchInventories({
  //             sectionName: section,
  //             load: 15,
  //             delay: (index * 1000) + 300
  //         })
  //     })
  // }

  componentWillUnmount() {
    this.unmounted = true;
  }

  // fetchInventories = async ({ sectionName, delay, load }) => {
  //     setTimeout(async () => {

  //         if (this.unmounted) return;

  //         const inventories = await this.inventoryHelper.getSectionInventories(sectionName, this.props.match.params._id, this.state.list)

  //         const inventoryBook = this.inventoryBook;
  //         _.set(inventoryBook, `${sectionName}.inventories`, inventories);
  //         _.set(inventoryBook, `${sectionName}.groupedInventories`, _.groupBy(inventories, 'label'));
  //         this.inventoryBook = inventoryBook;
  //         this.setState({
  //             inventoryBook
  //         })
  //     }, delay)

  // }

  askDeliveryZoneIfNeeded = async () => {
    const deliveryZones = await this.shopHelper.hasDeliveryZones();
    const cart = Helper.CartHelper.getCart();

    if (deliveryZones && cart.orderType !== "takeaway") {
      setTimeout(() => {
        this.setState({
          showZones: true,
        });
      }, 2000);
    }
  };

  getFilters = async (primarySections) => {
    let filterList = await this.filterHelper.getFilters(
      primarySections.propertyId
    );
    this.setState({
      filterList: filterList,
    });
  };

  getLabelValues = (_) => {
    return {
      pool: _.get(this.state.sections, "pool"),
    };
  };

  onZoneSubmit = (zipCode) => {
    this.shopHelper.setZone(zipCode);
  };

  showFilters = () => {
    this.setState({
      showFilterDailog: true,
    });
  };

  getRenderItems() {
    return (
      <FilterList
        onSubmit={this.onSubmit}
        propertyId={this.state.sections.propertyId}
        list={this.filterHelper.filterList}
      />
    );
  }

  onBack = () => {
    this.setState({
      showZones: true,
    });
  };

  getFilters = async (primarySections) => {
    let filterList = await this.filterHelper.getFilters(
      primarySections.propertyId
    );
    this.setState({
      filterList: filterList,
    });
  };

  cartUpdate = (count, cartItem) => {
    Helper.CartHelper.updateCart(count, cartItem)
  }

  getLabelValues = (_) => {
    return {
      pool: _.get(this.state.sections, "pool"),
    };
  };

  onZoneSubmit = (zipCode) => {
    this.shopHelper.setZone(zipCode);
  };

  showFilters = () => {
    this.setState({
      showFilterDailog: true,
    });
  };

  getRenderItems() {
    return (
      <FilterList
        onSubmit={this.onSubmit}
        propertyId={this.state.sections.propertyId}
        list={this.filterHelper.filterList}
      />
    );
  }

  onBack = () => {
    this.setState({
      showFilterDailog: false,
      showTabsDailog: false,
    });
  };

  onSubmit = async (values, pureValues) => {
    let list = this.filterHelper.getFilteredList(pureValues);
    const inventories = await this.inventoryHelper.getSectionInventories(
      this.state.sections.pool[this.state.propertyIndex],
      this.props.match.params._id,
      list
    );
    const groupedinventories = _.groupBy(inventories, "label");
    this.setState({
      inventories: inventories,
      showFilterDailog: false,
      list: list,
      groupedinventories: groupedinventories,
    });
  };

  closeZoneDailog = () => {
    this.setState({
      showZones: false,
    });
  };

  getListComponent(params) {
    const { index, key } = params;
    const section = _.get(this.state.sections, `pool.${index}`);
    const groupedinventories = _.get(
      this.state.inventoryBook,
      `${section}.groupedInventories`
    );
    return (
      <InventoryList
        index={index}
        key={key}
        groupedinventories={groupedinventories}
      />
    );
  }

  handleChangeIndex = (index) => {
    this.setState({
      selectedTabIndex: index,
    });
  };

  onInventoryView = (group, relatedGroups) => {
    this.setState({
      group,
      relatedGroups
    });
  };

  onClose = () => {
    this.setState({
      group: null,
    });
  };

  renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
    return (
      <MainLayout>
        <Container
          page={{
            title: _.capitalize(this.state.CategoryName).split("_").join(" "),
            secondaryText: this.state.description,
            stickyTitle: true
          }}
          previousPage={[
            { title: "Home", onClick: () => this.props.history.push("/") },
            {
              title: "Categories",
              onClick: () => this.props.history.push("/categories"),
            },
          ]}
          activePage={"Section"}
          actions
          leftFixedTitle="true"
        >
          <Layout name="section-container">
            <Block
              style={{
                width: "100%",
              }}
            >
              {/* <SwipeableTabs
                                actions={[
                                    <Button
                                        disableElevation
                                        style={{
                                            margin: '5px 20px',
                                            color: `${interiorDesigner.colorPallete.primary}77`,
                                            backgroundColor: `${interiorDesigner.colorPallete.primary}12`
                                        }}
                                        variant="contained"
                                        onClick={this.showFilters}
                                        startIcon={<FilterListIcon />}
                                        children="Filter"
                                    />
                                ]}
                                list={_.map(this.state.sections.pool, (section, index) => ({
                                    label: section, render: (function () {
                                        const inventories = _.get(this.state.inventoryBook, `${section}.inventories`)
                                        const groupedinventories = inventories ? _.groupBy(inventories, 'label') : null;

                                        return <InventoryList
                                            key={index}
                                            cartCount={this.state.cartCount}
                                            onClick={this.onClick}
                                            onClose={this.onClose}
                                            onBack={this.onItemBack}
                                            showFlag={this.state.showFlag}
                                            inventory={this.state.inventory}
                                            addToStack={this.addToStack}
                                            inventoryHelper={this.props.inventoryHelper}
                                            groupedinventories={groupedinventories}
                                        />
                                    }).bind(this)
                                }))}
                            />
                        </Block>
                    </Layout>
                    {
                        (this.state.showZones && tokenManager.type !== 'staff') &&
                        <Layout>
                            <Block>
                                <ChooseZones
                                    handleClickOpen={this.onDailogOpen}
                                    handleClose={this.closeZoneDailog}
                                    open={this.state.showZones}
                                    dialogTitle='Select Your Zone'
                                    onZoneSubmit={this.onZoneSubmit}
                                />
                            </Block>
                        </Layout>
                    }

                    {
                        this.state.showFilterDailog &&
                        <Dailog
                            open={this.state.showFilterDailog}
                            getRenderItems={this.getRenderItems}
                            handleClose={this.onBack}
                            title="Filter"
                        />
                    }
                </Container>
            </MainLayout>

        )
    }

    getStylesheet = (colorPallete) => {
    
                                tabHeaders={values.pool}
                                component={this.getListComponent}
                                onTabChange={this.handleChangeIndex}
                            /> */}
              <InventoryListByCategory
                sections={this.state.sections}
                categoryId={this.state.categoryId}
                onSelect={this.onInventoryView}
                inventoryCount={this.state.inventoryCount}
              />
            </Block>
          </Layout>
          {this.state.showZones && _.isEmpty(tokenManager.user) && (
            <Layout>
              <Block>
                <ChooseZones
                  handleClose={this.closeZoneDailog}
                  open={this.state.showZones}
                  dialogTitle="Select Your Zone"
                  onZoneSubmit={this.onZoneSubmit}
                />
              </Block>
            </Layout>
          )}

          {this.state.showFilterDailog && (
            <Dailog
              open={this.state.showFilterDailog}
              getRenderItems={this.getRenderItems}
              handleClose={this.onBack}
              title="Filter"
            />
          )}
        </Container>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
        return ({
            'filter-button': {
                master: {
                    position: 'fixed',
                    bottom: 40,
                    right: 30,
                    zIndex: 1
                },
                mobile: {
                    bottom: 20,
                    right: 20,
                    margin: '0px 10px',
                    // left: 'auto'
                }
            },
            'modal-items-display': {
                master: {
                    position: 'absolute',
                    bottom: 0,
                    right: 0
                }
            },
            'main-container': {
                master: {
                    position: 'relative',
                    width: '100%'
                }
            },
            'list': {
                master: {
                    position: 'relative',
                    left: 300,
                    width: 'calc(100% - 260px)'
                },
                mobile: {
                    marginTop: 0,
                    left: 0,
                    width: '100%'
                }
            },
            'section-container': {
                master: {
                    display: 'flex',
                },
                mobile: {
                    display: 'unset',
                    margin: 0
                }
            },
            'section-tabs': {
                master: {
                    position: 'fixed',
                },
                mobile: {
                    position: 'unset'
                }
            },
            'items-text-align': {
                master: {
                    fontSize: 24,
                    textAlign: 'center',
                    color: colorPallete.primary
                }
            },
        }, {
        'header': {
            master: {
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: colorPallete.teritary,
                position: 'fixed',
                zIndex: 1
                // margin: '10px 10px 0px 10px',
            },
            mobile: {
                margin: '20px 20px 0px 20px',
                position: 'relative',
            },
            transition: 'children-slideRight'
        },
        'back-arrow': {
            master: {
                padding: '0px 5px'
            }
        },
        'store-layout': {
            master: {
                margin: '0 auto',
                width: '91%',
                padding: '0px 20px',
            },
            mobile: {
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                padding: 0,
            }
        },
        'top-bar': {
            master: {
                display: 'flex',
                justifyContent: 'space-between'
            }
        },
        'main-container': {
            master: {
                margin: '0px',
                marginTop: _.isNull(persistentStorage.get("selectedDeliveryZone")) ? 50 : 30
            },
            mobile: {
                marginTop: 0
            }
        },
        'store-header': {
            master: {
                textTransform: 'uppercase',
                textAlign: 'center',
                marginRight: 'auto',
                fontSize: 16,
                color: interiorDesigner.colorPallete.black,
                fontWeight: 400,
                letterSpacing: 2,
                minWidth: 300
            },
            mobile: {
                minWidth: 'unset',
                margin: '0px 0px 10px auto',
            }
        },
        'arrow-overlay': {
            master: {
                display: 'flex',
                alignItems: 'center',
            },
            mobile: {
                flexDirection: 'column',
                marginRight: 'auto'
            }

        },
        'logo-image': {
            master: {
                width: 65,
                height: 65,
                margin: '6px 0px 6px 10px'
            }
        },
        'profile-piece': {
            master: {
                margin: 20
            }
        },
        'navigation-block': {
            master: {
                display: 'flex',
            },
            mobile: {
                marginRight: 'auto',
            }
        },
        'section-navigation': {
            master: {
                display: 'flex',
                alignItems: 'center',
            },
            mobile: {
                padding: '0px 20px',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }
        },
        'section-navigation-text-one': {
            master: {
                cursor: 'pointer',
                color: colorPallete.subtext,
                fontSize: 16,
                display: 'flex',
                alignItems: 'center',
                margin: 0
            },
            mobile: {
                fontSize: 14
            }
        },
        'back-link-text': {
            mobile: {
                margin: 0
            }
        },
        'section-navigation-text-two': {
            master: {
                fontSize: 22,
                margin: '0px 5px',
                fontWeight: 500,
                textAlign: 'center',
                fontWeight: 'bold'
            },
            mobile: {
                fontSize: 20,
                lineHeight: 1,
                margin: 0,
                paddingLeft: 0
            }
        }
    })
  };
}

export default Entities.createPlace(ShopSections, {
  name: "ShopSections",
  hasSkeleton: true,
});
