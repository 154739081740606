import _ from "lodash";
import api from "@api";

class ReviewHelper {
  constructor() {}

  createReview = async (values, orderId, userId) => {
    _.map(values, (value, key) => {
      if (value.rating) {
        let data = {
          ...value,
          inventoryId: key,
          orderId: orderId,
          reviewer: userId,
          createdAt: Date().toLocaleString(),
        };
        api.Reviews.create(data);
      }
    });
  };

  getReviews = async (orderId) => {
    let reviewsList = await api.Reviews.getReviews(orderId);
    if (reviewsList.length > 0) {
      let reviews = {
        list: reviewsList,
        status: "COMPLETED",
      };
      return reviews;
    } else {
      let reviews = {
        list: reviewsList,
        status: "IN-COMPLETE",
      };
      return reviews;
    }
  };

  getReviewDetails = async (inventoryId) => {
    let rating = await api.Reviews.getReviewDetails(inventoryId);
    return rating;
  };

  getAvgRatings = async (inventoryId) => {
    let avgRatings = await api.Reviews.getAvgRatings(inventoryId);
    return avgRatings;
  };
}

export default ReviewHelper;
