import Entities from './entities';
import _ from 'lodash';

class Toolkit {
    constructor() {

    }

    setNativeDependecies = (dependecies) => {
        const dependencyInjectedEntities = _.mapValues(Entities, (Entity, label) => {
            return(...args) => Entity(...args, dependecies);
        })
        this.Entities = dependencyInjectedEntities;
        this.Bits = dependencyInjectedEntities.Bits;
    }
}

export default new Toolkit();