import {  Button, CircularProgress } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import { withFormsy } from "formsy-react";
import React, { Component } from "react";
import FileUploader from "react-firebase-file-uploader";
import customFormHoc from "./customFormHoc";
import _ from 'lodash'; 
import classnames from 'classnames';


class FirebaseUploaderButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      isUploading: false,
    };

    this.props.setValue(this.props.defaultValue);
  }

  handleUploadStart = () => {
    this.setState({ isUploading: true })
  };

  handleUploadError = error => {
    this.setState({ isUploading: false });
    throw error;
  };

  handleUploadSuccess = filename => {
    this.props.getFirebase()
    .storage()
    .ref(this.props.mediaHelper.uploadDestinationFolder)
    .child(filename)
    .getDownloadURL()
    .then(url => {
      this.props.onChange(url, filename)
      this.props.setValue(filename)
      this.setState({
        isUploading: false
      });
    });
  }
 
  render() {
    return (
      <div className={classnames('f-f-uploader', this.props.className)}>
         <div>
            {
                  this.state.isUploading ?
                      <CircularProgress size="30px" thickness={5} color="white" />
                    : <Button variant="contained" color="primary">Add Image</Button>
            }
          </div>
        <div className="f-f-upload-hidden">
            <FileUploader
                accept="image/*"
                name={this.props.name}
                storageRef={this.props.getFirebase().storage().ref(this.props.mediaHelper.uploadDestinationFolder)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                filename={this.props.mediaHelper.generateRID}
          />
        </div>
      </div>
    );
  }
}

FirebaseUploaderButton.defaultProps = {
  onChange: _.noop,
  onError: _.noop
}
 
export default {
    formInput: withFormsy(FirebaseUploaderButton),
    input: customFormHoc(FirebaseUploaderButton),
};