import React from 'react'
import Helper from '@core/helpers'
import _ from 'lodash';
import Entities from '@toolkit/entities';
import Tabs from '@web-bricks/Shared/view/components/tabs';
import date from '@web-bricks/lib/date';
import CollapsibleList from '../../../shared/views/Things/collapsibleList';
import OrderListView from './orderListView'
import Divider from '@material-ui/core/Divider';
import tokenManager from '@utils/lib/tokenManager'
import Container from '@core/modules/shared/views/Things/container.js';

class AllOrders extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            orders: [],
            profileId: tokenManager.user
        }
        this.helper = new Helper.OrderHelper()
        this.userHelper = new Helper.UserSigninHelper()
    }
    async getData() {
        const orders = await this.helper.getUserOrders()
        const user = await this.userHelper.getUserById(this.state.profileId)
        this.setState({
            orders: _.reverse(_.sortBy(orders, 'orderedAt')),
            user: user
        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }

    getList = (grouped) => {
        let listItems = []
        listItems = _.map(grouped, (list, groupLabel) => {
            let item = {
                label: _.toUpper(groupLabel).split('_').join(' '),
                path: _.toLower(groupLabel),
                render: () => this.getRenderItem(list)
            }
            return item
        })
        return listItems
    }

    getRenderItem = (list) => {
        const groupedByDate = date.groupByDate(list, 'orderedAt');

        return (
            <div >
                <CollapsibleList list={_.map(groupedByDate, group => {
                    return ({
                        label: group[0],
                        render: () => {
                            return <div style={{ width: '100%' }}>{_.map(group[1], (item, index) => {
                                return (
                                    <div style={{ width: '100%' }} onClick={() => this.goToView(`/orderSummary/${item._id}`)}>
                                        <OrderListView order={item} />
                                        {
                                            index === group[1].length - 1 ? null :
                                                <Divider style={{ margin: '20px 0px', width: '100%' }} />
                                        }
                                    </div>
                                )
                            })}
                            </div>
                        }
                    })
                })} />
            </div>
        )
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        const grouped = _.groupBy(this.state.orders, 'status')
        return (
            <Container
                page={{ title: 'All Orders' }}
                previousPage={[{ title: 'Profile', onClick: () => this.props.history.push('/profile') }]}
                activePage={'Orders'}
            >
                <View>
                    <Layout>
                        <Block name='order-list-tabs'>
                            {
                                this.state.orders.length > 0 ?
                                    <Tabs
                                        list={this.getList(grouped)}
                                    /> :
                                    <Bits.Text name='no-orders-text'>Oops..! No orders</Bits.Text>
                            }

                        </Block>
                    </Layout>
                </View>
            </Container>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'back-button': {
                transition: 'children-slideRight'
            },
            'orders-list-container': {
                transition: 'children-slideRight'
            },
            'no-orders-text': {
                master: {
                    textAlign: 'center',
                    color: colorPallete.subtext,
                    fontSize: 15,
                }
            },
            'order-list-tabs': {
                master: {
                    margin: '0px 30px 10px'
                }
            },
            'top-bar': {
                master: {
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            },
            'order-shortcut': {
                master: {
                    marginTop: 5
                }
            },
            'navigation-block': {
                master: {
                    display: 'flex'
                }
            },
            'section-navigation': {
                master: {
                    paddingLeft: 15
                }
            },
            'prev-navigation-text': {
                master: {
                    margin: 0,
                    color: 'grey',
                    fontSize: 12,
                    cursor: 'pointer'
                }
            },
            'active-navigation-text': {
                master: {
                    margin: '0 3px',
                    color: colorPallete.color,
                    fontSize: 12,
                    fontWeight: 'bold',
                }
            },
            'title': {
                master: {
                    fontSize: 22,
                    fontWeight: 600,
                    margin: 0
                }
            },
            'main-layout': {
                master: {
                    margin: '30px 20px'
                }
            },
        })
    }
}

export default Entities.createPlace(AllOrders, {
    name: 'AllOrders'
});


// {
//     _.map(this.state.orders, order =>{
//         const NoOfItemsOrdered = order.orderedItems.length
//         return(
//             <Card style={interiorDesigner.getStyles('order-list-card')}>
//                 <CardContent onClick={()=> this.props.history.push(`/orderSummary/${order._id}`)}>
//                     <div style={interiorDesigner.getStyles('order-number-bg')}>
//                         <p style={interiorDesigner.getStyles('order-number')}>{`Order No : #${order.orderNumber}`}</p>
//                     </div>
//                     <p style={interiorDesigner.getStyles('order-status')}>{order.status}</p>
//                     <div style={interiorDesigner.getStyles('flex-row-space-btw')}>
//                         <p style={interiorDesigner.getStyles('order-text')}>Ordered at</p>
//                         <p style={interiorDesigner.getStyles('order-text')}>{moment(order.orderedAt).format("h:mm a D MMM")}</p>
//                     </div>
//                     <div style={interiorDesigner.getStyles('flex-row-space-btw')}>
//                         <p style={interiorDesigner.getStyles('order-text')}>Total amount</p>
//                         <p style={interiorDesigner.getStyles('order-text')}>₹{order.bill.totalAmount}</p>
//                     </div>
//                     <div style={interiorDesigner.getStyles('flex-row-space-btw')}>
//                         <p style={interiorDesigner.getStyles('order-text')}>No of items</p>
//                         <p style={interiorDesigner.getStyles('order-text')}>{NoOfItemsOrdered}</p>
//                     </div>
//                 </CardContent>
//                 </Card>
//         )
//     })
// }