import { ContactPhoneSharp } from '@material-ui/icons';
import BaseModel from '@utils/lib/basemodel'
import request from "@utils/lib/request";

const User = new BaseModel("user");

const Inventories = new BaseModel("inventory");

const Orders = new BaseModel("order");

const Transactions = new BaseModel("transaction");

const ShopDetails = new BaseModel("shopDetails");

const Categories = new BaseModel("category");

const Properties = new BaseModel("property");

const Coupon = new BaseModel("coupon");

const Tags = new BaseModel("tag");

const Reviews = new BaseModel("review");

const NotificationDrafts = new BaseModel("notificationDraft");

const Templates = new BaseModel("template");

const Services = new BaseModel("service");

User.referrals = {
  createCode: (payload) => request.post("user/referrals/code/create", payload),
};

User.migration = () =>{
  return request.post('user/migration')
}

Services.sendMail = (message) => { //message should contains to, from, subject, text
  return request.post("service/sendMail", message);
};

Services.sendBulkMail = (message) => { //message should contains to(array), from, subject, text
  return request.post("service/sendMail", message);
};

Coupon.redeem = (payload) => {
  return request.post("coupon/redeem", payload);
};

Inventories.getDisplayBoardItems = (inventory) => {
  return request.get("inventory/getDisplayBoardItems");
};

Inventories.findInventoryPropertyId = (options) => {
  return request.post("inventory/findInventoryPropertyId", options);
};

Inventories.inventoriesLinkedWithCategory = (id) => {
  return request.post("inventory/inventoriesLinkedWithCategory", { id });
};

Inventories.updateMany = (ids, data) => {
  return request.post("inventory/updateMany", { ids, data });
};

Inventories.createInventory = (inventory) => {
  return request.post("inventory/createInventory", inventory);
};

Inventories.search = (name) => {
  return request.post("inventory/search", { name });
};

Inventories.getPropertiesList = (inventory) => {
  return request.post("inventory/getPropertiesList", { inventory });
};

Inventories.fetchPrimarySectionNames = (categoryId) => {
  return request.post("inventory/fetchPrimarySectionNames", { categoryId });
};

Inventories.fetchSecondarySectionNames = (categoryId) => {
  return request.post("inventory/fetchSecondarySectionNames", { categoryId });
};

Inventories.fetchSectionInventories = (
  propertyLabel,
  categoryId,
  filterData
) => {
  return request.post("inventory/fetchSectionInventories", {
    propertyLabel,
    categoryId,
    filterData,
  });
};

Inventories.getFilterList = (propertyId) => {
  return request.post("inventory/getFilterList", { propertyId });
};

Inventories.fetchCategories = (value) => {
  return request.post("inventory/fetchCategories");
};

Inventories.setIsDeleted = (ids) =>{
  return request.post("inventory/setIsDeleted", {ids})
}

Transactions.createRazorpayOrder = (options) => {
  return request.post("transaction/createRazorpayOrder", options);
};

Transactions.chechAuth = (
  orderId,
  paymentId,
  key_secret,
  razorpaySignature
) => {
  return request.post("transaction/chechAuth", {
    orderId,
    paymentId,
    key_secret,
    razorpaySignature,
  });
};

Transactions.initializeRefund = (transaction_id, key_id, key_secret) => {
  return request.post("transaction/initializeRefund", {
    transaction_id,
    key_id,
    key_secret,
  });
};

Transactions.updateFailedTransaction = (transactionOrderId, data) => {
  return request.post("transaction/updateFailedTransaction", {
    transactionOrderId,
    data,
  });
};

User.updateLocationArray = (id, item) => {
  return request.post("user/updateLocationArray", { id, item });
};

ShopDetails.getShopDetails = () => {
  return request.post("shopDetails/getShopDetails");
};

ShopDetails.UpdateZones = (_id, data) => {
  return request.post("ShopDetails/updateZones", { _id, data });
};

ShopDetails.editZones = (_id, data) => {
  return request.post("ShopDetails/editZones", { _id, data });
};

ShopDetails.editStaff = (_id, data) => {
  return request.post("ShopDetails/editStaff", { _id, data });
};

ShopDetails.updateStaff = (_id, data) => {
  return request.post("ShopDetails/updateStaff", { _id, data });
};

ShopDetails.deleteStaff = (_id, data) => {
  return request.post("ShopDetails/deleteStaff", { _id, data });
};

ShopDetails.sendRequestMail = (message) =>{
  return request.post("ShopDetails/sendRequestMail", {message})
}

ShopDetails.deleteDeliveryZone = (_id, data) => {
  return request.post("ShopDetails/deleteDeliveryZone", { _id, data });
};

ShopDetails.changeReferralStatus = (status) => {
  return request.post("ShopDetails/changeReferralStatus", status);
};

Inventories.fetchFilteredInventories = (list) => {
  return request.post("inventory/fetchFilteredInventories", { list });
};

Inventories.expandOrderedItems = (orderedItems) =>{
  return request.post("inventory/expandOrderedItems", {orderedItems})
}

Categories.findCategory = (categoryName) => {
  return request.post("category/findCategory", { categoryName });
};

Categories.getDetails = (categoryId) => {
  return request.post("category/getDetails", { categoryId });
}

ShopDetails.deleteDeliveryZone = (_id, data) =>{
    return request.post('ShopDetails/deleteDeliveryZone', {_id, data})
}

Tags.getInventoriesByTag = ({ query }) => {
  return request.post("tag/getInventoriesByTag", { query });
};

User.deleteAddress = (id, item) => {
  return request.post("user/deleteAddress", { id, item });
};

User.editAddress = (id, item) => {
  return request.post("user/editAddress", { id, item });
};

Orders.getByDates = (dates) => {
  return request.post("order/getByDates", { dates });
};

Reviews.getReviews = (orderId) =>{
    return request.post('review/getReviews', {orderId})
}
Reviews.getAvgRatings = (inventoryId) =>{
    return request.post('review/getAvgRatings', {inventoryId})
}

Reviews.getReviewDetails = (inventoryId) =>{
    return request.post('review/getReviewDetails', {inventoryId}) 
}
User.signIn = (phoneNum) =>{
    return request.post('user/signIn', {phoneNum} )
}

User.checkOTP = (code, userId) =>{
    return request.post('user/checkOTP', {code, userId})
}

User.getStaffDetails = () =>{
    return request.get('user/getStaffDetails')
}

User.sendBulkNotifications = (body, ids) =>{
  return request.post('user/sendBulkNotifications', {body, ids})
}

export default {
  Inventories,
  User,
  Orders,
  Transactions,
  ShopDetails,
  Categories,
  Properties,
  Coupon,
  Tags,
  Reviews,
  NotificationDrafts,
  Templates,
  Services
};