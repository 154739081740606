import { customAlphabet } from 'nanoid'

class GenerateNumber {

    generate  = () =>{
        const alphabet = '0123456789';
        const nanoid = customAlphabet('1234567890', 6);
        const number = nanoid()
        return number
    }

    generateRID = () => {
        const nanoid = customAlphabet('1234567890', 10);
        const number = nanoid()
        return `RID-${number}`;
    }
}

export default GenerateNumber

