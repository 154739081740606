import firebase from '@firebaseConnect';
import tokenManager from '@utils/lib/tokenManager';
import ShopHelper from './ebrShopHelper';
import UserSigninHelper from './signinHelper';
import api from '@api';
import GenerateNumber from '@utils/lib/generateNumber';
import persistentStorage from '@utils/lib/localStorage';
import _ from 'lodash';
// import * as firebase from 'firebase';

class UserHelper {

    constructor() {
        this.state = {
            key: null,
            password: null,
        };
        this.shopHelper = new ShopHelper();
        this.signinHelper = new UserSigninHelper()
    }


    editStaff = async (values, staffId) =>{
        await api.User.update(staffId, values)
    }

    addStaff = async (values, scb, ecb) =>{
        let userExists = await api.User.find({query:{"phone":values.phone}})
        if(userExists.length > 0){
            ecb(_.head(userExists))
        }else{
            await api.User.create(values)
            scb()
        }
        //
    }

    deleteStaff = async (value) =>{
        await api.User.deleteMany([value._id])
    }

     onEdit = async (values, id) =>{

        const item = {
            label : values.addressLabel,
            addressLineOne : values.addressLineOne,
            addressLineTwo : values.addressLineTwo,
            city : values.city,
            state : values.state,
            zipCode : values.zipCode,
            id:id,
            lat : values.lat,
            lng : values.lng
        }
        await api.User.editAddress(tokenManager.user, item)
     }
     deleteAddress = async (id) =>{
        const item ={
            id :id
        }
         await api.User.deleteAddress(tokenManager.user, item)
     }

    userCurrentZipcode = async(deliveringLocation) =>{
        const zipCode = await this.shopHelper.checkZoneAvailability(deliveringLocation.zipCode)
        if(zipCode !== null){
            this.shopHelper.setZone(zipCode)
        }else{
            persistentStorage.remove("selectedDeliveryZone")
        }
        
    }

    getLocationFormate = async (user) =>{
        let locationList = []
        _.map(user.locations, location => {
            const secondaryText = location.addressLineOne + " " + location.addressLineTwo + " " + location.city
            const label = location.label
            const value = location.id
            const item = { secondaryText, label, value , zipCode: location.zipCode }
            locationList.push(item)
        })
        return locationList
    }

    signOut = async () => {
        tokenManager.reset();
    }

    updateUserProfile = async (data) =>{
        await api.User.update(tokenManager.user, data)
    }

    getUserById = async (_id) => {
        const user = await api.User.getById(_id)
        return user
    }

    updateRegistrationCompleted = async (userId) =>{
        await api.User.update(userId, { registrationStatus:'COMPLETED', registrationCompletedAt: Date().toLocaleString()})
        this.signinHelper.removeUserDetails()
        //persistentStorage.set({"completedRegistration": true})
    }

    referrals = {

        createCode: async (userId) => {
            await api.User.referrals.createCode({ userId })
        }
    }
}

export default UserHelper
