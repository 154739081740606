import React from 'react';
import View from './View';
import Text from './Text';
import Image from './Image';
import Currency from './Currency';
import { Divider, Button, IconButton } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';
import BulletList from './bulletList'
import createBit from '../lib/createBit';
import BottomNavigation from '@web-bricks/Shared/view/components/bottomNav';
import {
    Person,
    Phone,
    Mail,
    AccountTreeOutlined,
    Add,
    ShoppingBasket, Close, ArrowBack, Search, WhatsApp, ArrowForwardTwoTone
} from '@material-ui/icons';

// temp map for icons - to be replaced with dynamic imports

const IconMap = {
    ShoppingBasket,
    Close,
    ArrowBack,
    Search,
    WhatsApp,
    ArrowForwardTwoTone,
    Person,
    Phone,
    Mail,
    AccountTreeOutlined,
    Add
};
const IconBit = createBit((function (props) {

    const Icon = IconMap[props.children]
    return React.createElement(Icon, props);

}), { name: 'Icon' });

const IconButtonBit = createBit(IconButton, { name: 'IconButton' });

const ButtonBit = createBit(Button, { name: 'Button' });
const BottomNav = createBit(BottomNavigation, { name: 'BottomNav' })
const SkeletonBit = createBit(Skeleton, { name: 'Skeleton' });

export {
    View,
    Text,
    Image,
    Divider,
    Currency,
    BulletList,
    IconBit as Icon,
    ButtonBit as Button,
    IconButtonBit as IconButton,
    BottomNav,
    SkeletonBit as Skeleton
}