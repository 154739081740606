import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { withFormsy } from "formsy-react";
import CustomFormHOC from "./customFormHoc";
import _ from "lodash";
import { Label } from "@material-ui/icons";

const filter = createFilterOptions();

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      dialogValue: "",
    };
    this.props.setValue(this.props.defaultValue);
  }

  updateValue = (label) => {
    this.props.setValue(label);
    this.props.onChange(label);
    this.setState({
      dialogValue: label,
    });
  };

  changeInputValue = (newValue) => {
    this.props.onInputChange(newValue);
  };

  render() {
    return (
      <Autocomplete
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            this.updateValue(newValue);
          } else if (newValue && newValue.inputValue) {
            this.updateValue(newValue.inputValue);
          } else {
            this.updateValue(newValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          this.changeInputValue(newInputValue);
        }}
        filterOptions={
          this.props.isSearch
            ? (options) => {
                return options;
              }
            : (options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }
        }
        options={this.props.options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo={this.props.freeSolo}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={this.props.placeholder}
            label={this.props.label}
            variant={this.props.variant}
            fullWidth={this.props.fullWidth}
          />
        )}
      />
    );
  }
}

AutoComplete.defaultProps = {
  onChange: _.noop,
  onInputChange: _.noop,
  freeSolo: true
};

export default {
  formInput: withFormsy(AutoComplete),
  input: CustomFormHOC(AutoComplete),
};
