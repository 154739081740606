import GenerateNumber from "@utils/lib/generateNumber";
import config from "@config";
import _ from "lodash";

class MediaHelper {
  constructor() {
    this.uploadDestinationFolder = "originals";
    this.thumbnailSourceFolder = "processed";

    this.storageBucket = config.getConfig(
      "firebase.firebaseConfig.storageBucket"
    );
    this.imageThumbnailSizePallete = config.getConfig(
      "imageThumbnailSizePallete"
    );

    this.generateNumber = new GenerateNumber();
  }

  generateRID = (file) => {
    return (
      this.generateNumber.generateRID() + `.${_.last(file.name.split("."))}`
    );
  };

  makeURLToStorage = (filename, folderType) => {
    const folder = folderType
      ? this.uploadDestinationFolder
      : this.thumbnailSourceFolder;
    return `https://firebasestorage.googleapis.com/v0/b/${this.storageBucket}/o/${folder}%2F${filename}?alt=media`;
  };

  getThumbnailURL = (filename, blockStyles) => {
    if (_.startsWith(filename, "RID")) {
      const fit = this.getNearestThumbnailDimension(blockStyles);
      const dimString = `${fit.width}x${fit.height}`;
      const namePieces = _.split(filename, ".");
      const dimFitURL = `${_.head(namePieces)}_${dimString}.${_.last(
        namePieces
      )}`;

      const url = this.makeURLToStorage(dimFitURL);
      return url;
    } else {
      return filename;
    }
  };

  getOriginalFileURL = (filename) => {
    if (_.startsWith(filename, "RID")) {
      return this.makeURLToStorage(filename, true);
    } else {
      return filename;
    }
  };

  getNearestThumbnailDimension = (blockStyles) => {
    const width = _.get(blockStyles, "width");
    const maxWidth = _.get(blockStyles, "maxWidth");

    const defaultBlockWidth = 300;
    let blockWidth = defaultBlockWidth;

    if (width) {
      if (!_.endsWith(width, "%")) {
        blockWidth = width;
      } else {
        if (maxWidth) {
          if (!_.endsWith(maxWidth, "%")) {
            blockWidth = maxWidth;
          }
        }
      }
    }

    blockWidth = _.round(
      _.toNumber(_.replace(_.toString(blockWidth), "px", "")),
      -2
    );
    const availableFit =
      this.imageThumbnailSizePallete[blockWidth] ||
      this.imageThumbnailSizePallete[defaultBlockWidth];

    return availableFit;
  };
}

export default new MediaHelper();
