import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <p style={{ fontWeight:600, textAlign:'center', color:'#498a31', fontSize:24}} {...props}>{props.heading}</p>
      <p style={{ margin: 0, fontSize: 14, color:'#498a31', opacity:0.5 }}>{props.subText}</p>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DialogBox(props) {
    return (
        <div>
            <Dialog className="select-address" fullWidth onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={props.handleClose} style={{margin: 'auto' }} {...props}>
                    {props.dialogTitle}
                </DialogTitle>
                <DialogContent dividers style={{ border:'none', margin: props.alignment ? 0 : '0px auto', textAlign:props.alignment === 'left' ? 'left' :'center' }}>
                    {props.renderItem()}
                </DialogContent>
            </Dialog>
        </div>
    );
}