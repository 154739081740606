import React, { Component } from 'react';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class CollapsibleList extends Component {
    render() {
        const accordions = _.map(this.props.list, (item, index) => {
            return (
                <Accordion defaultExpanded={true} style={{ boxShadow: 'none' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{_.capitalize(item.label)}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {item.render()}
                    </AccordionDetails>
                </Accordion>
            )
        })
        return (
            <div >
                {
                    accordions
                }
            </div>
        )
    }
}

export default CollapsibleList;