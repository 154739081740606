import api from '@api';
import _ from 'lodash';


class ItemsHelper {
    constructor() {
        this.inventoryStack = [];
    }
   
    getStackTop = () => {
        let inventory
        // if(this.inventoryStack.length>0){
            inventory = _.last(this.inventoryStack)
        // }
        // else{
        //     inventory = null
        // }
        return  inventory
    }

    updateStack = (inventory) =>{
        this.inventoryStack.push(inventory)
    }

    popOutStack = () =>{
        this.inventoryStack.pop()
    }

    addToStack = (inventory) =>{
        this.inventoryStack.push(inventory)
    }
}

export default ItemsHelper