import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { withFormsy } from 'formsy-react';


class HTMLInput extends React.Component {

  constructor(props) {
    super(props);
    this.props.setValue(props.defaultValue)
  }
  handleEditorChange = (content, editor) => {
    this.props.setValue(content || null)
  }

  // onImageUpload = (blobInfo, success, error) => {
  //   upload({
  //     file: blobInfo.blob(),
  //     filename: blobInfo.filename(),
  //     folder: 'images'
  //   }, success, error)
  // }

  render() {
    return (
      <div style={{ margin: '10px 0px' }}>
        <Editor
          initialValue={this.props.defaultValue}
          apiKey="gbut2zgtq1521o52fv4ovz5j5zd11ec9abaapvckxagugvt5"
          init={{
            height: 500,
            menubar: false,
            // images_upload_handler: this.onImageUpload,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat'
          }}
          onEditorChange={this.handleEditorChange}
        />
      </div>
    );
  }
}

export default withFormsy(HTMLInput);
