import _ from 'lodash';
import api from '@api';
import tokenManager from '@utils/lib/tokenManager';
import UserHelper from './userHelper'
import GenerateNumber from '@utils/lib/generateNumber';
import persistentStorage from '@utils/lib/localStorage';

class CartHelper {

    constructor() {
        this.cart = persistentStorage.get("cart") ?  persistentStorage.get("cart") : {
            cartItems : {}
        };
        this.listeners = {}
        this.userHelper = new UserHelper()
    }

    getCart = () =>{
        return this.cart
    }

    getCount = () => {
        return _.size(_.keys(this.cart.cartItems));
    }

    updateCart = async (count, cartItem) => {
        if (count === 0) {
            delete this.cart.cartItems[cartItem._id];
        } else {
            const totalPrice = count * cartItem.rates.saleRate;
            this.cart.cartItems[cartItem._id] = {count, cartItem, totalPrice};
        }
        _.forIn(this.listeners, (cb) => {
            cb(this.cart);
        })
        let sumAmount = 0;
        _.map(this.cart.cartItems, item =>{
            sumAmount = sumAmount + item.totalPrice
        })
        this.cart.sumAmount = sumAmount
        if(tokenManager.user){
            this.cart.locationTodeliver = await this.getUserLocation(tokenManager.user)
        }
        persistentStorage.set({cart : this.cart})
    }

    getUserLocation = async (id) =>{
        const user = await this.userHelper.getUserById(id)
        const deliveringLocation = _.find(user.locations, ({ id: user.currentLocation}))
        return deliveringLocation;
    }

    addListener = (cb, index) => {
        this.listeners[index] = cb;
    }

    removeListener = (index) => {
        delete this.listeners[index];
    }

    getItemCountInCart = (_id) => {
        const item =  _.get(this.cart.cartItems, _id);
        return (item && item.count) || 0;
    }

    addNewAddress = async (values) => {
        const generateNumber = new GenerateNumber()
        var number = generateNumber.generate()
        const item = {
            label : values.addressLabel,
            addressLineOne : values.addressLineOne,
            addressLineTwo : values.addressLineTwo,
            city : values.city,
            state : values.state,
            zipCode : values.zipCode,
            id : number,
            deliveringZone: values.deliveryZone,
            lat : values.lat,
            lng : values.lng
        }
        await api.User.updateLocationArray(tokenManager.user, item) //find&update
        if(values.setAsCurrentAddress){
            const data = {
                currentLocation :  number
            }
            await api.User.update(tokenManager.user, data)
            this.cart.locationTodeliver = item
        }
    }

    selectAddress = async (location, user) => {
        let deliveringLocation
        const data = {
            curentLocation:  location.value
        }
        let _id = tokenManager.user

        deliveringLocation = _.find(user.locations, { id: location.value })
        await this.userHelper.userCurrentZipcode(deliveringLocation)
        
        // update without waiting
        api.User.update(_id, data)
        this.cart.locationTodeliver = deliveringLocation
        this.cart.orderType = 'delivery';
    }

    updateDeliveryLocation = (location) => {
        this.cart.locationTodeliver = location;
    }

    updateOrderType = (type) => {
        this.cart.orderType = type;
        persistentStorage.set({cart : this.cart})
    }

    updatePaymentMode = (value) =>{
        if(value){
            this.cart.paymentMode = 'COD'
        }else{
            this.cart.paymentMode = 'RP'
        }
        persistentStorage.set({cart : this.cart})
    }

    clearCart = () =>{
        this.cart.cartItems = {}
        persistentStorage.set({cart : this.cart})
    }


    makeProps = (inventories) => {
        const label = _.head(inventories).label
        const rates = _.head(inventories).rates || {};
        const tags = _.head(inventories).tags;
        const saleRate = rates.MRP === rates.saleRate ? 0 : rates.saleRate;
        const discountPercentage = _.ceil(
          ((rates.MRP - rates.saleRate) / rates.MRP) * 100
        );
        return ({
            group: {
                label: _.capitalize(label),
                inventories,
                discountPercentage,
                rates,
                tags,
                image: _.get(_.head(inventories), `media.0.url`),
            }
        })
      }
}

 export default new CartHelper();

