import _ from 'lodash';
import React, {Component} from 'react';
import { TextField } from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';
import customFormHoc from './customFormHoc';
require('./styles.scss');

class Text extends Component {
    constructor(props){
        super(props);
        this.changeValue = this.changeValue.bind(this);
        if (props.defaultValue)
            this.setValue(this.props.defaultValue);
    }

    changeValue(e){
        let value = e.target.value;
        if (this.props.type === 'number') {
            value = parseFloat(value)
        }
        this.setValue(value || null);
        this.props.onChange(value, e, this.props.name);
    }

    setValue = value => {
        if (this.props.type === 'number') {
            value = parseFloat(value)
        }
        this.props.setValue(value)
    }

    // componentWillReceiveProps(nextProps){
    //     if(this.props.defaultValue !== nextProps.defaultValue){
    //         this.setState({ 
    //             value: nextProps.defaultValue,
    //         })
    //         this.setValue(nextProps.defaultValue);
    //     }
    // }

    onKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.props.onEnter(this.props.value, event);
            this.props.onInteractionComplete(this.props.value, event);
        }
        this.props.onKeyDown();
    }

    render(){
        return(
            <div ref="textField__container" className={classNames('textField__container', this.props.wrapperClass)}>
                <TextField
                    fullWidth
                    //helperText={this.props.getErrorMessage()}
                    {...this.props}
                    onChange={this.changeValue}
                    required = {this.props.required ? true : false}
                    inputProps = {{ onKeyDown: this.onKeyDown}}
                />
            </div>
        ) 
    }
}

Text.defaultProps = {
    onKeyDown: _.noop,
    onEnter: _.noop,
    onInteractionComplete: _.noop,
    onChange: _.noop,
};

const nonFormsyHOC = customFormHoc(Text);

export { nonFormsyHOC as Text };
export default withFormsy(Text);
