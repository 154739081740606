import React from "react";
import _ from "lodash";
import Helper from "@core/helpers";
import Entities from "@toolkit/entities";

class MenuList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.vendorHelper = new Helper.VendorHelper();
    this.categoryHelper = new Helper.CategoryHelper();
  }

  async getData() {
    let categories = await this.categoryHelper.getCategories();
    this.setState({
      categories,
    });
  }

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };

  onItemClick = (categoryId) => {
    this.goToView(`shop/${categoryId}`);
  };

  renderSurface(
    values,
    { MainLayout, Layout, Block, Bits, View, Switch, interiorDesigner },
    config
  ) {
    return (
      <MainLayout name="menulist-mainlayout">
        <Block
          name="menulist-block"
          skeleton={{
            type: "card",
            cols: 5,
            width: !interiorDesigner.isMobile() ? 180 : "100%",
          }}
        >
          <Layout name="categories-container">
            {_.map(this.state.categories, (category, index) => {
              return (
                <Layout
                  key={index}
                  name="category-block-layout"
                  onClick={() => this.onItemClick(category._id)}
                >
                  <Block name="category-image-container">
                    <Bits.Image
                      name={
                        category.image ? "primary-item-image" : "image-place"
                      }
                      src={
                        category.image
                          ? category.image
                          : config.shop.compactLogo
                      }
                    />
                  </Block>
                  <Block name="category-name-container">
                    <Bits.Text name="category-name">
                      {_.capitalize(category.name).split("_").join(" ")}
                    </Bits.Text>
                  </Block>
                </Layout>
              );
            })}
          </Layout>
        </Block>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete, config) => {
    return {
      "description-text":{
        master:{
          color: colorPallete.subtext,
          margin: 'auto',
          fontSize: 12
        }
      },
      "menulist-mainlayout": {
        master: {
          width: "100%",
        },
      },
      "primary-item-image": {
        master: {
          width: "auto",
          height: 100,
          display: "block",
          margin: "auto",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        },
        mobile: {
          width: "unset",
        },
        // mobile: {
        //     width: 'calc(100% - (20px * 2))',
        //     margin: '20px'
        // }
      },
      "image-place": {
        master: {
          objectFit: "none",
          width: "100%",
          opacity: 0.9,
        },
      },
      "menulist-block": {
        master: {
          minHeight: 180,
        },
        mobile: {
          minHeight: 100,
        },
      },
      "category-image-container": {
        master: {
          background: colorPallete.white,
          width: "100%",
          height: 120,
          borderRadius: 4,
          opacity: 0.9,
          borderRadius: 4,
          overflow: "hidden",
        },
        mobile: {
          minWidth: "40%",
          maxWidth: "40%",
          height: 90,
          opacity: 0.9,
          marginRight: 10,
          // width: '50%'
        },
      },
      "header-layout": {
        master: {},
        transition: "slideRight",
      },
      "categories-container": {
        master: {
          // dis
          width: "100%",
          textAlign: "center",
          overflow: "hidden",
          // margin: '20px 0px',
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
          minHeight: 180,
        },
        transition: "children-slideRight",
        mobile: {
          display: "inline-grid",
          gridTemplateColumns: "repeat(2, [col-start] 50%)",
          gridGap: "15px",
          width: "calc(100% - 20px)",
          margin: "10px",
          // display: 'flex',
          // flexWrap: 'wrap'
        },
      },
      "category-page-header": {
        width: "80%",
        margin: "0px",
      },
      // 'categories-list': {
      //     master: {
      //         overflow: 'hidden',
      //         margin: '0px 0px 70px 0px'
      //     },
      //     mobile: {
      //         marginTop: 10
      //     }
      // },
      "category-header": {
        master: {
          fontSize: 28,
          color: colorPallete.primary,
          fontWeight: 600,
          letterSpacing: 1,
          margin: 0,
        },
        mobile: {
          fontSize: 24,
          letterSpacing: 0.5,
          margin: 0,
        },
      },
      // 'categories': {
      //     master: {
      //         display: 'flex',
      //         flexWrap: 'wrap',
      //         marginBottom: 80
      //     },
      //     mobile: {
      //         flexDirection: 'column',
      //     }
      // },
      "block-subtitle": {
        master: {
          fontSize: 15,
          margin: "4px 0px",
          opacity: 0.7,
        },
        mobile: {
          fontSize: 12,
          textAlign: "center",
        },
      },
      "navigation-link": {
        master: {
          display: "flex",
          alignItems: "center",
        },
      },
      "previous-page": {
        master: {
          display: "flex",
          alignItems: "center",
          color: colorPallete.subtext,
          cursor: "pointer",
          left: 40,
          top: 40,
        },
        mobile: {
          left: 15,
          top: 34,
        },
      },
      "active-page": {
        master: {
          color: colorPallete.teritary,
        },
      },
      "navigation-block": {
        master: {
          display: "flex",
        },
      },
      "content-container": {
        master: {},
        mobile: {
          width: "100%",
          margin: "10px 0px",
        },
      },
      "category-bg": {
        master: {
          position: "fixed",
          right: 0,
          top: "15%",
          backgroundImage: `url(${config.shop.categoryBgImage})`,
          width: 605,
          height: "100%",
          zIndex: -1,
          backgroundRepeat: "no-repeat",
          backgroundSize: 605,
          opacity: 0.5,
        },
        mobile: {
          height: "calc(100vh - 100px)",
          top: "10%",
          left: -10,
        },
      },
      "category-block-layout": {
        master: {
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: 4,
          // padding: '0px 10px',
          margin: "10px",
          boxSizing: "border-box",
          width: 260,
        },
        mobile: {
          width: "100%",
          height: "100%",
          overflow: "hidden",
          flexDirection: "row",
          height: 90,
          background: "#fbfbf6",
          justifyContent: "unset",
          margin: "7px 0px",
        },
      },
      "category-name-container": {
        master: {
          maxWidth: "100%",
          margin: "0 auto",
          padding: "0px 10px",
        },
        mobile: {
          margin: "0px 10px",
          maxWidth: "unset",
          minWidth: "unset",
        },
      },
      "category-name": {
        master: {
          textAlign: "center",
          // top: '50%',
          textTransform: "capitalize",
          position: "relative",
          color: colorPallete.teritary,
          fontSize: 18,
          // transform: 'translateY(-50%)',
          verticalAlign: "middle",
          fontWeight: 500,
          flex: 1,
          fontWeight: "bold",
          //margin: '25px 30px 25px 0px'
          color: "#616156",
        },
        mobile: {
          fontSize: 18,
        },
      },
    };
  };
}

export default Entities.createPlace(MenuList, {
  name: "MenuList",
  hasSkeleton: true,
});

const imageSample =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&usqp=CAU";
const colors = [
  "#3300FF",
  "#6600FF",
  "#9900FF",
  "#FF00FF",
  "#666666",
  "#FF6633",
];
