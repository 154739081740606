import React from 'react';
import SwitchInput from '@material-ui/core/Switch';
import { withFormsy } from 'formsy-react';
import CustomFormHOC from './customFormHoc';
import _ from 'lodash';

class Switch extends React.Component {
    constructor(props){
        super(props);
        this.props.setValue(this.props.defaultValue || this.props.value);
    }

    onChange = (event) => {
        const value = event.target.checked;
        this.props.setValue(value);
        this.props.onChange(value);
    }

    render() {
        return (
            <div className="switch__container">
                <SwitchInput
                    checked={this.props.value}
                    value={this.props.value}
                    name={this.props.name}
                    {...this.props}
                    onChange={this.onChange}
                    defaultChecked = {this.props.defaultValue}
                />
            </div>
        );
    }
}

Switch.defaultProps = {
    onChange: _.noop,
};

export default {
    formInput: withFormsy(Switch),
    input: CustomFormHOC(Switch),
};
