import React from 'react';
import _ from 'lodash';
import DataTable from "@web-bricks/Shared/table/dataGrid";
import { withFormsy } from 'formsy-react';
import CustomFormHOC from './customFormHoc';

class SharedDataTable extends React.Component {

    constructor(props) {
        super(props); 
        this.state = {
            list: []
        }
    }

    onSelectionModelChange = (values) => {
        this.setState({
            list : values
        })
        this.props.setValue(values)
        this.props.handleSelectionModelChange(values)    
    }



    render() {
        return (
            <div className="shared_data_table">
                <DataTable
                    columns={this.props.columns}
                    onSelectionModelChange = {this.onSelectionModelChange}
                    rows={this.props.rows}
                /> 
            </div>
        );
    }
}




export default {
    formInput: withFormsy(SharedDataTable),
    input: CustomFormHOC(SharedDataTable)
}
