import React from 'react';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import Helper from '@core/helpers'
import config from '@config/index';
import Grid from '@material-ui/core/Grid';

class InfoBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    async getData() {

    }

    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        return (
            <MainLayout name="info-main-block">
                <Layout name="info-block-container">
                    <Block name='info-image'>
                        <Bits.Image
                            src={config.getConfig('shop').infoBlockImage}
                            alt=''
                            name="info-banner"
                        />
                    </Block>
                    <Layout name='info-block'>
                        <Block>
                            <Bits.Text name='info-title'>{config.getConfig('shop').description}</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='info-text'>{config.getConfig('shop').about.text}</Bits.Text>
                        </Block>
                    </Layout>
                </Layout>
            </MainLayout>
        )
    }
    getStylesheet = (colorPallete, config) => {
        return ({
            'info-layout': {
                master: {
                    display: 'flex',
                },
                mobile: {
                    flexDirection: 'colomn'
                }
            },
            'info-block': {
                master: {
                    margin: 30
                },
                mobile: {
                    margin: 20
                }
            },
            'info-title': {
                master: {
                    color: colorPallete.primary,
                    margin: 'auto',
                    fontWeight: 'bold',
                    fontSize: 40,
                    etterSpacing: 1,
                    fontFamily: 'initial',
                    //textAlign:'center'
                },
                mobile: {
                    fontSize: 30
                }
            },
            'info-text': {
                master: {
                    //textAlign:'center',
                    fontSize: 15,
                    lineHeight: 1.5,
                    color: 'grey'
                },
                mobile: {
                    fontSize: 15
                }
            },
            'info-image': {
                master: {
                    maxWidth: "50%",
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: 'contain',
                },
                mobile: {
                    maxWidth: "100%",
                    // width: 60,
                    // height: 60,
                    //marginLeft: '-6px'
                }
            },
            'info-block-container': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row-reverse',
                    //width: 200,
                    height: 'auto',
                    background: '#f4e6d7',
                    borderRadius: 4,
                    margin: '50px 0px',
                },
                mobile: {
                    flexDirection: 'column'
                }
            }
            // 'top-bar-container': {
            //     master: {
            //         height: 100,
            //     },
            //     mobile: {
            //         height: 70,
            //     }
            // },
            // 'top-bar-outer': {
            //     master: {
            //         backgroundColor: colorPallete.bg,
            //         zIndex: 3,
            //         position: 'fixed',
            //         width: '100%',
            //         top: 0,
            //         left: 0,
            //     },
            //     mobile: {
            //         height: 70,
            //     }
            // },
        })
    }
}

export default Entities.createPlace(InfoBlock, {
    name: 'InfoBlock'
});