import api from '@api';
import _ from 'lodash';
import tokenManager from '@utils/lib/tokenManager'

class CouponHelper {

    redeem = async (code, bill) => {
        return await api.Coupon.redeem({ code, bill, userId: tokenManager.user, timestamp: new Date() })
    }

    getDataFromValues = values => {
        return ({
            name: values.name,
            code: values.code,
            description: values.description,
            featuredImage: values.featuredImage,
            conditions: JSON.stringify({ all: values.conditions }),
            discount: _.merge({
                discountAttribute: null,
                discountAmount: null,
                discountPercentage: null,
            }, values.discount), // ugly patch for discount object being merge instead of replacing itself.
            exclude: !_.isEmpty(values.excludePool) ? [{
                type: 'CATEGORY',
                pool: values.excludePool
            }] : [],
            type: 'GENERIC'
        })
    }

    create = async (values) => {
        return await api.Coupon.create(this.getDataFromValues(values))
    }

    update = async (values, couponId) => {
        return await api.Coupon.update(couponId, this.getDataFromValues(values))
    }

    getGenericCoupons = () => {
        return api.Coupon.find({ query: { type: 'GENERIC' }});
    }

    getById = async (id) => {
        return await api.Coupon.getById(id);
    }

    expandCoupon = async (couponId) =>{
        let couponDetails = await this.getById(couponId)
        return couponDetails.code
    }
}

export default CouponHelper
