import _ from 'lodash';

const isProduction = process.env.NODE_ENV === "production";


let instanceConfig = {}
let masterConfig = {};

try {
  masterConfig = require('./master.js').default;
    
} catch (e) {
    // no file found
    // console.log(e)
}

try {
  instanceConfig = require('./instance.js').default;
} catch (e) {
    // no file found
    // console.log(e);
}

let config = {};
config = { ...masterConfig, ...instanceConfig };

const getConfig = (module, env = process.env.NODE_ENV) => module ? _.get(config[env], module) : config[env];

const apiBaseUrl = getConfig('serverURL');

export default {
  api : {
    baseUrl: `${apiBaseUrl}`,
    uploadUrl: `${apiBaseUrl}`,
  },
  isProduction,
  getConfig,
}
