import { initializeApp, auth } from "firebase";
import config from '@config'

const apps = {
    shop: initializeApp(config.getConfig('firebase').firebaseConfig),
    ebr: initializeApp(config.getConfig('firebase').shop, "secondary"),
    thisFirebase: {
        auth: auth
    }
}


export default apps;