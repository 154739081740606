import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {withFormsy} from 'formsy-react';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@material-ui/core';

class CheckboxList extends Component {
    constructor(props){
        super(props);
        this.state = {}

        if (this.props.defaultValue)
            this.setValue(_.cloneDeep(this.props.defaultValue));
    }

    setValue = value => {
        const v = _.isEmpty(value) ? null : value;
        this.props.setValue(v)
        this.setState({ v })
    }

    handleChange = value => (event) => {
        let list = this.props.value || [];
        if(event.target.checked){
            list.push(value);
            list = _.compact(_.uniq(list));
            this.setValue(list);
        }
        else{
            list = _.pull(list, value)
            this.setValue(list)
        }
    }

    isItemChecked = (selectedValues, currentRenderingItemValue) => {
        if (this.props.enableObjectEqualityCheck) {
            return _.includes(_.map(selectedValues, _.toString), currentRenderingItemValue.toString());
        } else {
            return _.includes(selectedValues, currentRenderingItemValue);
        }
    }

    renderChildrenIfAny = (item, isChecked) => {
        if (item.render) {
            if (item.hideChildrenIfUnchecked) {
                if (isChecked) return item.render();
            } else return item.render()
        } else return null;
    }

    saveOtherText = (e, v) => {
        const list = this.props.value;
        const index = _.findIndex(list, a => a === this.getZebraFromSheepherd());
        list[index] = e.target.value;
        this.props.setValue(list);
    }

    getZebraFromSheepherd = () => {
        const zebras = _.difference(this.props.value, _.map(this.props.list, l => (l.value || l.label)));
        return zebras.join(', ') || '';
    }

    render(){
        let list = _.compact(this.props.list);
        if (this.props.sortByLabel) {
            list = _.sortBy(this.props.list, 'label');
        }

        if (this.props.includeOther) {
            list.push({
                label: 'Others',
                hideChildrenIfUnchecked: true,
                checked: !!this.getZebraFromSheepherd(),
                render: () => {
                    const value = this.getZebraFromSheepherd();
                    return (<TextField
                        onBlur={this.saveOtherText}
                        fullWidth
                        multiline
                        defaultValue={value.replace('Others', '')}
                        variant="outlined"
                        label="Others.."
                    />)
                }
            })
        }
        const listItems = list.map((item, index) => {
            const value = item.value || item.label;
            const isChecked = item.checked || this.isItemChecked(this.props.value, value)
            return <div className={this.props.blockClassName} key={index}>
                <FormControlLabel
                control={
                    <Checkbox
                        onChange={this.handleChange(value)}
                        key={value}
                        item={item}
                        label={item.label}
                        checked = {isChecked}
                        defaultChecked={_.includes(this.props.defaultValue, item.value)}
                    >
                    </Checkbox>
                }
                label={item.label}
                className={this.props.labelClassName}
          />
          {this.renderChildrenIfAny(item, isChecked)}
        </div>
        })
        return(
            <FormGroup className={classNames('radio-group', this.props.className, { 'row': this.props.displayInRow})}>
                {listItems}
            </FormGroup>
        )
    }
}

CheckboxList.defaultProps = {
    sortByLabel: true,
}

export default withFormsy(CheckboxList);