import React from "react";
import _ from "lodash";
import { withFormsy } from "formsy-react";
import TextField from "../../Input/textField";
import TextArea from "../../Input/textarea";
import ButtonInput from "../../Input/button";
import Select from "../../Input/select";
import RadioGroup from "../../Input/radio";
import Checkbox from "../../Input/checkbox";
import CheckboxList from "../../Input/CheckboxList";
import Switch from "../../Input/switch";
import UploadToFirebase from "../../Input/uploadToFirebase";
import AutoComplete from "../../Input/autoComplete";
import HTMLInput from "../../Input/htmlInput";
import Address from "../../Input/address";
import ChipsInput from "../../Input/chipInput";
import SketchExample from "../../Input/sketchPicker";
import MultiSelect from "../../Input/creatableAutocomplete";
import GroupLine from "./groupLine";
import DateTime from "../../Input/dateTimePicker";
import HiddenInput from "../../Input/hiddenInput";
import SingleGroupLine from "./singleGroupLine";
import RatingStars from "../../Input/rating";
import sharedDataTable from "../../Input/sharedDataTable";
import SocialMediaLink from '../../Input/socialMediaLinks';

const FormInputMap = {
  textField: TextField,
  Button: ButtonInput,
  select: Select,
  textArea: TextArea.formInput,
  radioGroup: RadioGroup,
  checkbox: Checkbox,
  checkboxList: CheckboxList,
  Switch: Switch.formInput,
  FirebaseFileUpload: UploadToFirebase.formInput,
  AutoComplete: AutoComplete.formInput,
  Address: Address,
  chipsInput: ChipsInput,
  ColorPicker: SketchExample,
  MultiSelect: MultiSelect,
  HTMLInput,
  GroupLine,
  SingleGroupLine,
  DateTime : DateTime,
  DataTable: sharedDataTable.formInput,
  Block: function (props) {
    return (
      <span {...props}>{props.render ? props.render() : props.children}</span>
    );
  },
  HiddenInput,
  Rating: RatingStars,
  SocialMediaLink: SocialMediaLink
};

const Child = (
  transitStyles = {},
  index,
  field,
  defaultModel,
  FieldInput,
  currentValues
) => (
  <div
    key={index}
    style={{ ...transitStyles, ...(field.style || { margin: 15 }) }}
  >
    {field.title && (
      <p
        className={field.titleClassName}
        style={{
          color: "black",
          fontWeight: "bold",
          marginBottom: 0,
        }}
      >
        {field.title}
      </p>
    )}
    <FieldInput
      FormMaker={FormMaker}
      key={index}
      formCurrentValues={
        _.get(field.props, "passCurrentValues") && currentValues
      }
      {...field.props}
      defaultValue={
        field.props.defaultValue || _.get(defaultModel, field.props.name)
      }
    />
  </div>
);

const FormMaker = (
  { fields, Transition, wrapperClassName, currentValues },
  defaultModel
) => {
  return (
    <div className={wrapperClassName}>
      {_.map(_.flatten(_.compact(fields)), (field, index) => {
        const FieldInput = FormInputMap[field.type];
        return Transition ? (
          <Transition
            index={index}
            render={(transitStyles) =>
              Child(transitStyles, index, field, defaultModel, FieldInput)
            }
          />
        ) : (
          Child({}, index, field, defaultModel, FieldInput, currentValues)
        );
      })}
    </div>
  );
};

const addCustomFormInput = ({ type, Input }) => {
  FormInputMap[type] = withFormsy(Input);
};
export default FormMaker;
export { addCustomFormInput };
