import React from "react";
import _ from 'lodash';
import Entities from '@toolkit/entities';
import Form from '@web-bricks/Shared/form/components/form';
import DialogBox from '@core/modules/shared/views/Things/dialogBox';
import Helper from '@core/helpers';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import delivery from '@core/assests/images/delivery.png';

class ChooseZones extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage : false,
            successMessage : false
        }
        this.shopHelper = new Helper.ShopHelper()
    }

    async getData() {
       const zoneList = await this.shopHelper.getDeliveryZones()
       const item = {
        label : 'Take-Away',
        value : 'takeaway'
    }
    zoneList.push(item)
        this.setState({
            ZonesList : zoneList,
        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }

    onSubmit = async (values) =>{
        let zip = await this.shopHelper.checkZoneAvailability(values.zipCode)
        if(zip !== null){
            this.props.onZoneSubmit(zip)
            setTimeout(() => {
                this.props.handleClose()
            }, 2000)
            this.setState({
                errorMessage : false,
                successMessage : true
            })
        }else{
            this.setState({
                errorMessage : true,
                successMessage : false
            })
        }
        values.onSuccess();
    }

    renderSelectZone = (Block, Bits) =>{
        return (
            <Block>
                <Block name='delivery-image-block' >
                    <Bits.Image name='delivery-image' src={delivery} />
                </Block>
                
            {
                this.state.successMessage !== true &&
                <Form submitButton={{ label: 'check', style: { backgroundColor: interiorDesigner.colorPallete.primary, color: interiorDesigner.colorPallete.bg }  }} noValidate onSubmit={this.onSubmit} 
                    formMaker={{
                        list: [{
                            type: 'textField',
                            props: {
                                name: 'zipCode',
                                label: 'Zip code',
                                wrapperClass: 'coupon-field',
                            }
                        }]
                    }}
                />
            }
            {
                this.state.errorMessage &&  <Block name="no-delivery-zip"> 
                    <Bits.Text>Unavailable delivery services for the selected zip code. Opt takeaway for continue shopping!</Bits.Text> 
                </Block>
            }
            {
                this.state.successMessage && 
                    <Block name='success-block'>
                        <Bits.Text name='success-bg'>🎉</Bits.Text>
                        <Bits.Text name='success-text'>Yayy! We deliver to your location. Happy shopping!</Bits.Text>
                    </Block>
            }
            </Block>
        )
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
    }) {
        return (
            <MainLayout>
                <Layout>
                    <Block>
                        <DialogBox
                            onClose = {this.props.handleClose}
                            handleClickOpen={this.handleClickOpen}
                            handleClose={this.props.handleClose}
                            open={this.props.open}
                            heading='Check delivery availability '
                            renderItem = {() =>this.renderSelectZone(Block, Bits)}
                        />
                    </Block>
                </Layout>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'delivery-image':{
                master:{
                    width:120
                }
            },
            'delivery-image-block': {
                master:{
                     display:'flex', 
                     justifyContent:'center' 
                }
            },
            'success-bg':{
                master: {
                    fontSize:60,
                    margin: 'auto',
                    textAlign:'center'
                },
            },
            'success-text':{
                master:{
                    textAlign:'center',
                    color: 'green'
                    // marginTop:0,
                }
            },
            'no-delivery-zip': {
                master: {
                    textAlign: 'center',
                    color: 'red'
                }
            }
        })
    }
}

export default Entities.createPlace(ChooseZones, {
    name: 'ChooseZones'
});