import React from "react";
import _ from "lodash";
import Entities from "@toolkit/entities";
import SliderComp from "@web-bricks/Shared/Image/slidercomp";

class InventoryMedia extends React.Component {
  constructor(props) {
    super(props);
  }

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };

  renderSurface(values, { MainLayout, Layout, Block, View, Bits }, config) {
    return this.props.type === "slider" ? (
      <SliderComp>
        {!_.isEmpty(this.props.media) ? (
          _.map(this.props.media, (item) => {
            return (
              <Block name="media-showcase" style={this.props._blockstyles}>
                <Bits.Image noLazy name="media-block" src={item.url} />
              </Block>
            );
          })
        ) : (
          <Block name="media-showcase">
            <Bits.Image src={config.shop.compactLogo} name="image-place" />
            <Bits.Text style={{ textAlign: "center", fontSize: 13 }}>
              No Images Available
            </Bits.Text>
          </Block>
        )}
      </SliderComp>
    ) : (
      <Block name="inventory-card-image">
        <Bits.Image
          noLazy
          src={
            this.props.media.length > 0 ? this.props.media[0].url : imageSample
          }
          name="image-block"
        />
      </Block>
    );
  }

  getStylesheet = (colorPallete) => {
    return {
      "media-block": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
      "image-place": {
        master: {
          objectFit: "none",
          width: "100%",
          opacity: 0.9,
        },
      },
      "image-block": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
      "inventory-card-image": {
        master: {
          width: "100%",
          maxWidth: 200,
          height: 140,
          position: "relative",
        },
      },
    };
  };
}

export default Entities.createThing(InventoryMedia, {
  name: "InventoryMedia",
});

const imageSample =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&usqp=CAU";
