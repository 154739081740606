import React from 'react';
import Form from '@web-bricks/Shared/form/components/form';
import FormMaker from '@web-bricks/Shared/form/components/formMaker'
import Helper from '@core/helpers/index'
import toolkit from '@toolkit';
import config from '@config';
import tokenManager from '@utils/lib/tokenManager';
import _ from 'lodash';
import Container from '@core/modules/shared/views/Things/container.js';

class AddAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addressId: this.props.match.params._id,

        };
        this.userHelper = new Helper.UserHelper()
    }

    OnAddNewAddress = async (values) => {
        if (!_.isEmpty(this.state.addressId)) {
            await this.userHelper.onEdit(values, this.state.addressId)
        } else {
            Helper.CartHelper.addNewAddress(values)
            if(this.state.user.registrationStatus==='PENDING'){
                this.userHelper.updateRegistrationCompleted(this.state.user._id)
            }
        }
        this.onComplete(
            {
                nextRoute: '/manageAddresses'
            }
        )
    }
    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }
    // edit = (value) => {

    //     this.goToView(`/addAddress/${value._id}`)
    // }
    async getData() {
        const user = await this.userHelper.getUserById(tokenManager.user)
        const location = _.find(user.locations, { id: this.state.addressId });
        //const locationList = await this.userHelper.getLocationFormate(user)
        const fields = [
            {
                type: 'Address',
                props: {
                    name: "address",
                    address: location
                }
            },
            {
                type: 'radioGroup',
                props: {
                    displayInRow: true,
                    name: 'addressLabel',
                    required: true,
                    defaultValue: location ? location.label : "",
                    list: [{
                        label: 'Home',
                        value: 'Home',

                    },
                    {
                        label: 'Work',
                        value: 'Work',
                    },
                    {
                        label: 'Other',
                        value: 'Other',
                    }]
                }
            },
            {

                type: 'checkbox',
                props: {
                    name: 'setAsCurrentAddress',
                    label: 'Mark this as delivering address',
                    defaultValue: true
                }
            },
        ]
        this.setState({
            fields: fields,
            user : user
        })

    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch,
        UI
    }) {
        return (
            <Container
                page={{ title: this.state.addressId ? 'Edit Address' : 'Add Address', secondaryText: 'Select the address and selected address type will be uploaded accordingly' }}
            >
                <View>
                    <Layout name="address-add-container">
                        <Block name='details-block'>
                            <Form submitButton={{ label: 'save' }} onSubmit={this.OnAddNewAddress}>
                                <FormMaker fields={this.state.fields} />
                            </Form>
                        </Block>
                    </Layout>
                </View>
            </Container>
        );
    }
    getStylesheet = () => {
        return ({
            'back-button': {
                transition: 'children-slideRight'
            },
            'address-add-container': {
                transition: 'children-slideRight'
            },
            'background': {
                master: {
                    backgroundImage: `url(${require('@core/assests/images/Icons/search.png').default}`,
                    width: 75,
                    // height:100,
                    backgroundRepeat: 'no-repeat'
                }
            },
            'details-block': {
                master: {
                    margin: 'auto',
                    maxWidth: 550
                }
            }
        })
    }

}

export default toolkit.Entities.createPlace(AddAddress, {
    name: 'AddAddress'
});
