import React from "react";
import _ from "lodash";
import Entities from "@toolkit/entities";
import Helper from "@core/helpers";
import api from "@api";
import Fuse from "fuse.js";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import InventoryList from "@core/modules/Inventory/views/Things/inventoriesList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Search from "@core/assests/images/Search.png";
import Chip from "@material-ui/core/Chip";

const searchList = ["oil", "ragi", "rice", "apple"];
class SeachPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: Helper.CartHelper.getCount() || 0,
      showFlag: false,
    };
    this.shopHelper = new Helper.ShopHelper();
    this.itemHelper = new Helper.ItemHelper();
    this.inventoryHelper = new Helper.InventoryHelper();
    this.categoryHelper = new Helper.CategoryHelper();
  }

  async getData() {
    const shopDetails = await this.shopHelper.fetchMyShopDetails();
    let categories = await this.categoryHelper.getCategories();
    this.setState({
      shopDetails,
      categories,
    });
  }

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };

  onClick = (inventory, count) => {
    this.itemHelper.updateStack(inventory);
    this.setState({
      inventory: inventory,
      showFlag: true,
      count: count,
    });
  };

  onClose = () => {
    this.setState({
      showFlag: false,
    });
  };

  onChange = async (value) => {
    let searchedInventories = await api.Inventories.search(value);
    this.setState({
      searchedInventories: _.groupBy(searchedInventories, "label"),
      searchValue: value,
    });
  };

  onItemBack = () => {
    this.itemHelper.popOutStack();
    this.setState({
      inventory: this.itemHelper.getStackTop(),
      showFlag: false,
    });
  };

  onItemClick = (categoryId) => {
    this.goToView(`shop/${categoryId}`);
  };

  renderSurface(values, { MainLayout, Layout, Block, Bits, View, Switch }) {
    return (
      <MainLayout name="top-bar-container">
        <Layout name="search-top-bar-outer">
          <Layout>
            <Block name="search-header">
              {/* <Bits.Text name="search-main-text">What you're looking for?</Bits.Text> */}
              <TextField
                margin="normal"
                fullWidth
                id="search"
                placeholder="What you're looking for ?"
                name="search"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <SearchIcon
                      style={{
                        margin: "0px 7px 0px 0px",
                        color: interiorDesigner.colorPallete.subtext,
                      }}
                    />
                  ),
                }}
                onChange={_.debounce(
                  (event) => this.onChange(event.target.value),
                  1000
                )}
                style={{
                  width: "100%",
                  backgroundColor: interiorDesigner.colorPallete.bg,
                  borderRadius: 5,
                }}
                variant="outlined"
              />
            </Block>
          </Layout>
          <Layout name="search-image">
            <Block name="search-image-align">
              {_.isEmpty(this.state.searchedInventories) &&
                _.isEmpty(this.state.searchValue) && (
                  <Layout>
                    <Layout name="categories-block">
                      <Block>
                        <Bits.Text name="category-heading">
                          Browse Categories
                        </Bits.Text>
                      </Block>
                      <Block name="search-categories-container">
                        {_.map(this.state.categories, (category, index) => {
                          return (
                            <Layout>
                              <Block
                                key={index}
                                name="search-category-name-container"
                                onClick={() => this.onItemClick(category._id)}
                              >
                                <Bits.Text name="search-category-name">
                                  {_.capitalize(category.name)
                                    .split("_")
                                    .join(" ")}
                                </Bits.Text>
                              </Block>
                            </Layout>
                          );
                        })}
                      </Block>
                    </Layout>
                    {/* <Layout name="search-image">
                                            <Block name="search-image-align">
                                                {
                                                    (_.isEmpty(this.state.searchedInventories) ?
                                                        <img style={{ width: '40%', height: '40%' }} src={Search} /> : null
                                                    )
                                                }
                                            </Block>
                                        </Layout> */}
                  </Layout>
                )}
            </Block>
          </Layout>
        </Layout>
        {!_.isEmpty(this.state.searchedInventories) && (
          <Layout name="inventory-list">
            <InventoryList
              cartCount={this.state.count}
              cart={this.props.cart}
              inventory={this.state.inventory}
              onClick={this.onClick}
              showFlag={this.state.showFlag}
              onClose={this.onClose}
              onBack={this.onItemBack}
              count={this.state.count}
              inventoryHelper={this.inventoryHelper}
              groupedinventories={this.state.searchedInventories}
            />
          </Layout>
        )}
        {/* {
                    (_.isEmpty(this.state.searchValue)) ? 
                    <Layout>
                        <Block name="search-text"><Bits.Text name="search-text-header">Get going</Bits.Text></Block>
                        <Block name="search-text">
                            <Bits.Text name="search-text-para">Looks like you haven't searched for anything yet, Please try again</Bits.Text>
                        </Block>
                    </Layout>
                    :<Block name='search-text'>
                    <Layout name="search-image">
                                        <Block name="search-image-align">
                                            {
                                                (_.isEmpty(this.state.searchedInventories) ? 
                                                    <img style={{ width:'40%',height:'40%' }} src={Search} /> : null
                                                )
                                            }
                                        </Block>
                                    </Layout><Bits.Text name="search-text-header">{_.isEmpty(this.state.searchedInventories) ? 'No Results' : 'Searching...'}</Bits.Text>
                                    <Block name="search-text">
                            <Bits.Text name="search-text-para">Try something like oil/rice..</Bits.Text>
                        </Block></Block>
                } */}
      </MainLayout>
    );
  }
  getStylesheet = (colorPallete) => {
    return {
      "category-heading": {
        master: {
          fontSize: 28,
          fontWeight: 500,
          marginLeft: 10,
        },
        mobile: {
          fontSize: 24,
        },
      },
      "category-image-container": {
        master: {
          background: colorPallete.white,
          width: "100%",
          height: 90,
          borderRadius: 4,
          opacity: 0.9,
          borderRadius: 4,
          overflow: "hidden",
          minWidth: "40%",
          display: "none",
        },
        mobile: {},
      },
      // 'categories-block':{
      //     master:{
      //         margin:25
      //     }
      // },
      "search-categories-container": {
        master: {
          display: "flex",
          flexWrap: "wrap",
        },
        transition: "children-slideRight",
      },
      "search-category-name": {
        master: {
          fontSize: 14,
          margin: 10,
          padding: "10px 20px",
          borderRadius: 24,
          backgroundColor: colorPallete.secondary,
        },
        mobile: {
          margin: 8,
          fontSize: 13,
        },
      },
      "inventory-list": {
        master: {
          //marginLeft:20
        },
        mobile: {
          marginLeft: 0,
        },
      },
      "top-bar-container": {
        master: {
          margin: 30,
        },
      },
      "top-bar": {
        master: {
          display: "flex",
          width: "100%",
          padding: "10px",
          boxSizing: "border-box",
        },
      },
      "search-header": {
        master: {
          width: "70%",
          margin: "0 auto",
        },
        mobile: {
          width: "100%",
        },
      },
      "search-top-bar-outer": {
        master: {},
        mobile: {
          width: "90%",
          margin: "0 auto",
        },
      },
      "no-products-message": {
        master: {
          display: "grid",
          placeItems: "center",
          color: colorPallete.subtext,
        },
      },
      "search-main-text": {
        master: {
          color: colorPallete.primary,
          fontSize: "2.3rem",
          fontWeight: 700,
          marginBottom: 0,
        },
      },
      "search-image-align": {
        master: {
          width: 1250,
          margin: "0 auto",
        },
        mobile: {
          width: "100%",
        },
      },
      "search-text": {
        master: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      },
      "search-text-header": {
        master: {
          color: colorPallete.primary,
          fontSize: "2.3rem",
          marginBottom: 0,
          fontWeight: 700,
        },
      },
      "search-text-para": {
        master: {
          color: colorPallete.subtext,
          width: 330,
          textAlign: "center",
          fontWeight: 400,
        },
      },
      "category-name-container": {
        master: {
          margin: "0px 10px",
          padding: "0px 10px",
          maxWidth: "unset",
          minWidth: "unset",
        },
        mobile: {},
      },
      "category-name": {
        master: {
          textAlign: "center",
          // top: '50%',
          textTransform: "capitalize",
          position: "relative",
          color: colorPallete.teritary,
          fontSize: 18,
          // transform: 'translateY(-50%)',
          verticalAlign: "middle",
          fontWeight: 500,
          flex: 1,
          fontWeight: "bold",
          //margin: '25px 30px 25px 0px'
          color: "#616156",
        },
        mobile: {
          fontSize: 18,
        },
      },
      "primary-item-image": {
        master: {
          width: "auto",
          height: 100,
          display: "block",
          margin: "auto",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        },
      },
    };
  };
}

export default Entities.createPlace(SeachPage, {
  name: "SeachPage",
});

const imageSample =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&usqp=CAU";

// <Layout>
//                                         <Block>
//                                             <Bits.Text name='category-heading'>Reacent Search</Bits.Text>
//                                         </Block>
//                                         {
//                                             _.map(searchList, (item, index) =>{
//                                                 return(
//                                                     <Chip
//                                                         key={index}
//                                                         //onDelete={() => this.removeChip(index)}
//                                                         className="margin-5"
//                                                         //onChange={this.changeValue}
//                                                         //onClick={() =>this.onChange(item)}
//                                                         style={{margin:5, fontSize:18}}
//                                                         label={
//                                                             <div className="flex-center">
//                                                                 <span>{item}</span>
//                                                             </div>
//                                                     }/>
//                                                 )
//                                             })
//                                         }
//                                     </Layout>
