import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withFormsy } from 'formsy-react';
import _ from 'lodash';
import customFormHoc from './customFormHoc';

class CheckboxItem extends Component {
    constructor(props){
        super(props);
        this.props.setValue(this.props.defaultValue);
    }

  
    onChange = (e) => {
        const value = e.target.checked;
        this.props.setValue(value);
        this.props.onChange(value, e);
    }


    render(){
        return (
            <div className="amenity__item">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.value}
                            onChange={this.onChange}
                            name={this.props.name}
                            color="primary"
                            defaultChecked = {this.props.defaultValue}
                            color='secondary'
                        />
                    }
                    label={this.props.label}
                />
            </div>
        )
    }
    
}

CheckboxItem.defaultProps = {
    onChange: _.noop,
};

const nonFormsyHOC = customFormHoc(CheckboxItem);

export { nonFormsyHOC as CheckboxItem };
export default withFormsy(CheckboxItem);
