import React from "react";
import _ from 'lodash';
import Entities from '@toolkit/entities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async getData() {
        this.setState({

        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
            
        })
    }


    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }, config) {
        const shopConfig = config['shop'];
        return (
            <MainLayout name="mainlayout">
                <View landing>
                    <Layout>
                        <Block name='delivery'>
                            <Bits.Text name='common-style-heading'>Shop Address</Bits.Text>
                            <Block name='shop-address-details'>
                            <Block>
                                {
                                     _.map(shopConfig.address,address => {
                                        return (
                                            <Bits.Text name="common-style-para">{address}</Bits.Text>
                                            )
                                        })
                                }
                            </Block>
                                <a style={{
                                    backgroundColor: 'white',
                                    color: interiorDesigner.colorPallete.primary,
                                    border: `2px ${interiorDesigner.colorPallete.primary}`,
                                    //padding: '10px 20px',
                                    textAlign: 'center',
                                    textDecoration: 'none',
                                    height: 'fit-content',
                                    display: 'inline-block',
                                }}target="_blank" href={shopConfig.googleMapLink}>
                                    <Bits.Text name='open-maps'>Open maps</Bits.Text>
                                </a>
                            </Block>
                        </Block>
                        <Block name='contact-details'>
                            <Bits.Text name='common-style-heading'>Contact Details</Bits.Text>
                            <Bits.Text name='shop-address'>{shopConfig.contact.name} - {shopConfig.contact.phone}</Bits.Text>
                        </Block>
                        <Block name='contact-details'>
                            <Bits.Text name='common-style-heading'>Business Hours</Bits.Text>
                            <Bits.Text name='shop-address'>
                                {shopConfig.businessHours}<br />
                            </Bits.Text>
                        </Block>
                    </Layout>
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete, config) => {
        return ({
            'common-style-heading':{
                master: {
                    color:colorPallete.primary,
                    fontSize:16,
                    fontWeight:600,
                    margin:'20px 0px 10px',
                    textTransform: 'uppercase'
                }
            },
            'common-style-para':{
                master:{
                    margin:0,
                    fontSize:15,
                    textAlign:'justify'
                },
                'name':{
                    mobile:{
                        width:300
                    }
                }
            },
            'contact-details':{
                master:{
                    paddingTop:10,
                }
            },
            'open-maps':{
                master:{
                    margin:5,
                    fontSize:15
                }
            },
            'shop-address':{
                master:{
                    fontSize:12,
                    margin:0,
                }
            },
            'shop-address-details':{
                master:{
                    display:'flex',
                    justifyContent:'space-between',
                }
            },
            'delivery-details-header':{
                master:{
                    color:colorPallete.primary,
                    fontSize:20,
                    margin:0,
                    marginBottom: 5
                }
            },
            'top-bar':{
                master:{
                    display:'flex',
                    marginBottom:30
                }
            },
            'mainlayout':{
                master:{
                    margin:'30px 30px'
                }
            },
            'top-bar-title':{
                master:{
                    margin:'0px 10px',
                    fontSize:22,
                }
            },
            'logo': {
                master: {
                    backgroundImage: `url(${config.shop.logo})`,
                    width: '100%',
                    // height:100,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    height: 290,
                    marginTop:-30
                }
            },
        })
    }
}

export default Entities.createPlace(ContactUs, {
    name: 'ContactUs'
});

