const colorPallete = {
  // primary: '#735340',
  primary: "#0B2517",
  secondary: "#735340",
  teritary: "#05C2B8",
  exclusive: "#FFD793",
  subtext: "#9c9898",
  darkGrey: "#4F4F4F",
  yellow: "#fecb6e",
  black: "#333",
  white: "#fff",
  successGreen: "#54B749",
  accent: "#FBF7EF",
  bg: "#fff",
  backLinkTextColor: "#D03C3C",
  brown: "#b97549",
};

const imageThumbnailSizePallete = {
  50: {
    width: 50,
    height: 50,
  },
  100: {
    width: 100,
    height: 100,
  },
  200: {
    width: 200,
    height: 200,
  },
  300: {
    width: 300,
    height: 300,
  },
  400: {
    width: 400,
    height: 400,
  },
  500: {
    width: 500,
    height: 500,
  },
};

const overridingStylesheet = {
  "app-bottom-nav": {
    master: {
      root: {
        height: 70,
        backgroundColor: colorPallete.bg,
        bottom: 20,
        left: "50%",
        transform: "translateX(-50%)",
        boxShadow:
          "rgb(0 0 0 / 10%) 0px 19px 38px, rgb(0 0 0 / 12%) 0px 15px 12px",
        borderRadius: 6,
        flexDirection: "row",
      },
      action: {
        root: {
          borderRadius: 3,
          padding: "0px 20px !important",
          maxWidth: "unset",
          minWidth: "unset",
        },
        selected: {
          background: colorPallete.primary,
          color: "#FFD793 !important",
        },
      },
    },
    mobile: {
      root: {
        width: 210,
        marginBottom: 0,
        height: "70px !important",
        flexDirection: "row",
        bottom: 20,
        borderRadius: 6,
      },
      action: {
        root: {
          borderRadius: 3,
          minWidth: "70px !important",
          minHeight: 70,
          margin: "0 !important",
          padding: "unset !important",
        },
      },
    },
  },
  "main-layout": {
    master: {
      backgroundColor: colorPallete.white,
      maxWidth: 1200,
      margin: "0 auto",
    },
  },
  "shop-door": {
    master: {
      backgroundColor: colorPallete.primary,
    },
  },
  "SimpleNav.top-bar-outer": {
    master: {
      borderBottom: `1px solid ${colorPallete.primary}`,
    },
  },
  "top-bar": {
    master: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
    },
    mobile: {
      position: "relative",
      marginLeft: 0,
      left: "unset",
      transform: "unset",
    },
  },
  "top-bar-container.logo": {
    master: {
      backgroundImage: `url(${require("../images/logo.png").default})`,
    },
    mobile: {
      width: 44,
      height: 44,
    },
  },
  "top-bar-container.shop-title": {
    master: {
      // backgroundImage: `url(${require('../images/name1.png').default})`,
      width: 149,
      height: 55,
      marginTop: 10,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      color: "transparent",
    },
    mobile: {
      width: 120,
      height: 47,
    },
  },
  "FrontWall.shop-banner": {
    master: {
      display: "flex",
      alignItems: "center",
    },
  },
  "nav-icons": {
    master: {
      backgroundColor: colorPallete.bg,
    },
  },
  "shop-title": {
    master: {
      color: colorPallete.primary,
      fontFamily: "cursive",
    },
    mobile: {
      fontSize: 22,
    },
  },
  "shop-caption": {
    master: {
      display: "none",
    },
  },
  "out-of-stock": {
    master: {
      borderRadius: 3,
      padding: "5px 0px",
      textAlign: "center",
      background: "#cc8834",
      textTransform: "uppercase",
      fontSize: 12,
      margin: "10px 0px 5px 0px",
      color: "#fefefc",
      fontWeight: 600,
    },
  },
  "FrontWall-mainlayout": {
    master: {
      backgroundColor: colorPallete.bg,
      margin: 0,
    },
  },
  "section-navigation": {
    master: {
      background: "unset",
      padding: "30px 0px 20px",
    },
    mobile: {
      padding: "20px 15px 30px",
    },
  },
  "cart-layout": {
    mobile: {
      padding: "0px 15px 70px",
    },
  },
  "primary-shelf-block": {
    mobile: {
      height: "calc(100vh - 170px)",
    },
  },
  "front-courousel": {
    master: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      height: "100%",
      maxWidth: "80%",
      justifyContent: "space-evenly",
    },
    mobile: {
      flexDirection: "column",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "90%",
      margin: "0px auto",
      width: "90%",
    },
  },
  "priamry-image-block": {
    master: {},
    mobile: {
      boxShadow: "rgb(0 0 0 / 6%) 0px 2px 4px 0px inset",
      width: "100%",
      height: "300px",
      padding: "unset",
    },
  },
  "grid-block-more": {
    master: {
      color: colorPallete.teritary,
    },
  },
  "studio-link": {
    master: {
      width: "20%",
      backgroundColor: "none",
    },
    mobile: {
      width: "80%",
    },
  },
  "counter-block": {
    master: {
      marginRight: 0,
    },
    mobile: {
      padding: "10px 0px",
      marginRight: "unset",
      margin: "0 auto",
      alignItems: "center",
    },
  },
  "powered-by": {
    master: {
      color: "#d4cfcf",
    },
  },
  "search-category-name": {
    master: {
      backgroundColor: colorPallete.accent,
    },
  },
  more: {
    master: {
      fontWeight: 600,
    },
    mobile: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  "front-arrow": {
    master: {
      background: colorPallete.teritary,
      border: "none",
      borderRadius: 4,
    },
  },
  "view-more-icon": {
    master: {
      background: colorPallete.teritary,
      color: colorPallete.white,
      borderRadius: 20,
    },
  },
  "FrontWall.primary-shelf": {
    master: {
      backgroundImage: `url(${require("../images/mockup.png").default})`,
      backgroundColor: colorPallete.bg,
      margin: "0px 0px 60px 0px",
      borderRadius: 2,
      padding: 0,
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    mobile: {
      margin: 0,
      padding: 0,
      backgroundImage: "unset",
    },
  },
  "FrontWall.primary-shelf.name": {
    master: {
      color: colorPallete.primary,
    },
  },
  "FrontWall.grid-block-text": {
    master: {
      color: colorPallete.primary,
      fontWeight: "bold",
      fontSize: 18,
      fontWeight: 600,
      color: "#333",
      padding: "14px 0px",
      borderBottom: "1px solid #b9b7b7",
    },
    mobile: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  footer: {
    master: {
      padding: "50px 0px",
    },
  },
  "grid-blocks": {
    master: {
      background: colorPallete.accent,
      padding: 0,
    },
  },
  "grid-block-price": {
    master: {
      margin: 0,
      fontSize: 20,
      //padding: '10px 0px',
      fontWeight: 300,
    },
    mobile: {
      fontSize: 18,
    },
  },
  "FrontWall.block-title": {
    master: {
      color: colorPallete.black,
      fontWeight: "bold",
      fontSize: 24,
      letterSpacing: 0.3,
      lineHeight: "32px",
      textTransform: "uppercase",
      fontFamily: "unset",
      margin: 0,
    },
    mobile: {
      margin: "5px 0px",
    },
  },
  "FrontWall.board-layout.blocks.1.DisplayBoard.primary-shelf.layouts.2.layouts.2.name":
    {
      master: {
        fontSize: 22,
        fontWeight: "bold",
      },
    },
  "FrontWall.board-layout.blocks.1.DisplayBoard.grid-block-text": {
    master: {
      fontSize: 24,
      fontWeight: 400,
    },
  },
  "counter-outline": {
    master: {
      border: 0,
    },
  },
  "block-header": {
    master: {
      borderBottom: "2px solid #333",
      fontSize: 16,
      padding: "5px 0px",
    },
    mobile: {
      padding: 0,
      margin: "10px 15px",
    },
  },
  "primary-item-name-block": {
    master: {
      width: "60%",
      marginLeft: 0,
      padding: "20px 0px",
    },
    mobile: {
      marginTop: 20,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
  },
  "image-block": {
    master: {
      objectFit: "cover",
      padding: 0,
      borderRadius: 4,
    },
    mobile: {
      objectFit: "cover",
    },
  },
  "primary-block-title": {
    master: {
      fontWeight: 400,
      fontSize: 16,
      background: "unset",
      padding: "10px 0px 5px 0px",
      letterSpacing: 1,
      color: colorPallete.teritary,
    },
    mobile: {
      padding: 0,
      marginTop: 10,
      width: "auto",
      padding: "5px 20px",
      color: colorPallete.white,
      background: colorPallete.brown,
    },
  },
  "secondary-info-block": {
    master: {
      background: colorPallete.accent,
      margin: "40px 20px 20px 0px",
      padding: 40,
    },
    mobile: {
      padding: "unset",
    },
  },
  "grid-block-image": {
    master: {
      width: 220,
      height: 220,
      padding: 0,
      background: "unset",
    },
    mobile: {
      width: 150,
      height: 150,
      padding: "unset",
    },
  },
  "Profile.profile-name": {
    master: {
      color: colorPallete.primary,
    },
  },
  "top-bar-outer": {
    master: {
      boxShadow: "0 6px 6px -8px #333",
      borderBottom: "unset",
    },
  },
  "Profile.title": {
    master: {
      color: colorPallete.primary,
    },
  },
  "InventoriesList.items-layout": {
    master: {
      padding: "10px 0px 100px",
    },
    mobile: {
      margin: "0px 10px",
    },
  },
  "product-price": {
    master: {
      color: colorPallete.teritary,
    },
  },
  "category-header": {
    master: {
      fontSize: 45,
      fontWeight: 500,
    },
    mobile: {
      fontSize: 28,
      fontWeight: 600,
    },
  },
  description: {
    master: {
      color: colorPallete.primary,
      width: 500,
      fontSize: 18,
      lineHeight: "24px",
      letterSpacing: 0.3,
      opacity: 0.7,
    },
    mobile: {
      fontSize: 14,
      lineHeight: "22px",
      textAlign: "center",
      color: colorPallete.primary,
    },
  },
  name: {
    master: {},
    mobile: {
      textAlign: "center",
      fontSize: 30,
      fontWeight: 500,
      margin: "0px",
      padding: "12px 0px 0px",
    },
  },
  "product-description": {
    master: {
      fontSize: 13,
      //lineHeight: '15px',
      //padding: '10px 0px'
    },
  },
  "categories-container": {
    master: {
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      padding: 0,
    },
    mobile: {
      width: "calc(100% - 30px)",
      gap: 15,
      margin: "0px 15px 40px",
    },
  },
  "Categories.category-block-layout": {
    master: {
      position: "relative",
      justifyContent: "space-between",
      width: 260,
      height: 130,
      margin: 20,
      borderRadius: 4,
      background: colorPallete.black,
      boxShadow:
        "rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px",
    },
    mobile: {
      width: "unset",
      height: "auto",
      background: colorPallete.black,
      flexDirection: "column",
      margin: 0,
    },
  },
  "FrontWall.category-block-layout": {
    master: {
      width: 180,
      height: 260,
      margin: 0,
    },
    mobile: {
      width: "100%",
      height: "auto",
    },
  },
  "primary-item-image": {
    mobile: {
      width: "100%",
    },
  },
  "category-name-container": {
    master: {
      padding: 0,
      position: "absolute",
      bottom: "-5%",
      left: "8%",
    },
    mobile: {
      bottom: "0%",
      left: "6%",
    },
  },
  "categories-list.categories-container": {
    master: {
      gridTemplateColumns: "repeat(auto-fill, minmax(190px, 1fr))",
      padding: "50px 0px",
    },
    mobile: {
      padding: "30px 0px",
    },
  },
  "category-image-container": {
    master: {
      height: "100%",
      opacity: 0.8,
    },
    mobile: {
      height: 220,
      marginRight: 0,
      maxWidth: "100%",
      background: colorPallete.black,
    },
  },
  "info-title": {
    master: {
      fontSize: 38,
      lineHeight: 1.3,
      color: colorPallete.primary,
    },
    mobile: {
      fontSize: 34,
      margin: "auto",
    },
  },
  "info-text": {
    master: {
      opacity: 0.9,
      color: colorPallete.primary,
    },
    mobile: {
      fontSize: 14,
    },
  },
  "coupon-showcase": {
    master: {
      margin: "0 auto",
      maxWidth: "80%",
    },
  },
  "category-name": {
    master: {
      color: colorPallete.white,
      textTransform: "capitalize",
      fontSize: 22,
      overflow: "hidden",
      fontWeight: "bolder",
      marginLeft: 0,
      textOverflow: "ellipsis",
      textAlign: "left",
    },
    mobile: {
      fontSize: 20,
    },
  },
  "menulist-block": {
    master: {
      overflowX: "scroll",
    },
  },
  "stack-indicator": {
    master: {},
    mobile: {
      paddingBottom: "unset",
      marginTop: "unset",
      borderBottom: "unset",
    },
  },
  name: {
    master: {
      fontSize: 58,
      lineHeight: "72px",
      fontWeight: 500,
      padding: "0px 0px 5px 0px",
      color: colorPallete.primary,
      letterSpacing: 0.3,
    },
    mobile: {
      fontSize: 38,
      lineHeight: "50px",
      padding: "5px 0px 5px",
    },
  },
  "inventory-card-image": {
    master: {
      width: "100%",
      maxWidth: "unset",
      height: 200,
    },
    mobile: {
      height: "100%",
    },
  },
  "grid-block-text": {
    master: {},
    mobile: {
      margin: "0px 0px",
      fontSize: 18,
      fontWeight: 500,
      padding: 10,
    },
  },
  "item-modal-main-item-image": {
    master: {
      width: "100%",
      height: "100%",
    },
  },
  "section-tabs": {
    master: {
      position: "fixed",
      top: 300,
      marginTop: "unset",
    },
    mobile: {
      position: "unset",
      paddingBottom: 0,
    },
  },
  "section-inventories": {
    master: {
      marginLeft: "unset",
    },
  },
  "inventory-details": {
    master: {
      maxWidth: 180,
    },
    mobile: {
      maxWidth: 165,
    },
  },
  "item-image-container": {
    master: {
      width: "33%",
      width: 363,
      height: 363,
      // height: '100%'
    },
    mobile: {
      width: "100%",
      padding: 0,
      height: "auto",
      background: colorPallete.white,
    },
  },
  "name-price": {
    master: {
      flexDirection: "unset",
      alignItems: "flex-end",
    },
    mobile: {
      alignItems: "center",
    },
  },
  "product-name": {
    mobile: {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  "product-price": {
    master: {
      marginTop: "unset",
    },
    mobile: {
      fontSize: 22,
      fontWeight: 400,
    },
  },
  "stock-name": {
    mobile: {
      position: "absolute",
      fontSize: 17,
      color: colorPallete.white,
      top: 80,
      right: 0,
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      padding: "5px 20px",
      backgroundColor: colorPallete.teritary,
    },
  },
  inventory_list: {
    mobile: {
      paddingLeft: 0,
      padding: "20px 0px 10px 20px",
    },
  },
  "price-table": {
    mobile: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: 0,
    },
  },
  "detail-row": {
    mobile: {
      margin: "2px 0px",
    },
  },
  "arrow-overlay": {
    mobile: {
      top: 20,
      borderRadius: "50%",
      padding: 10,
      background: "#efefef",
    },
  },
  "modal-arrow-overlay": {
    mobile: {
      top: 100,
      borderRadius: "50%",
      padding: 15,
      left: "50%",
      background: "#efefef",
    },
  },
  "counter-outline-add": {
    master: {
      width: "unset",
      background: colorPallete.bg,
      boxShadow: "rgb(33 35 38 / 10%) 0px 10px 10px -10px",
      width: "unset",
      borderRadius: 0,
      padding: "4px 25px",
      background: colorPallete.primary,
    },
    mobile: {
      width: "100%",
      padding: "5px 20px",
      borderRadius: 4,
    },
  },
  rates: {
    mobile: {
      marginBottom: 20,
    },
  },
  "counter-button": {
    master: {
      color: colorPallete.white,
      margin: 4,
      fontSize: 15,
      fontWeight: 500,
    },
    mobile: {
      fontSize: 18,
      fontWeight: "bold",
    },
  },
  "card-title": {
    master: {
      fontSize: 18,
      letterSpacing: 0.4,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    mobile: {
      fontWeight: 600,
    },
  },
  "item-details": {
    master: {
      padding: "10px 5px 0px 5px",
      background: colorPallete.accent,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
    },
    mobile: {
      padding: "10px 0px 0px",
    },
  },
  "price-block": {
    master: {
      padding: "10px 5px",
      width: "unset",
    },
    mobile: {
      flexDirection: "column",
      alignItems: "unset",
      padding: "5px 0px",
    },
  },
  item: {
    master: {
      width: "unset",
    },
    mobile: {
      width: "100%",
    },
  },
  "inventory-card-image-container": {
    master: {
      marginBottom: -20,
      zIndex: 0,
    },
    mobile: {
      width: 200,
      height: 200,
    },
  },
  "item-details-container": {
    master: {
      background: "#fbf7ef",
      padding: 10,
      borderRadius: 10,
      margin: "-10px 0px 0px 0px",
    },
  },
  "items-grid-container": {
    master: {
      gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
    },
    mobile: {
      gridTemplateColumns: "repeat(auto-fill, minmax(calc(50vw - 20px), 1fr))",
      gap: 10,
    },
  },
  moreInfo: {
    master: {
      width: 95,
      color: colorPallete.primary,
      fontSize: 16,
      fontWeight: 500,
      borderRadius: 2,
      margin: "40px 0px 10px",
      letterSpacing: 1,
      border: `1px solid ${colorPallete.primary}`,
      padding: "10px 20px",
    },
    mobile: {
      width: 84,
      fontSize: 14,
      margin: "20px 0px 10px",
      color: colorPallete.white,
      backgroundColor: colorPallete.primary,
      boxShadow:
        "rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px",
    },
  },
  "info-block": {
    master: {
      background: "#f1e8db",
      padding: 40,
      margin: "30px 30px 30px 0px",
    },
    mobile: {
      background: "unset",
      padding: "unset",
    },
  },
  "info-block-container": {
    master: {
      background: "unset",
    },
    mobile: {
      margin: "unset",
    },
  },
  "ItemModal.basic-info.blocks.4": {
    master: {
      padding: "10px 0px",
    },
    mobile: {
      padding: 15,
    },
  },
  "details-heading": {
    master: {
      color: colorPallete.darkGrey,
      lineHeight: "15px",
      margin: 0,
    },
  },
  "details-title": {
    master: {
      fontWeight: "bold",
      color: colorPallete.darkGrey,
      lineHeight: "15px",
      margin: 0,
    },
  },
  "details-text": {
    master: {
      color: colorPallete.darkGrey,
    },
  },
  header: {
    mobile: {
      fontSize: 28,
      lineHeight: 1.5,
      fontWeight: 600,
      marginRight: "unset",
    },
  },
  "item-price": {
    master: {
      fontSize: 20,
    },
  },
  charges: {
    master: {},
  },
  "info-text": {
    master: {
      fontSize: 17,
      color: colorPallete.primary,
      whiteSpace: "pre-line",
      opacity: 0.9,
    },
  },
  "item-modal-layout": {
    master: {
      maxWidth: "100%",
      justifyContent: "space-evenly",
    },
    mobile: {
      background: "#fbf7ef",
    },
  },
  "product-details": {
    mobile: {
      margin: 0,
    },
  },
  "inventory-list-card": {
    master: {
      width: "unset",
      margin: "0px 30px 30px 0px",
    },
    mobile: {
      width: 200,
      margin: 0,
      padding: "0px 10px",
    },
  },
  "similar-bar": {
    mobile: {
      margin: "40px 0px",
    },
  },
  "similar-bar-title": {
    mobile: {
      marginLeft: 15,
      fontSize: 17,
    },
  },
  "delivery-charge": {
    master: {
      color: colorPallete.darkGrey,
      fontSize: 14,
    },
  },
  "charge-price": {
    master: {
      color: colorPallete.darkGrey,
      fontSize: 16,
    },
  },
  "mobile-entry": {
    mobile: {
      marginTop: 0,
    },
  },
  "signin-bg": {
    master: {
      width: 350,
      bottom: 0,
      backgroundImage: `url(${require("../images/login.png").default})`,
    },
    mobile: {
      width: 300,
      height: 350,
      right: -35,
      bottom: -5,
      top: "unset",
      position: "fixed",
    },
  },
  "signin-close": {
    master: {
      background: "#efefef",
      borderRadius: "50%",
    },
  },
  login: {
    master: {
      maxWidth: "100%",
    },
    mobile: {
      padding: "50px 20px 0px",
    },
  },
  "login-message": {
    master: {
      display: "flex",
      alignItems: "center",
    },
  },
  "login-message-text": {
    master: {
      fontSize: 14,
    },
  },
  "location-title": {
    master: {
      fontSize: 12,
      textTransform: "uppercase",
    },
  },
  "logo-text-block": {
    master: {
      backgroundImage: `url(${require("../images/compactName.png").default})`,
      width: 140,
      height: 53,
      marginTop: 10,
    },
    mobile: {
      width: 100,
      height: 40,
      marginLeft: 2,
    },
  },
  "cart-header-text": {
    master: {
      fontSize: 28,
      fontWeight: 500,
      marginRight: 12,
    },
  },
  review: {
    master: {
      fontSize: 24,
      fontWeight: 300,
      lineHeight: "36px",
      fontFamily: "unset",
      fontStyle: "italic",
      WebkitFontSmoothing: "auto",
    },
    mobile: {
      fontSize: 20,
      lineHeight: "30px",
    },
  },
  reviewer: {
    master: {
      fontSize: 16,
      color: "#d69708",
      fontWeight: 400,
    },
  },
  "testimonial-details": {
    master: {
      maxWidth: "80%",
    },
  },
  "discount-badge": {
    master: {
      borderRadius: 4,
      padding: 8,
      borderBottomLeftRadius: 5,
    },
    mobile: {
      left: 15,
    },
  },
};

const shopConfig = {
  apiKey: "AIzaSyBUadOWIn3hvodskPbViTVIetjdf15Y0UY",
  authDomain: "ebr-shopee.firebaseapp.com",
  projectId: "ebr-shopee",
  storageBucket: "ebr-shopee.appspot.com",
  messagingSenderId: "966181549394",
  appId: "1:966181549394:web:3454c06001e74b28a8368b",
  measurementId: "G-5DLGCEJZGE",
};

const firebaseConfig = {
  apiKey: "AIzaSyCvOzlss2rjFrdOhIQqdrwkGBJNdVo_5yo",
  authDomain: "deipara-9eec3.firebaseapp.com",
  projectId: "deipara-9eec3",
  storageBucket: "deipara-9eec3.appspot.com",
  messagingSenderId: "876694175219",
  appId: "1:876694175219:web:bf0923e07fce8353572686",
  measurementId: "G-J5BHNR4ES1",
};

const shop = {
  name: "Deipara",
  description: "Wholesale & Retail Dry Fruits & Spicies",
  about: {
    text: `Journey of Deipara began amid pandemic with an intent to deliver Quality dry fruits and spices at most affordable price. Our sourcing of products are mostly from farmers and leading distributors of the state to ensure the best price is passed to consumers.\n
        We are confident of reaching new milestones in this path. We require your support to achieve!!!\n
        Thank you for sparing your time … Best Quality – Super Price..`,
  },
  compactLogo: `${require("../images/logo.png").default}`,
  logoText: `${require("../images/compactName.png").default}`,
  logo: `${require("../images/logo-with-tag.jpg").default}`,
  // bankaccount: {
  //     razorpayAccountId: 'acc_GhQkSv405svGMb',
  //     branch: "Axis bank LTD, indiranagar, Bengaluru",
  //     name: "DEIPARA TRADING PVT LTD"
  // },
  googleMapLink:
    "https://www.google.com/maps/place/Deipara+Dry+Fruits,+Spices+%26+More/@12.97136,77.6562172,15z/data=!4m5!3m4!1s0x0:0xd5cc593839593ac6!8m2!3d12.9714958!4d77.6562339",
  address: [
    "Deipara Flagship Store",
    "12/2 A2",
    "New Thippassandra main road",
    "Bangalore",
    "Karnataka 560075",
  ],
  // categoryBgImage: require('../images/category-bg.png').default,
  contact: {
    name: "Anthony Raj",
    phone: "8088502233",
    email: "deipara456@gmail.com",
  },
  businessHours: "Mon - Sat: 10:30am - 9pm Sun: 5:30pm - 9pm",
  webAddress: "www.thedeipara.com",
  // referrals: {
  //     inviteSMS: {
  //         title: 'Refer now & avail discounts on Nature for Future',
  //         text: 'Invite your friends to avail discount of FLAT 3% OFF on Nature for Future. ',
  //         url: 'www.thedeipara.com'
  //     },
  //     profileBlock: {
  //         description: 'Invite your friends to avail discount of FLAT 3% OFF on Nature for Future.',
  //         detail: ' Your friend gets a Coupon worth 3% FLAT OFF on using your referral code while signup. You would get Coupon worth same on first order of your friend.',
  //     },
  //     featuredImage: 'https://firebasestorage.googleapis.com/v0/b/nature-for-future.appspot.com/o/refer.png?alt=media&token=00a15103-487d-405a-a5c8-a6a14e0f3f59',
  // },
  socialmedialinks: {
    instagram: "https://www.instagram.com/deipara2020",
  },
  testimonials: [
    {
      name: "Ravishankar Rao",
      descr:
        "This is the best place to get fresh and quality dry fruits at a reasonable price. They do courier their orders to far away parts of the country.",
    },
    {
      name: "Janet raj",
      descr:
        "The place has very good quality of dry fruits And the price is very reasonable compared to other dry fruit shops in new thippsandra.",
    },
    {
      descr:
        "Awesome collection and variety of dry fruits. Fresh and reasonably priced. Must check out place",
      name: "Harish babu",
    },
    {
      name: "Soundarya Devatha",
      descr:
        "The DEIPARA store is located in new thippasandra, they have very good varieties of dry fruits collection with a very reasonable, affordable,well within the reach of a common man`s pocket who can definitely buy a dry fruits to his family on regular basis. With an assured quality of dry fruits!!!!",
    },
    {
      descr:
        "Excellent service and timely delivered, wide range of dry fruits and nuts, prices are low compared to other stores, dates and Gulkand very tasty. Thanks to Deipara.",
      name: "Jagadish Gk",
    },
    {
      descr:
        "Very good assortment, best quality, best price, neat ambiance. Must visit store for all dry fruits and spices",
      name: "praveen nazare",
    },
    {
      descr:
        "Excellent assortment of dry fruits, very reasonable compares to other fancy stores around Indiranagar.",
      name: "Narendra Babu",
    },
  ],
  companyName: "613f67e851dec37cee5f9fa5",
  quantity: "613f682451dec37cee5f9fa6",
};

const config = {
  development: {
    serverURL: "https://cloud.thedeipara.com",
    //serverURL: "http://localhost:5000",
    //serverURL: "http://34.93.118.183:83",
    shopId: "613746ebf48bfececeec2a1e",
    sentry:
      "https://68c01d955a124caba504e4d55acc3631@o963101.ingest.sentry.io/5911275",
    // logrocket : "ehpfzt/e-shops",
    razorpay: {
      key_id: "rzp_live_y8PZvo5mXlZDvl",
      key_secret: "Rr7AWv6V4ycHBaMQbSVtgRcO",
    },
    overridingStylesheet,
    colorPallete,
    shop,
    firebase: {
      shop: shopConfig,
      firebaseConfig,
    },
    imageThumbnailSizePallete,
    showCartInFrontWall: true,
  },
  production: {
    serverURL: "https://cloud.thedeipara.com",
    shopId: "613746ebf48bfececeec2a1e",
    sentry:
      "https://68c01d955a124caba504e4d55acc3631@o963101.ingest.sentry.io/5911275",
    logrocket: "ehpfzt/e-shops",
    razorpay: {
      key_id: "rzp_live_y8PZvo5mXlZDvl",
      key_secret: "Rr7AWv6V4ycHBaMQbSVtgRcO",
    },
    overridingStylesheet,
    colorPallete,
    shop,
    firebase: {
      shop: shopConfig,
      firebaseConfig,
    },
    imageThumbnailSizePallete,
    showCartInFrontWall: true,
  },
};

export default config;
