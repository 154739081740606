import logrocket from 'logrocket';
import config from '@config/index';
import _ from 'lodash';
import sentry from './sentry';

class Logrocket {

    constructor() {
        this.log = logrocket;
    }
    
    wakeup = () => {
        if(config.getConfig('logrocket')){
            logrocket.init(config.getConfig('logrocket'))
            this.log.getSessionURL(sessionURL => {
                sentry.trace.configureScope(scope => {
                    scope.setExtra("sessionURL", sessionURL);
                });
        });
        }
    }
}

export default new Logrocket()
