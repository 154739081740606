import superagent from 'superagent';
import errorHandler from './errorHandler';
import tokenManager from './tokenManager';
import config from '@config/index';

const responseBody = res => {
    return res.body;
}

const responseHandler = (options) => {
    return options.responseHandler || responseBody;
}

const getUrl = url => `${config.api.baseUrl}/${url}`;

const request = {
    get: (url, data, options = {}) => {
        return (
            superagent
            .get(options.absoluteUrl ? url : getUrl(url))
            .use(tokenManager.handler)
            .then(responseHandler(options))
        );
    },
    post: (url, data, options = {}) => {
        return (
            superagent
            .post(getUrl(url))
            .send(data)
            .use(tokenManager.handler)
            .then(responseHandler(options), errorHandler(options.errorHandler))
        )
    },
    patch: (url, data, options = {}) => {
        return (
            superagent
            .patch(getUrl(url))
            .send(data)
            .use(tokenManager.handler)
            .then(responseHandler(options), errorHandler(options.errorHandler))
        )
    },
};

export default request;
