import React from 'react';
import Form from '@web-bricks/Shared/form/components/form';
import FormMaker from '@web-bricks/Shared/form/components/formMaker';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Helper from '@core/helpers';
import transitionRegistry from '@toolkit/lib/transitionRegistry';
import firebase from '@firebaseConnect';
import Container from '@core/modules/shared/views/Things/container.js';
import DialogBox from '@core/modules/shared/views/Things/dialogBox';
import { Button } from '@material-ui/core';

class AddCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryId: this.props.match.params._id,
            showDeleteAlert:false
        };
        this.categoryHelper = new Helper.CategoryHelper()
        this.inventoryHelper = new Helper.InventoryHelper()
    }

    async getData() {
        let inventoriesLinkedWithCategory = this.state.categoryId ? await this.inventoryHelper.getInventoriesCount(this.state.categoryId) : null
        let categoryDetails = this.state.categoryId ? await this.categoryHelper.getById(this.state.categoryId) : null;
        const fields = [
            {
                type: 'FirebaseFileUpload',
                props: {
                    mediaHelper: Helper.MediaHelper,
                    name: 'image',
                    getFirebase: () => firebase.shop,
                    defaultValue: this.state.categoryId ? categoryDetails.image : null
                }
            },
            {
                type: "textField",
                props: {
                    name: "name",
                    label: "Category name",
                    required: true,
                    defaultValue: this.state.categoryId ? categoryDetails.name : ''
                }
            },
            {
                type: 'textArea',
                props: {
                    name: `description`,
                    label: "Description",
                    rows:"4",
                    placeholder:"description",
                    defaultValue: this.state.categoryId ? categoryDetails.description : ''
                }
            },
            {
                type: 'checkbox',
                props: {
                    name: 'isIncluded',
                    label: 'Include',
                    defaultValue: true
                }
            }
        ]
        this.setState({
            fields: fields,
            inventoryCount : inventoriesLinkedWithCategory
        })
    }

    getRenderItems = (Block, Bits) => {

        return (
            <Block name='delete-message'>
                {
                    this.state.inventoryCount > 0 ? 
                    <Bits.Text name='caution-text'>{` ${this.state.inventoryCount} inventories are involved with this category, move them to another category and proceed.`}</Bits.Text> :
                    <Block>
                        <Button variant="contained" color="primary"  onClick={this.deleteCategory} style={{ margin: 'auto' }}>delete</Button>
                    </Block>
                }
            </Block>
        )
    }

    deleteCategory = async () =>{
        await this.categoryHelper.updateIsDeleted(this.state.categoryId)
        this.props.history.push('/vendor/sortCategories')
    }

    onSubmit = async (values) => {
        if (!_.isEmpty(this.state.categoryId)) {
            await this.categoryHelper.editCategory(values, this.state.categoryId)
            this.props.history.push('/vendor/sortCategories')
        } else {
            await this.categoryHelper.addCategory(values)
            this.props.history.push('/vendor/sortCategories')
        }
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
        })
    }

    oncategoryDelete = () =>{
        this.setState({
            showDeleteAlert: true
        })
    }

    goBack = () =>{
        this.setState({
            showDeleteAlert : false
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        return (
            <Container
                page={{ title: _.isEmpty(this.state.categoryId) ? 'Add Category' : 'Edit Category' }}
                previousPage={[{ title: 'Categories', onClick: () => this.props.history.push('/vendor/sortCategories') }]}
                activePage={_.isEmpty(this.state.categoryId) ? 'Add' : 'Edit'}
            >
                <View>
                    <Layout name="add-category-layout">
                        <Block name="form-layout">
                            <Form submitButton={{ label: _.isEmpty(this.state.categoryId) ? 'add' : "edit", variant: "contained", color: "primary", fullWidth: true, className: "add-submit-button" }} noValidate onSubmit={this.onSubmit} secondaryButton={{label:'delete', onClick:this.oncategoryDelete, fullWidth: true,className: "add-submit-button"}}>
                                <FormMaker fields={this.state.fields} Transition={transitionRegistry.slideDown} />
                            </Form>
                        </Block>
                    </Layout>
                    {
                        this.state.showDeleteAlert && <DialogBox open={this.state.showDeleteAlert} renderItem={() => this.getRenderItems(Block, Bits)} handleClose={this.goBack} heading= {this.state.inventoryCount ?"Caution" : 'Are you sure you want to delete this category?'} style={{color:this.state.inventoryCount ?'red':'black', fontSize:24,margin:0,fontWeight:600 }}/>
                    }
                </View>
            </Container>
        )
    }
    getStylesheet = (colorPallet) => {
        return ({
            'caution-text':{
                master:{
                    color:'red',
                    margin:0
                }
            },
            'form-layout': {
                master: {
                    width: 350,
                    margin: "10px auto 90px",
                    paddingBottom:100
                }
            },
            'top-bar': {
                master: {
                    // position: 'absolute',
                    // top: '10%',
                    // left: '10%',
                    margin: 20,
                    color: colorPallet.teritary,
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: 18,
                    fontWeight: 400,
                    cursor: 'pointer',
                }
            },
            'header': {
                master: {
                    margin: 0,
                    marginLeft: 5,
                    fontSize: 20,
                    fontWeight: 600
                }
            },
            'add-inventory-layout': {
                master: {
                    display: 'flex',
                    flexDirection: 'column',

                },
                mobile: {
                }

            },
            'add-category-layout': {
                master: {
                    //margin: '30px auto',
                    marginBottom:100
                }
            },
            'header-layout': {
                master: {
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 24
                },
            },
        })
    }
}

export default Entities.createPlace(AddCategories, {
    name: 'AddCategories'
});