import React from "react";
import _ from "lodash";
import Entities from "@toolkit/entities";
import Grid from "@material-ui/core/Grid";
import ItemModal from "@core/modules/Inventory/views/Things/itemModal";
import CouponShowcase from "@core/modules/shared/views/Things/couponShowcase";
import Helper from "@core/helpers";
import ArrowForwardTwoToneIcon from "@material-ui/icons/ArrowForwardTwoTone";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import TaggedInventories from "@core/modules/Shop/views/Places/taggedInventories";
import InfoBlock from "@core/modules/shared/views/Things/infoBlock";
import SliderComp from "@web-bricks/Shared/Image/slidercomp";
import MenuList from "@core/modules/shared/views/Things/menuList.js";
import InventoryMedia from "@core/modules/Inventory/views/Pieces/inventoryMedia";
import config from "@config";
import Counter from "@core/modules/shared/views/Things/counter";
import withCart from "@core/modules/Cart/views/lib/withCart";


class SecondarySelfItem extends React.Component {

  constructor(props) {
    super(props);
  }

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    Bits
  }) {
    const { inventory } = this.props;
    return (
      <MainLayout>

        <Layout
          name="secondary-info-block"
          onClick={this.expandView}
        >
          <Block name="grid-block-image">
            <InventoryMedia media={inventory.media} />
          </Block>
          <Block name="grid-blocks">
            <Bits.Text name="grid-block-text">
              {_.capitalize(inventory.label)}
            </Bits.Text>
            <Block name="counter-block">
              <Bits.Text name="grid-block-price">
                &#8377;
                {`${_.capitalize(inventory.rates.saleRate)}`}
              </Bits.Text>
              {this.renderQuickAdd(false)}
            </Block>
          </Block>
          </Layout>
          {this.renderExpandView()}
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => ({
    "counter-block": {
      master: {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    "secondary-shelf": {
      master: {
        boxSizing: "border-box",
        marginBottom: 50,
        width: "100%",
      },
      mobile: {
        margin: 0,
        padding: "0 0px",
        marginLeft: 0,
      },
      transition: "children-slideUp",
    },
    "grid-block": {
      master: {
        margin: 15,
      },
    },
    "grid-block-image": {
      master: {
        width: 150,
        height: 150,
        background: "#f9f1e9",
        borderRadius: 4,
        cursor: "pointer",
        padding: "50px 30px",
      },
      mobile: {
        width: 100,
        height: 100,
        padding: "40px 25px",
      },
    },
    "grid-block-more": {
      master: {
        width: 210,
        fontWeight: 600,
        color: colorPallete.primary,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      },
      mobile: {
        width: 100,
        height: 100,
        padding: "40px 20px",
      },
    },
    "grid-block-text": {
      master: {
        textTransform: "capitalize",
        textAlign: "left",
        fontSize: 16,
        fontWeight: 600,
        color: colorPallete.black,
        maxWidth: 210,
        margin: "7px 0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      mobile: {
        fontSize: 14,
      },
    },
    "grid-block-price": {
      master: {
        margin: 0,
        fontSize: 13,
        color: colorPallete.Black,
      },
    },
    "secondary-info-block": {
      master: {
        marginRight: 40,
        display: "inline-block",
      },
      mobile: {
        marginRight: 20,
        width: 150,
      },
    },
    "secondary-shelf-item-grid": {
      master: {
        justifyContent: "flex-start",
        whiteSpace: "nowrap",
        padding: "10px 0px 20px 0px",
        display: "flex",
        overflow: "auto",
        alignItems: "center",
      },
      mobile: {
        padding: 20,
      },
      transition: "children-slideUp",
    },
  })
}

SecondarySelfItem = Entities.createThing(withCart(SecondarySelfItem), {
  name: 'SecondarySelfItem'
})


class PrimaySelfItem extends React.Component {


  constructor(props) {
    super(props)
  }

  renderSurface(values, {
    Layout,
    Block,
    Bits,
  }) {
    const { shelfItem } = this.props;
    return (
      <Layout
        name="front-courousel"
      >
        <Block name="priamry-image-block" >
          <InventoryMedia
            media={shelfItem.media}
            name="primary-item-image"
            onClick={this.expandView}
          />
        </Block>
        <Block name="primary-item-name-block">
          <Block>
            <Bits.Text name="primary-block-title" type="p">
              Exclusives
            </Bits.Text>
          </Block>
          <Bits.Text onClick={this.expandView} name="name" type="p">
            {_.capitalize(shelfItem.label)}
          </Bits.Text>
          <Bits.Text name="description" type="p">
            {_.capitalize(shelfItem.description)}
          </Bits.Text>
          <Bits.Text
            name="moreInfo"
            type="p"
            onClick={this.expandView}
          >
            Know More
          </Bits.Text>
        </Block>
        {this.renderExpandView()}
      </Layout>
    );
  }

  getStylesheet = (colorPallete) => ({
    "front-courousel": {
      master: {
        maxWidth: 700,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "auto",
      },
      mobile: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "80%",
        margin: "0px 30px",
      },
    },
    "primary-shelf-block": {
      master: {
        height: "calc(100vh - 300px)",
        width: "100%",
        minHeight: 300,
      },
    },
    "primary-item-name-block": {
      master: {
        marginLeft: 30,
      },
      mobile: {
        margin: 0,
      },
    },
    "primary-item-name-block.name": {
      master: {
        borderRadius: 4,
        textTransform: "capitalize",
        padding: "10px 0px",
        fontSize: 34,
        fontWeight: 600,
        margin: 0,
        // color: colorPallete.bg,
        color: colorPallete.white,
        letterSpacing: 0.8,
      },
      mobile: {
        fontSize: 24,
        margin: 0,
      },
    },
    "primary-item-info": {
      master: {
        padding: "0px 40px",
      },
      mobile: {
        padding: "0px 30px",
      },
    },
    price: {
      master: {
        textAlign: "center",
      },
    },
    image: {
      master: {
        width: 140,
        objectFit: "contain",
        borderRadius: 4,
      },
    },
    "priamry-image-block": {
      master: {
        width: 200,
        height: 200,
        background: "unset",
        padding: 20,
        borderRadius: 4,
        marginLeft: 50,
      },
      mobile: {
        width: 150,
        height: 150,
        marginLeft: 0,
      },
    },
    "primary-item": {
      master: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 40px",
        cursor: "pointer",
      },
      mobile: {
        display: "flex",
        padding: "0px 30px",
      },
      transition: "children-children-",
    },
    "primary-item-image": {
      master: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: 4,
      },
    },
    "primary-block-title": {
      master: {
        backgroundColor: colorPallete.teritary,
        width: 95,
        fontWeight: 600,
        padding: "5px 0px 5px 16px",
        fontSize: 10,
        letterSpacing: 1.8,
        textTransform: "uppercase",
        margin: 0,
        borderRadius: 50,
        color: colorPallete.white,
        opacity: 0.9,
      },
      mobile: {
        width: 89,
        padding: "4px 0px 4px 16px",
        margin: "25px 6px 0px 0px",
        fontSize: 11,
        letterSpacing: 1,
      },
    },
    "block-title": {
      master: {
        color: colorPallete.primary,
        fontWeight: 600,
        fontSize: 30,
        letterSpacing: 1,
        fontFamily: "initial",
        margin: 0,
        opacity: 0.95,
      },
      mobile: {
        margin: "10px 20px 0px",
        fontSize: 24,
      },
      transition: "slideDown",
    },
    "block-subtitle": {
      master: {
        fontSize: 16,
        color: colorPallete.primary,
        margin: 0,
      },
      mobile: {
        fontSize: 12,
        margin: "5px 0px",
        opacity: 0.8,
      },
    },
    "primary-shelf.block-header": {
      master: {
        fontSize: 16,
      },
      transition: "children-slideRight",
    },
    "primary-shelf": {
      master: {
        // display: 'flex',
        alignItems: "center",
        padding: "20px 0px",
        margin: "20px auto",
        // backgroundColor: '#f1e5d9',
        // backgroundColor: '#f3e8de',
        backgroundColor: colorPallete.primary,
        justifyContent: "center",
        borderRadius: 6,
        width: "100%",
      },
      mobile: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'unset',
        padding: "50px 0px",
        width: "100%",
        borderRadius: 0,
      },
      transition: "children-slideUp",
    },
  })
}

PrimaySelfItem = Entities.createThing(withCart(PrimaySelfItem), {
  name: 'PrimaySelfItem'
})
class DisplayBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventories: {},
      offerQuery: { type: "OFFERS" },
      organicQuery: { label: "Organic" },
    };
    this.itemHelper = new Helper.ItemHelper();
    this.inventoryHelper = new Helper.InventoryHelper();
    this.categoryHelper = new Helper.CategoryHelper();
    this.showCartInFrontWall = config.getConfig("showCartInFrontWall");
    this.testimonials = config.getConfig("shop").testimonials;
  }

  async getData() {
    const displayboardInventories =
      await this.inventoryHelper.getDisplayBoardItems();

    const inventories = _.reduce(
      displayboardInventories,
      (compiled, inventory) => {
        _.forEach(inventory.displayBoardShelves, (shelf) => {
          compiled[shelf] = compiled[shelf] || [];
          compiled[shelf].push(inventory);
        });

        return compiled;
      },
      {}
    );

    _.pull(inventories, { hideInventory: true });
    this.setState({
      inventories,
      inventoryFlatList: _.compact(
        _.concat(inventories["PRIMARY"], inventories["SECONDARY"])
      ),
    });
  }

  cartUpdate = (...args) => {
    Helper.CartHelper.updateCart(...args);
    this.setState({
      cart: Helper.CartHelper.getCart(),
    });
  };

  onItemClick = (inventory) => {
    this.setState({
      showModal: true,
      inventoryClicked: inventory,
    });
  };

  onAddToStack = (...args) => {
    this.itemHelper.addToStack(...args);
    this.setState({
      inventoryClicked: this.itemHelper.getStackTop(),
    });
  };

  onItemBack = () => {
    this.itemHelper.popOutStack();
    const inventoryClicked = this.itemHelper.getStackTop();
    inventoryClicked
      ? this.setState({
          inventoryClicked,
        })
      : this.setState({
          inventoryClicked: null,
          showModal: false,
        });
  };

  getLabelValues = (_, transformers) => {
    const randomTopShelfItem = _.sample();
    return {
      inventories: this.state.inventories,
      inventoryName: _.get(randomTopShelfItem, "label"),
      description: _.get(randomTopShelfItem, "description"),
      image: _.get(randomTopShelfItem, "image"),
      randomTopShelfItem,
      topShelfItems:_.slice(_.shuffle( _.get(this.state.inventories, "PRIMARY")), 0, 5),
    };
  };

  // onGoToShop = () => {
  //     this.goToView('shop')
  // }

  getRelatedInventories = () => {
    return _.map(
      _.reject(this.state.inventoryFlatList, (i) => {
        return i._id === this.state.inventoryClicked;
      }),
      (i) => [i]
    );
  };

  onCategoryClick = (categoryId) => {
    this.goToView(`shop/${categoryId}`);
  };

  cartUpdate = (count, cartItem) => {
    Helper.CartHelper.updateCart(count, cartItem);
    this.setState({
      cart: this.state.cart + 1,
    });
  };

  cartValue = (inventory) => {
    const value = Helper.CartHelper.getItemCountInCart(inventory._id);
    return value;
  };

  renderSurface(
    values,
    { MainLayout, Layout, Block, Bits, View, interiorDesigner }
  ) {
    return (
      <MainLayout>
        <View>
          <Layout name="display-board">
            <Layout name="primary-shelf">
              <Block
                name="primary-shelf-block"
                skeleton={{ type: "rectangle" }}
              >
                <SliderComp>
                  {_.map(values.topShelfItems, (shelfItem, index) => <PrimaySelfItem
                      key={index}
                      shelfItem={shelfItem}
                      {...Helper.CartHelper.makeProps([shelfItem])}
                    />)}
                </SliderComp>
                {/* Just to test with 1 product(on PRIMARY SHELF) for styling purpose */}
                {/* {values.topShelfItems && (
                  <Layout
                    onClick={() => this.onItemClick(values.topShelfItems[0])}
                    name="front-courousel"
                  >
                    <Block name="priamry-image-block">
                      <InventoryMedia
                        media={values.topShelfItems[0].media}
                        name="primary-item-image"
                      />
                    </Block>

                    <Layout name="primary-item-name-block">
                      <Block>
                        <Bits.Text name="primary-block-title" type="p">
                          Exclusives
                        </Bits.Text>
                      </Block>
                      <Bits.Text name="name" type="p">
                        {_.capitalize(values.topShelfItems[0].label)}
                      </Bits.Text>
                      <Bits.Text name="description" type="p">
                        {_.capitalize(values.topShelfItems[0].description)}
                      </Bits.Text>
                      <Bits.Text
                        name="moreInfo"
                        type="p"
                        onClick={() =>
                          this.onItemClick(values.topShelfItems[0])
                        }
                      >
                        Know More
                      </Bits.Text>
                    </Layout>
                  </Layout>
                )} */}
              </Block>

              {/* <Layout name="exclusive-label-caption">
                    <Block>
                        <Bits.Text name='primary-block-subtitle' type="p">
                            Rare collection of products with their health benefits
                        </Bits.Text>
                    </Block>
                </Layout> */}
            </Layout>
            <Layout name="coupon-showcase">
              <Block name="coupon-slider" skeleton={{ type: "rectangle" }}>
                <CouponShowcase />
              </Block>
            </Layout>
            <Layout name="secondary-shelf">
              <Block name="block-header">
                <Bits.Text name="block-title" type="h2">
                  Best Sellers
                </Bits.Text>
                {/* <Bits.Text name='block-subtitle' type="p">
                                    Best selling products from our collection
                                </Bits.Text> */}
              </Block>
              <Block
                name="secondary-self-block"
                skeleton={{
                  type: "card",
                  cols: 5,
                  cover: "secondary-shelf.grid-block-image",
                }}
              >
                <Layout name="secondary-shelf-item-grid">
                  {_.map(
                    _.get(values.inventories, "SECONDARY"),
                    (inventory, index) => {
                      return <SecondarySelfItem
                        inventory={inventory}
                        {...Helper.CartHelper.makeProps([inventory])}
                        key={index}
                      />
                    }
                  )}
                  <Layout
                    name="more-container"
                    onClick={() => this.goToView("categories")}
                  >
                    <Block name="grid-block-more">
                      <Bits.Text name="more">View More</Bits.Text>
                      <Block name="front-arrow">
                        <Bits.Icon name="view-more-icon">
                          ArrowForwardTwoTone
                        </Bits.Icon>
                      </Block>
                    </Block>

                    {/* <Block>
                                            <Bits.Text name='grid-block-text'>{_.capitalize(.label)}</Bits.Text>
                                            <Bits.Text name='grid-block-price'>{`Rs. ${_.capitalize(inventory.rates.saleRate)}`}</Bits.Text>
                                        </Block> */}
                  </Layout>
                </Layout>
              </Block>
            </Layout>
            <InfoBlock />
            <Layout name="categories">
              <Layout name="categories-block">
                <Block name="block-header">
                  <Bits.Text name="block-title" type="h2">
                    Menu
                  </Bits.Text>
                </Block>
              </Layout>
              <Block name="frontwall-categories-list">
                <MenuList />
              </Block>
            </Layout>
            {this.testimonials && (
              <Layout name="testimonials-block">
                <Layout name="categories-block">
                  <Block name="block-header">
                    <Bits.Text name="block-title" type="h2">
                      Testimonials
                    </Bits.Text>
                  </Block>
                </Layout>
                <Block name="testimonial-carousel">
                  <SliderComp dots={true}>
                    {_.map(this.testimonials, (item) => {
                      return (
                        <Layout name="testimonial-details-block">
                          <Block name="testimonial-details">
                            <Bits.Text name="review">{item.descr}</Bits.Text>
                            <Bits.Text name="reviewer">{`- ${item.name}`}</Bits.Text>
                          </Block>
                        </Layout>
                      );
                    })}
                  </SliderComp>
                </Block>
              </Layout>
            )}
            <TaggedInventories
              title="Offers"
              query={this.state.offerQuery}
            />
            <TaggedInventories
              title="Organic"
              query={this.state.organicQuery}
            />
          </Layout>
        </View>
      </MainLayout>
    );
  }

  onClick = (inventory, count) => {
    this.itemHelper.updateStack(inventory);
    this.setState({
      inventory: inventory,
      showFlag: true,
      count: count,
    });
  };

  getStylesheet = (colorPallete) => {
    return {
      "front-courousel": {
        master: {
          maxWidth: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "auto",
        },
        mobile: {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "80%",
          margin: "0px 30px",
        },
      },
      "primary-shelf-block": {
        master: {
          height: "calc(100vh - 300px)",
          width: "100%",
          minHeight: 300,
        },
      },
      "testimonial-details-block": {
        master: {
          width: "100%",
        },
      },
      
      // 'secondary-self-block': {
      //     master: {
      //         minHeight: 100,
      //     },
      //     mobile: {
      //         minHeight: 100,
      //     }
      // },
      "testimonial-details": {
        master: {
          maxWidth: "60%",
          margin: "auto",
        },
        mobile: {
          maxWidth: "100%",
        },
      },
      "testimonial-carousel": {
        master: {
          margin: 60,
        },
        mobile: {
          margin: 20,
        },
      },
      "testimonials-block": {
        master: {
          //margin:20
        },
      },
      "counter-block": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          marginRight: 15,
        },
      },
      "categories-list.categories-container": {
        master: {
          maxWidth: "100%",
          marginRight: 20,
          justifyContent: "flex-start",
          whiteSpace: "nowrap",
          padding: "10px 0px 20px 0px",
          display: "flex",
          alignItems: "center",
          overflow: "auto",
        },
        mobile: {
          marginRight: 20,
          justifyContent: "flex-start",
          whiteSpace: "nowrap",
          padding: "10px 0px 20px 0px",
          display: "flex",
          alignItems: "center",
          overflow: "auto",
        },
        transition: "children-slideRight",
      },
      "view-more-icon": {
        master: {
          fontSize: 18,
          color: interiorDesigner.primary,
          padding: 3,
        },
      },
      
      "secondary-shelf": {
        master: {
          boxSizing: "border-box",
          marginBottom: 50,
          width: "100%",
        },
        mobile: {
          margin: 0,
          padding: "0 0px",
          marginLeft: 0,
        },
        transition: "children-slideUp",
      },
      "display-board": {
        master: {
          backgroundColor: colorPallete.bg,
        },
      },
      "info-block": {
        master: {
          display: "flex",
          direction: "row",
        },
      },
      "primary-item-image": {
        master: {
          width: "100%",
          height: "240px",
          objectFit: "contain",
          borderRadius: 5,
        },
        mobile: {
          height: "100%",
        },
      },
      
      "coupon-showcase": {
        master: {
          marginTop: 40,
          maxWidth: "100%",
          display: "flex",
          alignItems: "center",
          //justifyContent: 'center',
          //width: 'fit-content',
          borderColor: colorPallete.primary,
          boxSizing: "border-box",
        },
        mobile: {
          marginTop: 20,
          padding: 0,
          display: "block",
          alignItems: "flex-start",
          flexDirection: "column",
          margin: "30px 0px",
        },
      },
      "coupon-showcase.block-header": {
        master: {
          padding: "10px 0px",
          fontSize: 16,
        },
        mobile: {
          padding: 0,
        },
      },
      "coupon-slider": {
        master: {
          position: "relative",
          top: "2px",
          //marginLeft: 100
        },
        mobile: {
          width: "100%",
          margin: 0,
          top: 0,
        },
      },
      "front-arrow": {
        master: {
          border: `1.5px solid ${colorPallete.secondary}`,
          borderRadius: "100%",
          margin: "0px 10px",
          background: colorPallete.secondary,
          opacity: 0.8,
          width: 24,
          height: 24,
        },
        mobile: {
          margin: "0px 5px",
        },
      },
      moreInfo: {
        master: {
          color: colorPallete.teritary,
          fontSize: 14,
          fontWeight: 500,
          margin: "6px 0px",
          letterSpacing: 0.8,
          cursor: "pointer",
        },
      },
      description: {
        master: {
          textTransform: "capitalize",
          color: colorPallete.bg,
          width: 400,
          fontSize: 13,
          letterSpacing: 0.4,
          lineHeight: 1.6,
          margin: 0,
          opacity: 0.9,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
        },
        mobile: {
          width: "100%",
          fontSize: 12,
        },
      },
      "primary-item-name-block": {
        master: {
          marginLeft: 30,
        },
        mobile: {
          margin: 0,
        },
      },
      "primary-item-name-block.name": {
        master: {
          borderRadius: 4,
          textTransform: "capitalize",
          padding: "10px 0px",
          fontSize: 34,
          fontWeight: 600,
          margin: 0,
          // color: colorPallete.bg,
          color: colorPallete.white,
          letterSpacing: 0.8,
        },
        mobile: {
          fontSize: 24,
          margin: 0,
        },
      },
      "primary-item-info": {
        master: {
          padding: "0px 40px",
        },
        mobile: {
          padding: "0px 30px",
        },
      },
      price: {
        master: {
          textAlign: "center",
        },
      },
      image: {
        master: {
          width: 140,
          objectFit: "contain",
          borderRadius: 4,
        },
      },
      "priamry-image-block": {
        master: {
          width: 200,
          height: 200,
          background: "unset",
          padding: 20,
          borderRadius: 4,
          marginLeft: 50,
        },
        mobile: {
          width: 150,
          height: 150,
          marginLeft: 0,
        },
      },
      "primary-item": {
        master: {
          display: "flex",
          flexDirection: "column",
          padding: "0px 40px",
          cursor: "pointer",
        },
        mobile: {
          display: "flex",
          padding: "0px 30px",
        },
        transition: "children-children-",
      },
      "primary-item-image": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: 4,
        },
      },
      "primary-block-title": {
        master: {
          backgroundColor: colorPallete.teritary,
          width: 95,
          fontWeight: 600,
          padding: "5px 0px 5px 16px",
          fontSize: 10,
          letterSpacing: 1.8,
          textTransform: "uppercase",
          margin: 0,
          borderRadius: 50,
          color: colorPallete.white,
          opacity: 0.9,
        },
        mobile: {
          width: 89,
          padding: "4px 0px 4px 16px",
          margin: "25px 6px 0px 0px",
          fontSize: 11,
          letterSpacing: 1,
        },
      },
      "block-title": {
        master: {
          color: colorPallete.primary,
          fontWeight: 600,
          fontSize: 30,
          letterSpacing: 1,
          fontFamily: "initial",
          margin: 0,
          opacity: 0.95,
        },
        mobile: {
          margin: "10px 20px 0px",
          fontSize: 24,
        },
        transition: "slideDown",
      },
      "block-subtitle": {
        master: {
          fontSize: 16,
          color: colorPallete.primary,
          margin: 0,
        },
        mobile: {
          fontSize: 12,
          margin: "5px 0px",
          opacity: 0.8,
        },
      },
      "name-piece-block": {
        master: {
          margin: "auto",
        },
      },
      "grid-block": {
        master: {
          margin: 15,
        },
      },
      "grid-block-image": {
        master: {
          width: 150,
          height: 150,
          background: "#f9f1e9",
          borderRadius: 4,
          cursor: "pointer",
          padding: "50px 30px",
        },
        mobile: {
          width: 100,
          height: 100,
          padding: "40px 25px",
        },
      },
      "grid-block-more": {
        master: {
          width: 210,
          fontWeight: 600,
          color: colorPallete.primary,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        },
        mobile: {
          width: 100,
          height: 100,
          padding: "40px 20px",
        },
      },
      "more-container": {
        master: {
          display: "inline-block",
          alignItems: "center",
        },
      },
      more: {
        master: {
          fontWeight: 400,
          marginTop: 10,
        },
        mobile: {
          fontSize: 12,
          margin: "0px 2px 0px 0px",
        },
      },
      "grid-block-text": {
        master: {
          textTransform: "capitalize",
          textAlign: "left",
          fontSize: 16,
          fontWeight: 600,
          color: colorPallete.black,
          maxWidth: 210,
          margin: "7px 0px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        mobile: {
          fontSize: 14,
        },
      },
      "grid-block-price": {
        master: {
          margin: 0,
          fontSize: 13,
          color: colorPallete.Black,
        },
      },
      "secondary-info-block": {
        master: {
          marginRight: 40,
          display: "inline-block",
        },
        mobile: {
          marginRight: 20,
          width: 150,
        },
      },
      "secondary-shelf-item-grid": {
        master: {
          justifyContent: "flex-start",
          whiteSpace: "nowrap",
          padding: "10px 0px 20px 0px",
          display: "flex",
          overflow: "auto",
          alignItems: "center",
        },
        mobile: {
          padding: 20,
        },
        transition: "children-slideUp",
      },
      "item-details": {
        master: {
          margin: "auto",
        },
      },
    };
  };
}

export default Entities.createPlace(DisplayBoard, {
  name: "DisplayBoard",
  hasSkeleton: true,
});
