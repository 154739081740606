import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (item) => () => {
    
    item.link ? history.push(item.link) : item.onClick();
    setAnchorEl(null);
  }
  return (
    <div>
    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      {
        props.buttonLabel ||
        <MenuIcon fontSize="large" color='action'/>
      }
    </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      {
        _.map(props.items.list, item=>{
          return(
            <MenuItem onClick={onItemClick(item)}>{item.label}</MenuItem>
          )
        })
      }
      </Menu>
    </div>
  );
}


// import React from 'react';
// import Menu from './menu';
// import MenuIcon from '@material-ui/icons/Menu';


// class MenuButton extends React.Component {

//     constructor(props) {
//         super(props);
//         this.triggerMenu = this.triggerMenu.bind(this);
//         this.state = {show : false }
//         this.state = {
//             showContactForm: false,
//             show : false,
            
//         };
//         this.myRef = React.createRef();
//         this.state = { open: false, show : false, key: 0 }
//         this.triggerMenu = this.triggerMenu.bind(this);
//     }
    
//     onMenuClose = () => {
//         this.setState({
//             show: false,
//         });
//     }

//     triggerMenu(e){
//         this.setState({ show : true })
//     }

//     render() {
//         return (
//             <div>
//                 <Menu
//                     open={this.state.show}
//                     getAnchorEl={() => this.ele}
//                     onClose={this.onMenuClose}
//                     root={"menulist"}
//                     list={this.props.items.list}
//                     lable={this.props.items.list}
//                 >    
//                     <div className='flex' onClick={this.triggerMenu}>
//                         <p  style={{color: 'white'}} ref={ele => this.ele = ele}>{this.props.items.name}</p>
//                         <MenuIcon fontSize="large" color='action'/>
//                     </div>
//                 </Menu> 
//             </div>
//         );
//     }
// }

// export default MenuButton;
