import React, {Component} from 'react';
import { Button as ButtonInput} from '@material-ui/core';
import _ from 'lodash';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';


class Button extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    onClick = async (event) => {
        this.setState({
            loading: true,
        });
        await this.props.onClick(event);
        this.setState({
            loading: false,
        });
    }

    render(){
        return(
            <div className={classNames(this.props.className, { loading: this.state.loading || this.props.loading })}>
                <ButtonInput {...this.props} onClick={this.onClick} disable={this.state.loading}>
                    {(this.props.loading || this.state.loading) ? 
                        <span className="button__loader">
                            <CircularProgress />
                        </span>
                      :
                    <span className="button__content">
                        {this.props.children}
                    </span>
                    }
                </ButtonInput>
                <br/>
            </div>
        ) 
    }
}

Button.defaultProps = {
    onClick: _.noop,
};

export default Button;