import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import Button from "../../Input/button";
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Close } from '@material-ui/icons';

function getModalStyle() {
  const top = 40 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '80%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding:'24px',
    opacity:0.8,
    overflow: "scroll"
  },
});

class CustomModal extends React.Component {
    constructor(props){
        super(props);
    }
    state = { open: false };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        disableAutoFocus
        // fullWidth
        closeAfterTransition
      >
        <Fade in={this.props.open}>
          <div style={getModalStyle()} className={classes.paper}>
            {
              this.props.title && 
                <div className="font-30">
                  {this.props.title}
                </div>
            }
            {
              this.props.escape &&
                <div className="modal__escape pointer" onClick={this.props.onClose}>
                  <Close />
                </div>
            }
            {this.props.children}
            {
            (this.props.okButton) &&
              <div className={classNames('text-center', { spinner: this.props.loading })}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={this.props.okButton.onClick}
                  loading={this.props.okButton.loading}
                >
                  {this.props.okButton.label}
                </Button>
                {
                  this.props.cancelButton &&
                    <Button
                      className="flat"
                      onClick={this.props.cancelButton.onClick}
                    >{this.props.cancelButton.label}</Button>
                }
              </div>
          }
          </div>
        </Fade>
      </Modal>
    );
  }
}

CustomModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomModal);