import api from '@api';
import _ from 'lodash';

class FilterHelper {

    constructor() {
        this.filterList = []
        this.showBadge = true
    }

    getFilterList = async (propertyId) =>{
        let filterList = await api.Inventories.getFilterList(propertyId)
        this.filterList = filterList
        return filterList
    }

    getFilteredInventories = async (values) =>{
        let inventories = await api.Inventories.fetchFilteredInventories(values)
        return inventories
    }

    filterListFromInventories = async (list) =>{
        let query = {}
        const inventories = await api.Inventories.find({query})
        let inventoryPropsPool = []
        inventoryPropsPool =  _.reduce(inventories, (inventoryPropsPool, inventory) => {
                                _.forEach(inventory.properties, property =>{
                                    inventoryPropsPool.push(property.value)
                                })
                                return inventoryPropsPool
                            },[])
        _.forEach(list, item=>{
            let intersectedValues = _.intersection(item.values, inventoryPropsPool)
            item.values = intersectedValues
        })
        this.filterList = list 
        return this.filterList
    }

    getFilteredList = (values) =>{
        this.filterList = _.map(this.filterList, section =>{
            return ({
                ...section,
                values: _.map(section.values, (value, key) => {
                    return ({
                        ...value,
                        opted: _.includes(values[section.label], value.value),
                    })
                })
            })
        })
        let list = [] 
        _.mapKeys(values, (value, key)  => {
            let data = {
                label : key,
                values : value
            }
            if (value)
            list.push(data)
        });
        return list
    }

    isFiltered = () =>{
        return _.some(this.filterList, section =>{
            return _.some(section.values, ["opted", true])
        })
    }

    getFilters = async (propertyId) =>{
        if(this.filterList.length > 0){
            return this.filterList
        }else{
            const filterList = await this.getFilterList(propertyId)
            this.filterList = filterList
            return this.filterList
        }
    }
    
}

export default FilterHelper
