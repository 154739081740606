import React from 'react';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import { View } from '../bits';
import _ from 'lodash';
import { concatNameChain } from '../lib/utils';

class Layout extends React.PureComponent {

    constructor(props) {
        super(props);

        this.renderChild = this.renderChild.bind(this)
        this.renderLayoutView = this.renderLayoutView.bind(this)
    }

    
    getName = () => {
        return this.props.name || ('layout.' + this.props.childIndex)
    }

    displayName = this.getName()

    getChild(Child, index, ChildrenStylesheet, interpolatingStyle) {
        return React.cloneElement(Child, {
            // key: index,
            childIndex: index,
            ref: Child.props.innerRef,
            style: { ...(Child.props.style || {}), ...interpolatingStyle },
            stylesheet: _.merge({}, ChildrenStylesheet, Child.props.stylesheet || {}),
            namechain: concatNameChain(this.props.namechain, Child.props.name || `layouts.${index + 1}`)
        })
    }

    renderChild(transitionStyles, ChildTransition) {

        const self = this;
        return function (Child, index) {
            if (!Child) return null;

            const ChildrenStylesheet = self.getChildrenStylesheet()
            const ChildInstance = self.getChild(Child, index, ChildrenStylesheet, transitionStyles)
            if (ChildTransition) {
                return <ChildTransition
                    key={index}
                    index={index + 1}
                    render={function() {
                        return ChildInstance;
                    }}
                />
            } else {
                return ChildInstance;
            }
        }
    }

    getChildrenStylesheet = () => {
        return interiorDesigner
            .parentStylesheet(this.props.stylesheet)
            .getChildrenStylesheet(this.getName());
    }

    renderLayoutView(transitionStyles, ChildTransition) {
        const styles = interiorDesigner
            .parentStylesheet(this.props.stylesheet)
            .getStyles(this.getName(), { ...this.props.style }, this.props.namechain);

        return (
            <View className={ChildTransition && "transit-overflow"} {...this.props} style={{  ...styles,  ...transitionStyles }}>
                {
                    React.Children.map(this.props.children, this.renderChild(transitionStyles, ChildTransition))
                }
            </View>
        )
    }

    render() {
        const Transition = interiorDesigner
        .parentStylesheet(this.props.stylesheet)
        .getTransition(this.getName(), this.props.namechain);
        
        const self = this;
        if (Transition) {
            return Transition.toChildren ?
                this.renderLayoutView({}, Transition.Component) :
                <Transition.Component
                    render={function(interpolatingStyle) {
                        return self.renderLayoutView(interpolatingStyle);
                    }}
                />
        }
        return this.renderLayoutView()
    }
}

export default Layout;