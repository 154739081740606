import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import CustomFormHOC from './customFormHoc';
import { withFormsy } from 'formsy-react';
import _ from 'lodash';
import { getAddressByCurrentLocation , getAddressByLatLng} from '../../lib/places';
import CircularProgress from '@material-ui/core/CircularProgress';

const containerStyle = {
  width: '100%',
  height: '200px'
};

function GoogleMapPicker(props) {

    const [latlng, updateLatLng] = React.useState(props.defaultValue)
    if(_.isEmpty(latlng) || (latlng.lat === null)){
        getAddressByCurrentLocation((data, latlng, rawAddress) => {
            props.onSelect(data)
            updateLatLng(latlng)
        })
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "YOUR_API_KEY"
    })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onDraggableChanged = (address) =>{
    let latLng = {
        lat :address.latLng.lat(), lng: address.latLng.lng()
    }
    updateLatLng(latLng)
    handleChangeLocation(latLng)
  }

  const handleChangeLocation = (latLng) =>{
    const address = getAddressByLatLng(latLng.lat, latLng.lng, (formattedData) => {
        props.onSelect(formattedData);
    //this.handleChange(formattedData)
    })
}
  return isLoaded && latlng && 
    <div style={{marginBottom:10}}>
    {
      !_.isEmpty(latlng) ?
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={latlng}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <Marker
            //onLoad={onLoad}
            position={latlng}
            draggable={true}
            // onDrag={onDrag}
            onDragEnd ={onDraggableChanged}
        />
      </GoogleMap> : 
        <div style={{ position: 'relative', top: '50%', left: '50%', transform: [{ translateX: '-50%' }, { translateY: '-50%' }] }}>
          <CircularProgress color="#041f40"/>
          <p style={{marginLeft:-40}}>Fetching maps...</p>
        </div>
    }
      
    </div>
}

GoogleMapPicker.defaultProps = {
  onSelect: _.noop,
  //restrictLabel: true,
};


export default {
  input: CustomFormHOC(GoogleMapPicker),
  formInput: withFormsy(GoogleMapPicker),
};

