import React from 'react';
import createBit from '../lib/createBit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import _ from 'lodash'
import helpers from '@core/helpers';

class Image extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
    this.imageThumbnailSizePallete = this.props.config.getConfig('imageThumbnailSizePallete');
  }

  shouldComponentUpdate(nextProps, nextState) {
      if ((!_.isEqual(this.props.src, nextProps.src) && !_.isEqual(this.props.style, nextProps.style)) || (this.state.hasError !== nextState.hasError)) {
        return true;
      } return false;
  }

  getSrc = () => {
    return this.state.hasError ? helpers.MediaHelper.getOriginalFileURL(this.props.src) : helpers.MediaHelper.getThumbnailURL(this.props.src, this.props._blockStyles)
  }

  onError = () => {
    this.setState({
      hasError: true,
    })
  }

  render() {

    return this.props.noLazy ? <img onError={this.onError} {...this.props} src={this.getSrc()} /> : (
      <LazyLoadImage
        onError={this.onError}
        effect="opacity"
        threshold={window.innerHeight}
        // beforeLoad={() => setShowPlaceholder(true)}
        // afterLoad={() => setShowPlaceholder(false)}
        {...this.props}
        src={this.getSrc()}
        wrapperProps={{
          style: {
            display: 'unset',
            width: '100%',
            height: '100%',
          }
        }}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          ...this.props.style
        }}
      />
    )
  }
}

 
  
export default createBit(Image, {
    name: 'img'
});
