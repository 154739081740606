import Formsy from "formsy-react";
import _ from "lodash";
import React from "react";
import classNames from "classnames";
import { CircularProgress, Button, Typography } from "@material-ui/core";
import FormMaker from "./formMaker";
import { addValidationRule } from "formsy-react";
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: _.get(props.submitButton, "disabled"),
      refreshKey: 0
    };
    this.form = React.createRef();
    addValidationRule("isNotEmpty", function (values, value, array) {
      return value && value.length > 0;
    });
  }

  reset = () => {
    this.form.current.reset();
    this.setState({
      refreshKey: this.state.refreshKey+1
    })
  };

  onInvalid = () => {
    this.setState({
      isValid: false,
    });
    this.props.onInvalid();
  };

  onValid = () => {
    this.setState({
      isValid: true,
    });
    this.props.onValid();
  };

  onChange = (values) => {
    this.setState({
      currentValues: values,
    });
    this.props.onChange(values);
  };

  onSubmit = async (values) => {
    // if submiting already, dont submit again
    if (this.state.isSubmitting) return;

    this.setState({
      isSubmitting: true,
    });

    const defaultModel = this.props.defaultModel;
    let changedValues = values;

    if (defaultModel) {
      changedValues = _.pickBy(
        values,
        (value, key) => !_.isEqual(value, defaultModel[key])
      );
    }

    this.props.onSubmit(
      {
        ...values,
        onSuccess: () => {
          this.setState({
            isSubmitting: false,
          });
        },
        onError: () => {
          this.setState({
            isSubmitting: false,
          });
        },
      },
      values,
      changedValues,
      this
    );
  };

  submit = () => {
    this.form.current.submit();
  };

  getRequiredFields = () => {
    const formInputList = _.get(this.props.formMaker, "list");

    if (formInputList) {
      return _.filter(formInputList, "props.required");
    }
    return [];
  };

  getIncompleteFields = () => {
    if (!this.form.current) return;
    const model = this.form.current.getModel();
    const requiredFields = this.getRequiredFields();
    const incompleteFields = _.filter(requiredFields, (field) =>
      _.isEmpty(_.get(model, field.props.name))
    );
    return incompleteFields;
  };

  getModel = () => this.form.current.getModel()

  render() {
    const incompleteFields = this.getIncompleteFields();
    return (
      <div key={this.state.refreshKey} className={classNames("form-container", this.props.className)}>
        <Formsy
          onValid={this.onValid}
          onInvalid={this.onInvalid}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          ref={this.form}
        >
          {this.props.title && (
            <div
              className={classNames(
                "margin-t-b-10 form-header",
                this.props.titleClassName,
                { "text-center": this.props.centeredHead }
              )}
            >
              <div className="margin-l-r-10">
                <Typography variant="h4">{this.props.title}</Typography>
              </div>
              {this.props.caption && (
                <p className="grey-text form__caption small-title margin-t-b-10 ">
                  {this.props.caption}
                </p>
              )}
            </div>
          )}
          <div
            className={
              this.props.buttonPosition === "row"
                ? "flex-row form-row flex-middle"
                : "flex-column"
            }
          >
            <div
              className={classNames("form_children", { blur: this.props.blur })}
            >
              {this.props.childrenFirst && this.props.children}
              {this.props.formMaker &&
                FormMaker(
                  {
                    fields: this.props.formMaker.list,
                    Transition: this.props.formMaker.Transition,
                    currentValues: this.state.currentValues,
                    wrapperClassName: this.props.formMaker.wrapperClassName,
                  },
                  this.props.defaultModel
                )}
              {!this.props.childrenFirst && this.props.children}
            </div>
            {this.props.showIncompleteFieldIndicator && !_.isEmpty(incompleteFields) && (
              <div className="light-blue-box padding-10">
                <span>
                  Please provide{" "}
                  {_.map(incompleteFields, (iF, index) => (
                    <b>
                      {" "}
                      &nbsp;
                      {_.capitalize(
                        _.lowerCase(
                          iF.props.errorLabel || iF.props.label || iF.props.name
                        )
                      )}
                      {index != incompleteFields.length - 1 && ","}
                    </b>
                  ))}
                  &nbsp;to continue..
                </span>
              </div>
            )}
            {
              <div
                className={classNames(
                  "text-center margin-t-b-20",
                  this.props.buttonWrapperClassName,
                  { blur: this.props.blur }
                )}
              >
                {this.props.backButton && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="margin-l-r-10"
                    onClick={() =>
                      this.props.backButton.onClick({ submit: this.submit })
                    }
                  >
                    {_.get(this.props.backButton, "label", "back")}
                  </Button>
                )}
                {!this.props.submitButton.hide && (
                  <Button
                    type="submit"
                    variant={this.props.submitButton.variant}
                    className={this.props.submitButton.className}
                    fullWidth={this.props.submitButton.fullWidth}
                    size={this.props.submitButton.size || "large"}
                    color="primary"
                    disabled={
                      this.props.submitButton.disabled || !this.state.isValid
                    }
                    id={_.get(this.props.submitButton, "submitButtonId")}
                    style={this.props.submitButton.style}
                  >
                    {this.state.isSubmitting ? (
                      <CircularProgress color="white" />
                    ) : (
                      this.props.submitButton.label
                    )}
                  </Button>
                )}
                {this.props.secondaryButton &&
                  !this.props.secondaryButton.hide && (
                    <div
                      style={{
                        marginTop:
                          this.props.buttonPosition === "row" ? 28 : 13,
                      }}
                    >
                      <Button
                        size={this.props.secondaryButton.size || "large"}
                        fullWidth={this.props.secondaryButton.fullWidth}
                        variant={this.props.secondaryButton.color || "outlined"}
                        color={this.props.secondaryButton.color || "secondary"}
                        className={this.props.submitButton.className}
                        disabled={
                          this.props.secondaryButton.disableOnInvalid &&
                          !this.state.isValid
                        }
                        onClick={() =>
                          this.props.secondaryButton.onClick({
                            submit: this.submit,
                          })
                        }
                        style={this.props.submitButton.style}
                      >
                        {this.props.secondaryButton.label || "Cancel"}
                      </Button>
                    </div>
                  )}
              </div>
            }
          </div>
        </Formsy>
      </div>
    );
  }
}

Form.defaultProps = {
  submitButton: {
    label: "Next step",
  },
  onSubmit: _.noop,
  onChange: _.noop,
  onInvalid: _.noop,
  onValid: _.noop,
  showIncompleteFieldIndicator: true
};

export default Form;
