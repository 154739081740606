import React, { Component } from "react";
import Slider from "react-slick";
import _ from "lodash";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SliderComp extends Component {
  render() {
    const settings = {
      dots: this.props.dots,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <Slider
        className="slider"
        style={{ width: "100%", height: "100%" }}
        {...settings}
      >
        {this.props.children}
      </Slider>
    );
  }
}

SliderComp.defaultProps = {
  dots: true,
};

export default SliderComp;
