import React from 'react';
import _ from 'lodash';
import Entities from '@toolkit/entities'
import Helper from "@core/helpers";
import { RadioGroupForm } from '../../../../../web-bricks/Shared/Input/radio';
import CounterButton from "@core/modules/shared/views/Things/counter";
import interiorDesigner from '@toolkit/lib/interiorDesigner';


class Counter extends React.Component {


    constructor(props) {
        super(props);

        const defaultVariant = _.get(_.head(this.props.variants), 'value');

        this.state = {
            countBook: {},
            selectedVariant: defaultVariant,
            hasMoreVariants: _.size(this.props.variants) > 1,

        }
    }

    componentDidMount() {
        const countBook = _.reduce(this.props.variants, (countBook, variant) => {
            countBook[variant.value] = Helper.CartHelper.getItemCountInCart(variant.value);
            return countBook;
        }, {})
        this.setState({
            countBook
        })
    }


    onUpdate = (count) => {


        if (this.props.quickView && this.state.hasMoreVariants) {
            this.props.onClick()
            return;
        }
        const countBook = this.state.countBook;
        countBook[this.state.selectedVariant] = count;

        Helper.CartHelper.updateCart(count, this.props.inventoryBook[this.state.selectedVariant]);
        this.setState({
            countBook
        })
    }

    onVariantChange = (e, variantInventory) => {
        this.setState({
            selectedVariant: variantInventory
        })
    }

    render() {
        const self = this;
        return (
            <div>
                <div>
                    {
                        !this.props.quickView && this.state.hasMoreVariants &&
                            <RadioGroupForm displayInRowClass="row-scrollable" defaultValue={this.state.selectedVariant} onChange={this.onVariantChange} displayInRow list={this.props.variants} />
                    }
                    <div style={{ position: 'relative'}}>
                        {
                            this.props.quickView && this.state.hasMoreVariants &&
                                <div style={{
                                    position: 'absolute', 
                                    top: -5,
                                    right: 0,
                                    zIndex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                 }}>
                                    {
                                        _.map(this.props.variants, function (v, index) {
                                            const count = self.state.countBook[v.value];
                                            return count > 1 ? <div
                                                key={index}
                                                style={{
                                                    backgroundColor: interiorDesigner.colorPallete.primary,
                                                    borderRadius: '50%',
                                                    position: 'relative',
                                                    paddingTop: 2,
                                                    left: !index? 6 : 3,
                                                    paddingLeft: 4,
                                                    paddingRight: 4,
                                                    paddingBottom: 2,
                                                    lineHeight: '14px',
                                                    fontSize: 14,
                                                    color: 'white'
                                                }}>
                                                {count}
                                            </div> : null;
                                        })
                                    }
                                </div>
                        }
                        <CounterButton
                            suffixText={this.props.suffixText}
                            alwaysShowAdd={!this.props.quickView}
                            variants={this.props.variants}
                            quickView={this.props.quickView}
                            small={this.props.quickView}
                            key={this.state.selectedVariant}
                            onChange={this.onUpdate}
                            outlined={this.props.outlined}
                            count={this.state.countBook[this.state.selectedVariant]}
                        />
                    </div>
                </div>
            </div>
        )
    }


   
}


Counter.defaultProps = {
    onClick: _.noop
}

export default Counter;
