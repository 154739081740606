import React from "react";
import _ from "lodash";
import Entities from "@toolkit/entities";
import Helper from "@core/helpers";
import SearchIcon from "@material-ui/icons/Search";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import persistentStorage from "@utils/lib/localStorage";
import tokenManager from "@utils/lib/tokenManager";
import DialogBox from "@core/modules/shared/views/Things/dialogBox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { RadioGroupForm } from "@web-bricks/Shared/Input/radio";
import FabCart from "@core/modules/Cart/views/lib/cart";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import delivery from "@core/assests/images/delivery.png";
import Config from '@config';
class SimpleNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.shopHelper = new Helper.ShopHelper();
    this.helper = new Helper.UserSigninHelper();
    this.userHelper = new Helper.UserHelper();
    this.state = {
      showDailogFlag: false,
      cartOrderType: _.get(Helper.CartHelper.getCart(), "orderType"),
      selectedDeliveryZone: persistentStorage.get("selectedDeliveryZone"),
    };
    this.renderSelectAddress = this.renderSelectAddress.bind(this);
    this.fetchData();
  }

  async fetchData() {
    let locationList = [];
    if (tokenManager.user && tokenManager.type !== "staff") {
      this.user = await this.helper.getUserById(tokenManager.user);
      locationList = await this.userHelper.getLocationFormate(this.user);
      this.setState({
        locationList: locationList,
      });
    }
  }

  onDailogOpen = () => {
    this.setState({
      showDailogFlag: true,
    });
  };

  onDailogClose = () => {
    this.setState({
      showDailogFlag: false,
    });
  };

  setTakeAway = () => {
    Helper.CartHelper.updateOrderType("takeaway");
    this.setState({
      showDailogFlag: false,
      cartOrderType: _.get(Helper.CartHelper.getCart(), "orderType"),
    });
  };

  onAddAddress = () => {
    this.props.history.push("/addAddress");
  };

  onAddressSubmit = async (values) => {
    await Helper.CartHelper.selectAddress(values, this.user);
    this.setState({
      showDailogFlag: false,
    });
  };

  onAddressSubmit = async (e, values) => {
    let location = {
      value: values,
    };
    await Helper.CartHelper.selectAddress(location, this.user);
    this.setState({
      showDailogFlag: false,
      selectedDeliveryZone: persistentStorage.get("selectedDeliveryZone"),
    });
  };

  renderSelectAddress(Block, Bits) {
    const self = this;
    return function () {
      return (
        <Block>
          <Block
            style={{ display: "flex", justifyContent: "center" }}
            className="delivery-image-align"
          >
            <img style={{ width: "120px" }} src={delivery} />
          </Block>
          <List>
            <ListItem button onClick={self.setTakeAway}>
              <ListItemText primary="Take away" />
            </ListItem>
            <Divider />
            <ListItem button onClick={self.onAddAddress}>
              <ListItemText primary="Add Address" />
            </ListItem>
            <Divider />
            <ListItem>
              <RadioGroupForm
                name="deliveryAddress"
                list={self.state.locationList}
                onChange={self.onAddressSubmit}
                required
              />
            </ListItem>
          </List>
        </Block>
      );
    };
  }

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };
  goToHome = () => {
    this.goToView("/");
  };

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };

  renderSurface(
    values,
    { interiorDesigner, MainLayout, Layout, Block, Bits, View, Switch },
    config
  ) {
    return (
      <MainLayout name="top-bar-container">
        <Layout name="top-bar-outer">
          <Layout name="top-bar-search">
            <Block name="back-container">
              {!interiorDesigner.isMobile() && !this.props.hideBackButton && (
                <Bits.Icon
                  name="back-icon"
                  onClick={() => this.props.history.goBack()}
                >
                  ArrowBack
                </Bits.Icon>
              )}
            </Block>
            <Layout name="top-bar" onClick={this.goToHome}>
              <Block name="logo">
                <Bits.Image
                  name="logo-img"
                  src={config.shop.compactLogo}
                  noLazy
                />
              </Block>
              <Block name="header">
                {config.shop.logoText ? (
                  <Block name="logo-text-block">
                    <Bits.Image
                      name="logo-text"
                      noLazy
                      src={config.shop.logoText}
                    ></Bits.Image>
                  </Block>
                ) : (
                  <Block>
                    <Bits.Text name="shop-title">{config.shop.name}</Bits.Text>
                    {config.shop.description && (
                      <Bits.Text name="shop-caption">
                        {config.shop.description}
                      </Bits.Text>
                    )}
                  </Block>
                )}
              </Block>
            </Layout>
            <Layout name="nav-icons">
              <Block name="icon-block">
                <Bits.Icon
                  name="search-icon-link"
                  onClick={() => this.props.history.push("/search")}
                >
                  Search
                </Bits.Icon>
              </Block>
              {!tokenManager.isStaff()&& (
                <Block name="icon-block">
                  <FabCart
                    name="icon"
                    pushToCart={() => this.props.history.push("/cart")}
                  />
                </Block>
              )}
            </Layout>
          </Layout>
          {_.isNull(this.state.selectedDeliveryZone) &&
            !tokenManager.isStaff() &&
            tokenManager.user &&
            this.state.cartOrderType !== "takeaway" && (
              <Block name="banner" onClick={this.onDailogOpen}>
                <Bits.Text name="banner-text">
                  Services unavailable for opted delivery Zone.Try using
                  takeaway or other address to continue shopping
                </Bits.Text>
              </Block>
            )}
          {this.state.showDailogFlag && (
            <DialogBox
              handleClickOpen={this.onDailogOpen}
              handleClose={this.onDailogClose}
              open={this.state.showDailogFlag}
              dialogTitle="Select Address"
              renderItem={this.renderSelectAddress(Block, Bits)}
              subText="Let us check if your location is within our delivery zones."
              heading="Delivery Zone"
            />
          )}
        </Layout>
      </MainLayout>
    );
  }
  getStylesheet = (colorPallete, config) => {
    return {
      "logo-text-block": {
        master: {
          width: 116,
          height: 48,
          marginLeft: 10,
        },
      },
      "logo-text": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
      banner: {
        master: {
          backgroundColor: colorPallete.secondary,
          width: "100%",
        },
      },
      "banner-text": {
        master: {
          color: colorPallete.primary,
          margin: 0,
          padding: 5,
          textAlign: "center",
          fontSsize: 14,
          lineHeight: 1.5,
          letterSpacing: "0.5px",
        },
      },
      "icon-block": {
        master: {
          margin: "0px 30px",
        },
        mobile: {
          margin: "0px 10px",
        },
      },
      "search-icon-link": {
        master: {
          color: colorPallete.primary,
        },
      },
      "top-bar-search": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
      "top-bar-container": {
        master: {
          height: 100,
          zIndex: 1,
        },
        mobile: {
          height: 70,
        },
      },
      "top-bar-outer": {
        master: {
          zIndex: 100,
          borderBottom: "1px solid #C8C8C8",
          backgroundColor: colorPallete.bg,
          position: Config.isTestRunning ? 'relative': "fixed",
          width: "100%",
          top: 0,
          left: 0,
        },
        mobile: {
          height: 70,
        },
      },
      "back-container": {
        master: {
          cursor: "pointer",
          marginLeft: tokenManager.type === "staff" ? 280 : 50,
          opacity: this.props.hideBackButton ? 0 : 1,
        },
        mobile: {
          margin: "0px 0px 0px 20px",
          display: this.props.hideBackButton ? "none" : "block",
        },
      },
      "back-icon": {
        master: {
          color: interiorDesigner.colorPallete.primary,
          // width: '1.7rem',
          // height: '1.7rem',
          border: `2px solid ${colorPallete.primary}`,
          borderRadius: "50%",
          padding: 4,
        },
      },
      "top-bar": {
        master: {
          padding: 10,
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
        },
        mobile: {
          padding: "5px 20px",
          display: !this.props.hideBackButton ? "none" : "flex",
          marginLeft: tokenManager.type === "staff" ? 40 : "unset",
        },
      },
      "nav-icons": {
        master: {
          padding: "0px 30px",
          display: "flex",
          alignItems: "center",
          height: 100,
          backgroundColor: colorPallete.primary,
        },
        mobile: {
          height: 70,
          padding: "0px 20px",
        },
      },
      logo: {
        master: {
          width: 80,
          // height:100,
          height: 80,
        },
        mobile: {
          width: 60,
          height: 60,
          marginLeft: "-6px",
        },
      },
      "logo-img": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
      "shop-title": {
        master: {
          fontSize: 26,
          fontWeight: 500,
          margin: 0,
          color: colorPallete.primary,
        },
        mobile: {
          fontSize: 17,
        },
      },
      "shop-caption": {
        master: {
          margin: 0,
          fontSize: 12,
          color: "grey",
        },
        mobile: {
          fontSize: 10,
        },
      },
      header: {
        master: {},
        mobile: {},
      },
    };
  };
}

export default Entities.createPlace(SimpleNav, {
  name: "SimpleNav",
});
